import {
  GLOSSARY_SIZE_LIMIT,
  MULTIPART_FILE_LIMIT_BYTES,
  MaiaApiRoutes,
  SETTINGS_ROUTE_PREFIX,
  SETTINGS_ROUTE_UPLOAD_GLOSSARY,
  supportedGlossaryFileTypes,
  supportedLanguages,
} from 'common-ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericUploadModal from '../../../components/GenericUploadModal';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import i18n from '../../../i18n';
import { useBoundStore } from '../../../store/useBoundStore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/ui/button';
import { Alert } from '../../../components/ui/alert';
import { toaster } from '../../../components/ui/toaster';

type GlossaryUploadButtonProps = {
  onSuccess: () => void;
};

function GlossaryUploadButton({ onSuccess }: GlossaryUploadButtonProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  async function handleFileSelect(files: FileList) {
    if (files.length > 1) {
      toaster.create({
        type: 'error',
        title: t('workspaceSettings.glossarySettings.onlyOneFileAllowed'),
      });
      return;
    }

    const form = new FormData();
    const language: (typeof supportedLanguages)[number]['short'] =
      supportedLanguages.find(
        (supportedLang) => supportedLang.short === i18n.language.split('-')[0]
      )?.short ?? 'en';

    form.append('file', files.item(0)!);
    form.append('workspaceId', workspaceId);
    form.append('language', language);

    const session = await supabase.auth.getSession();
    const token = session?.data.session?.access_token;

    setProcessing(true);
    // This has to be fetched this way because the body is formdata and not json.
    const response = await fetch(
      `${import.meta.env.VITE_MAIA_CORE_API_PUBLIC_URL}${SETTINGS_ROUTE_PREFIX}${SETTINGS_ROUTE_UPLOAD_GLOSSARY}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      }
    );
    setProcessing(false);
    setUploadModalOpen(false);

    if (!response.ok) {
      if (response.status === 413) {
        toaster.create({
          type: 'error',
          title: t('workspaceSettings.glossarySettings.glossaryFileTooBig'),
        });
        return;
      }
      toaster.create({ type: 'error', title: t('general.tryAgainError') });
      return;
    }

    const data: MaiaApiRoutes['/settings']['/upload_glossary']['response'] =
      await response.json();

    if (data.success) {
      setUploadModalOpen(false);
      onSuccess();
    } else {
      toaster.create({ type: 'error', title: data.reason, duration: 10000 });
    }
  }

  return (
    <>
      <Button
        colorPalette="maia-purple"
        onClick={() => {
          setUploadModalOpen(true);
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        {t('workspaceSettings.glossarySettings.uploadGlossaryButton')}
      </Button>
      <GenericUploadModal
        loading={
          processing
            ? t('workspaceSettings.glossarySettings.uploadGlossaryProcessing')
            : undefined
        }
        acceptedMimeTypes={[...supportedGlossaryFileTypes]}
        allowMultiUpload={false}
        isOpen={uploadModalOpen}
        onClose={() => {
          setUploadModalOpen(false);
        }}
        onFileSelect={handleFileSelect}
        fileSizeLimitMb={MULTIPART_FILE_LIMIT_BYTES / (1024 * 1024)}
      >
        <Alert
          status="info"
          title={t('workspaceSettings.glossarySettings.glossaryLimitInfo', {
            limit: GLOSSARY_SIZE_LIMIT,
          })}
        >
          {t('workspaceSettings.glossarySettings.glossaryOverwriteWarning')}
        </Alert>
      </GenericUploadModal>
    </>
  );
}

export default GlossaryUploadButton;
