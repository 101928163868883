import { t } from 'i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../../components/ui/dialog';
import { Button } from '../../../components/ui/button';

export type ConformDeleteFileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  fileCount: number;
};

/**
 * ConfirmDeleteFileModal displays a modal asking for confirmation to delete files.
 *
 * @param isOpen - Determines if the modal is open.
 * @param onClose - Function to call when closing the modal.
 * @param onConfirm - Function to call when confirmation is made.
 * @param fileCount - Number of files to be deleted.
 */
export const ConfirmDeleteFileModal = ({
  isOpen,
  onClose,
  onConfirm,
  fileCount,
}: ConformDeleteFileModalProps) => (
  <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
    <DialogBackdrop />
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t('deleteFileModal.title')}</DialogTitle>
      </DialogHeader>{' '}
      <DialogBody>
        {fileCount > 1
          ? t('deleteFileModal.confirmTextMultipleFiles', {
              fileCount,
            })
          : t('deleteFileModal.confirmTextSingleFile')}
      </DialogBody>
      <DialogFooter>
        <div className="flex items-center gap-2">
          <Button
            className="text-maia-text-dark font-medium"
            onClick={onClose}
            variant="outline"
          >
            {t('general.cancelButton')}
          </Button>
          <Button
            className="bg-maia-support-red text-maia-text-light font-medium"
            onClick={onConfirm}
          >
            {t('general.deleteButton')}
          </Button>
        </div>
      </DialogFooter>
    </DialogContent>
  </DialogRoot>
);
