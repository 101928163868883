import { DialogTitle, Input } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Field } from './ui/field';

type ConfirmDeletionModalProps = {
  title: string;
  subtitle: string;
  isOpen: boolean;
  saveWord?: string;
  deleteButtonLabel?: string;
  onClose: () => void;
  onConfirm: (saveWord?: string) => void;
};

export default function DeleteModal({
  title,
  subtitle,
  isOpen,
  saveWord,
  deleteButtonLabel,
  onConfirm,
  onClose,
}: ConfirmDeletionModalProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);

  const [typedSaveWord, setTypedSaveWord] = useState<string>('');
  const [deleteDisabled, setDeleteDisabled] = useState(!!saveWord);

  function handleSaveWord(value: string) {
    setTypedSaveWord(value);
    if (value === saveWord) {
      setDeleteDisabled(false);
    } else {
      !deleteDisabled && setDeleteDisabled(!deleteDisabled);
    }
  }

  return (
    <DialogRoot
      open={isOpen}
      finalFocusEl={() => cancelRef.current}
      onOpenChange={({ open }) => !open && onClose()}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <div>{subtitle}</div>
          {saveWord && (
            <Field
              label={t('general.saveWordInstruction', {
                saveWord,
              })}
            >
              <Input
                type="email"
                backgroundColor={'white'}
                placeholder={t('userSettings.email')}
                defaultValue={typedSaveWord}
                onChange={(e) => {
                  // validation
                  handleSaveWord(e.target.value);
                }}
              />
            </Field>
          )}
        </DialogBody>
        <DialogFooter>
          <Button ref={cancelRef} onClick={onClose} variant="outline">
            {t('general.cancelButton')}
          </Button>
          <Button
            disabled={deleteDisabled}
            colorPalette="red"
            onClick={() => onConfirm(typedSaveWord)}
            ml={3}
          >
            {deleteButtonLabel ?? t('general.deleteButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
