import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../../components/ui/dialog';
import { Button } from '../../../components/ui/button';

type ConfirmDeletionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function DeleteShareModal({
  isOpen,
  onConfirm,
  onClose,
}: ConfirmDeletionModalProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);

  return (
    <DialogRoot
      open={isOpen}
      finalFocusEl={() => cancelRef.current}
      onOpenChange={({ open }) => !open && onClose()}
      role="alertdialog"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader fontSize="lg" fontWeight="bold">
          <DialogTitle>
            {t('fileManagerPanel.deleteShareModalTitle')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogBody>
          {t('fileManagerPanel.deleteShareModalSubtitle')}
        </DialogBody>
        <DialogFooter>
          <DialogCloseTrigger>
            <Button ref={cancelRef} onClick={onClose}>
              {t('fileManagerPanel.deleteShareModalSecondaryButton')}
            </Button>
          </DialogCloseTrigger>
          <DialogActionTrigger asChild>
            <Button colorPalette="red" onClick={onConfirm} ml={3}>
              {t('fileManagerPanel.deleteShareModalRemoveButton')}
            </Button>
          </DialogActionTrigger>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
