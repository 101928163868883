import { useEffect, useState } from 'react';

import Disable2FAModal from './modals/Disable2FAModal';
import EmailVerificationModal from './modals/EmailVerificationModal';
import SettingsSwitch from '../../settings/SettingsSwitch';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { toaster } from '../../../components/ui/toaster';

type EmailMFAProps = {
  setMfaCheck: (value: boolean) => void;
};

export default function EmailMFA({ setMfaCheck }: EmailMFAProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const userInfo = useBoundStore((state) => state.userInfo);

  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [disable2faModal, setDisable2faModal] = useState(false);

  const handleSwitchChange = () => {
    isMFAEnabled ? setDisable2faModal(true) : setEmailVerificationModal(true);
  };

  const fetchMFAstatus = async () => {
    const { data, error } = await supabase
      .from('user_setting')
      .select('is_email_mfa_enabled');

    if (error) {
      toaster.create({
        title: t('userSettings.security.generalError'),
        type: 'error',
      });
      console.error(error);
      return;
    }
    setIsMFAEnabled(!!data?.[0]?.is_email_mfa_enabled);
    setMfaCheck(true);
  };
  useEffect(() => {
    fetchMFAstatus();
  }, [emailVerificationModal]);

  const handleDisableMFA = async () => {
    if (!userInfo?.userId) return;

    const { error } = await supabase
      .from('user_setting')
      .update({ is_email_mfa_enabled: false })
      .eq('user_id', userInfo.userId);

    if (error) {
      console.error(error);
      return;
    }

    setMfaCheck(true);
    setIsMFAEnabled(false);
    setDisable2faModal(false);
  };

  return (
    <>
      <SettingsSwitch
        title={t('userSettings.security.emailTitle')}
        subtitle={t('userSettings.security.emailSubtitle')}
        switchProps={{ checked: isMFAEnabled, onChange: handleSwitchChange }}
      />

      {disable2faModal && (
        <Disable2FAModal
          title={t('userSettings.security.disable2FAModal.emailTitle')}
          subtitle={t('userSettings.security.disable2FAModal.emailSubtitle')}
          isOpen={disable2faModal}
          onClose={() => setDisable2faModal(false)}
          onConfirm={handleDisableMFA}
        />
      )}
      {emailVerificationModal && (
        <EmailVerificationModal
          isOpen={emailVerificationModal}
          onClose={() => setEmailVerificationModal(false)}
        />
      )}
    </>
  );
}
