import { getLlmModel, setLlmModel } from '../utils/localStorage.js';
import { useEffect, useState } from 'react';

import { AvailableModelEnum, ExperimentalModelEnum } from 'common-ts';
import { useBoundStore } from '../store/useBoundStore.js';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from './ui/select.js';
import { createListCollection } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';

const supportedModelsMap = new Map<
  AvailableModelEnum | ExperimentalModelEnum,
  string
>([
  // keeping the naming convention consistent with ChatGpt
  [AvailableModelEnum.GPT_4_O, 'GPT-4o'],
  [AvailableModelEnum.GPT_4_O_MINI, 'GPT-4o mini'],
  [ExperimentalModelEnum.MISTRAL_LARGE_2407, 'Mistral-large'],
  [ExperimentalModelEnum.META_LLAMA_3_1_70B_INSTRUCT, 'Llama 3.1'],
]);

const DEFAULT_MODEL = AvailableModelEnum.GPT_4_O;

type ChangeLlmProps = {
  withExperimentalLlm?: boolean;
  onChange?: () => void;
};

export function ChangeLlm({
  onChange,
  withExperimentalLlm = false,
}: ChangeLlmProps) {
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );

  const [model, setModel] = useState<
    AvailableModelEnum | ExperimentalModelEnum
  >(getLlmModel(workspaceLicenseType ?? undefined) ?? DEFAULT_MODEL);

  const [availableModels, setAvailableModels] = useState<string[]>(
    Object.values(AvailableModelEnum).map((key) => supportedModelsMap.get(key)!)
  );

  const supportedModels = createListCollection<{
    label: string;
    value: AvailableModelEnum | ExperimentalModelEnum;
  }>({
    items: Array.from(supportedModelsMap.entries())
      .filter(([_, modelName]) => availableModels.includes(modelName))
      .map(([modelEnum, modelName]) => {
        return { label: modelName, value: modelEnum };
      }),
  });

  const updateAvailableModels = () => {
    if (import.meta.env.VITE_MAIA_STAGE !== 'prod' && withExperimentalLlm) {
      const experimentalModels = Object.values(ExperimentalModelEnum);
      setAvailableModels((prevModels) => [
        ...prevModels,
        ...experimentalModels.map(
          (modelEnum) => supportedModelsMap.get(modelEnum)!
        ),
      ]);
    }
  };

  useEffect(() => {
    updateAvailableModels();
  }, []);

  function persistGpt(model: AvailableModelEnum | ExperimentalModelEnum) {
    setLlmModel(model);
    setModel(model);
    onChange?.();
  }

  return (
    // for some reason, select is really adamant about default styles. Probably a bug!
    <SelectRoot
      collection={supportedModels}
      size={'sm'}
      defaultValue={[model]}
      onValueChange={({ value }) =>
        persistGpt(value[0] as AvailableModelEnum | ExperimentalModelEnum)
      }
      variant={'subtle'}
      unstyled
    >
      <SelectTrigger className="flex w-max min-w-36 items-center justify-center">
        <SelectValueText />
        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
      </SelectTrigger>
      <SelectContent className="border-maia-border flex w-max flex-col gap-y-0.5 rounded border border-solid bg-white px-2 py-2 shadow">
        {supportedModels.items.map((supportedModel) => (
          <SelectItem
            item={supportedModel}
            key={supportedModel.value}
            className={`hover:bg-maia-blue-100 flex w-full cursor-pointer items-center justify-between rounded px-2 py-1 ${model === supportedModel.value ? 'bg-gray-100' : ''}`}
          >
            <span>{supportedModel.label}</span>
            {model === supportedModel.value && (
              <FontAwesomeIcon icon={faCheck} className="ml-4" />
            )}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
}
