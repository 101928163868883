import {
  EnrichedBasicLayoutItem,
  MergedLayoutItemsWithAnnotations,
  SnippetResultsV1,
  mergeSnippets,
} from 'common-ts';

import { resultAndLayoutBlocksToMarkdown } from '../pages/search/resultAndLayoutBlocksToMarkdown';

export type ResultV1V2Abstraction = {
  filename: string;
  documentId?: string;
  fileId?: string;
  content: string;
  pageNumbers: string[];
};

const getPageNumbers = (snippet: MergedLayoutItemsWithAnnotations) => {
  const uniqueSortedPageNumbers = Array.from(
    new Set(snippet.annotations.map((a) => a.pageNumber))
  ).sort((a, b) => a - b);

  return combinePageNumbers(uniqueSortedPageNumbers);
};

const combinePageNumbers = (pageNumbers: number[]): string[] => {
  const ranges: string[] = [];
  let start = pageNumbers[0];
  let end = pageNumbers[0];

  for (let i = 1; i < pageNumbers.length; i++) {
    if (end && pageNumbers[i] === end + 1) {
      end = pageNumbers[i];
    } else if (start && end) {
      ranges.push(start === end ? `${start}` : `${start}-${end}`);
      start = pageNumbers[i];
      end = pageNumbers[i];
    }
  }
  if (start && end) ranges.push(start === end ? `${start}` : `${start}-${end}`);
  return ranges;
};

export function abstractV1V2(
  v1OrV2: SnippetResultsV1 | EnrichedBasicLayoutItem
) {
  /* post-processing of snippets have been removed because inline references will not work if snippets are merged or re-arranged. 
  inline references use snippet index to identify the reference. 
  Previously, uuids were used but they were replaced by indices for reduction in tokens.*/
  const combined: ResultV1V2Abstraction[] =
    v1OrV2.type === 'items'
      ? mergeSnippets(v1OrV2.items, resultAndLayoutBlocksToMarkdown).map(
          (v2) => ({
            filename: v2.filename,
            documentId: v2.documentId,
            content: v2.content,
            pageNumbers: getPageNumbers(v2),
          })
        )
      : v1OrV2.snippets.map((v1) => ({
          filename: v1.fileName,
          fileId: v1.fileId,
          content: resultAndLayoutBlocksToMarkdown(v1),
          pageNumbers: [],
        }));

  return combined;
}
