import { ValidationError, object, ref, string } from 'yup';
import { useMemo, useRef, useState } from 'react';

import { USER_SETTINGS_TOAST_ID } from './userSettingsToastId.js';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../store/useBoundStore.js';
import { toaster } from '../../components/ui/toaster.js';
import { Alert } from '../../components/ui/alert.js';
import { Field } from '../../components/ui/field.js';
import { Box, Input } from '@chakra-ui/react';
import { Button } from '../../components/ui/button.js';

type FormError = {
  email?: string;
  repeatEmail?: string;
};

type UpdateEmailProps = {
  onClose: () => void;
};

function UpdateEmail({ onClose }: UpdateEmailProps) {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);
  const supabase = useBoundStore((state) => state.supabase);

  const email = useRef(userInfo?.email ?? '');
  const repeatEmail = useRef('');
  const [formError, setFormError] = useState<FormError>();
  const [loading, setLoading] = useState(false);

  const formSchema = useMemo(() => {
    return object().shape({
      email: string()
        .required(`${t('loginView.emptyMail')}`)
        .email(`${t('loginView.mailNotValid')}`),
      repeatEmail: string()
        .required(`${t('loginView.emptyMail')}`)
        .email(`${t('loginView.mailNotValid')}`)
        .oneOf([ref('email')], t('userSettings.emailMismatch')),
    });
  }, [t]);

  async function handleSave() {
    setLoading(true);

    try {
      await formSchema.validate(
        {
          email: email.current,
          repeatEmail: repeatEmail.current,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        const formError: FormError = {};
        error.inner.forEach((element) => {
          formError[element.path as keyof FormError] = element.message;
        });
        setFormError(formError);
        setLoading(false);
        return;
      }
    }

    const updatedUserData = {
      email: email.current,
    };

    if (!userInfo?.userId) return;

    try {
      const result = await supabase?.auth.updateUser(updatedUserData);

      if (result.error) {
        throw new Error(result.error.message);
      }
      toaster.create({
        id: USER_SETTINGS_TOAST_ID,
        title: t('userSettings.updateMailSuccess'),
        type: 'success',
      });
    } catch (err) {
      if (err instanceof Error) {
        captureException(err);
        toaster.create({
          id: USER_SETTINGS_TOAST_ID,
          title: t('userSettings.saveError'),
          description: err.message,
          type: 'error',
        });
      }
    }

    setLoading(false);
    onClose();
  }

  return (
    <Box className="flex flex-col gap-4" colorPalette={'maia-accent'}>
      <Alert
        status="info"
        variant="subtle"
        title={t('userSettings.updateMailInfo')}
      />
      <Field
        label={t('userSettings.email')}
        invalid={!!formError?.email}
        errorText={formError?.email}
      >
        <Input
          type="email"
          backgroundColor={'white'}
          placeholder={t('userSettings.email')}
          defaultValue={email.current}
          onChange={(e) => {
            email.current = e.target.value;
          }}
        />
      </Field>
      <Field
        invalid={!!formError?.repeatEmail}
        errorText={t('userSettings.repeatEmail')}
      >
        <Input
          type="email"
          backgroundColor={'white'}
          placeholder={t('userSettings.email')}
          onChange={(e) => {
            repeatEmail.current = e.target.value;
          }}
        />
      </Field>
      <div className="flex justify-end gap-2">
        <Button
          size="sm"
          variant="outline"
          onClick={onClose}
          loading={loading}
          colorPalette={'gray'}
        >
          {t('userSettings.auth.cancel')}
        </Button>
        <Button
          size="sm"
          colorPalette="maia-purple"
          onClick={handleSave}
          loading={loading}
        >
          {t('userSettings.saveButton')}
        </Button>
      </div>
    </Box>
  );
}

export default UpdateEmail;
