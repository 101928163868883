import {
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCircleInfo,
  faFileCircleXmark,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
} from '@fortawesome/pro-solid-svg-icons';

import { Database } from 'common-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FileIconProps = {
  mimeType?: string;
  name?: string;
  status?: Database['public']['Enums']['embedding_status'];
  className?: string;
};

export function getFileExtension(filename: string | undefined): string | null {
  if (!filename) return null;

  const lastDot = filename.lastIndexOf('.');
  const lastSegment = filename.lastIndexOf('/');
  if (lastDot <= lastSegment) return null;

  return filename.slice(lastDot);
}

function FileIcon({ mimeType, name, status, className }: FileIconProps) {
  const key = mimeType || getFileExtension(name) || '';
  const identifier = FILE_IDENTIFIER_MAP.get(key);
  const icon = identifier ? ICON_MAP.get(identifier) : undefined;

  return (
    <div className={`text-prodbg-400 ${className || ''}`}>
      {status ? (
        status === 'FAILED' ? (
          <FontAwesomeIcon icon={faFileCircleXmark} />
        ) : status === 'ONGOING' || status === 'PENDING' ? (
          <FontAwesomeIcon icon={faFileCircleInfo} />
        ) : (
          icon || <FontAwesomeIcon icon={faFile} />
        )
      ) : (
        icon || <FontAwesomeIcon icon={faFile} />
      )}
    </div>
  );
}

const FILE_IDENTIFIER_MAP = new Map<string, string>([
  // office files
  ['application/vnd.ms-excel', 'excel'],
  [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'excel',
  ],
  ['.xls', 'excel'],
  ['.xlsx', 'excel'],

  ['application/vnd.ms-powerpoint', 'powerpoint'],
  [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'powerpoint',
  ],
  ['.ppt', 'powerpoint'],
  ['.pptx', 'powerpoint'],

  ['application/msword', 'word'],
  [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'word',
  ],
  ['.doc', 'word'],
  ['.docx', 'word'],

  // pdf files
  ['application/pdf', 'pdf'],
  ['.pdf', 'pdf'],

  // text files
  ['text/plain', 'text'],
  ['.txt', 'text'],

  // image files
  ['image/bmp', 'image'],
  ['image/gif', 'image'],
  ['image/vnd.microsoft.icon', 'image'],
  ['image/jpeg', 'image'],
  ['image/png', 'image'],
  ['image/svg+xml', 'image'],
  ['image/tiff', 'image'],
  ['image/webp', 'image'],
  ['.bmp', 'image'],
  ['.gif', 'image'],
  ['.ico', 'image'],
  ['.jpeg', 'image'],
  ['.jpg', 'image'],
  ['.png', 'image'],
  ['.svg', 'image'],
  ['.tif', 'image'],
  ['.tiff', 'image'],
  ['.webp', 'image'],

  // archive files
  ['application/zip', 'archive'],
  ['application/x-7z-compressed', 'archive'],
  ['application/x-bzip', 'archive'],
  ['application/x-bzip2', 'archive'],
  ['application/gzip', 'archive'],
  ['application/x-tar', 'archive'],
  ['.zip', 'archive'],
  ['.7z', 'archive'],
  ['.bz', 'archive'],
  ['.bz2', 'archive'],
  ['.gz', 'archive'],
  ['.tar', 'archive'],

  // code files
  ['text/html', 'code'],
  ['text/css', 'code'],
  ['text/javascript', 'code'],
  ['text/xml', 'code'],
  ['application/xml', 'code'],
  ['.html', 'code'],
  ['.htm', 'code'],
  ['.css', 'code'],
  ['.js', 'code'],
  ['.xml', 'code'],

  // csv files
  ['text/csv', 'csv'],
  ['.csv', 'csv'],

  // audio files
  ['audio/aac', 'audio'],
  ['audio/midi', 'audio'],
  ['audio/x-midi', 'audio'],
  ['audio/mpeg', 'audio'],
  ['audio/ogg', 'audio'],
  ['audio/opus', 'audio'],
  ['audio/wav', 'audio'],
  ['audio/webm', 'audio'],
  ['.aac', 'audio'],
  ['.mid', 'audio'],
  ['.midi', 'audio'],
  ['.mp3', 'audio'],
  ['.ogg', 'audio'],
  ['.opus', 'audio'],
  ['.wav', 'audio'],
  ['.weba', 'audio'],

  // video files
  ['video/x-msvideo', 'video'],
  ['video/mp4', 'video'],
  ['video/mpeg', 'video'],
  ['video/ogg', 'video'],
  ['video/webm', 'video'],
  ['.avi', 'video'],
  ['.mp4', 'video'],
  ['.mpeg', 'video'],
  ['.mpg', 'video'],
  ['.ogv', 'video'],
  ['.webm', 'video'],
]);

const ICON_MAP = new Map<string, JSX.Element>([
  ['excel', <FontAwesomeIcon icon={faFileExcel} />],
  ['powerpoint', <FontAwesomeIcon icon={faFilePowerpoint} />],
  ['word', <FontAwesomeIcon icon={faFileWord} />],
  ['pdf', <FontAwesomeIcon icon={faFilePdf} />],
  ['text', <FontAwesomeIcon icon={faFileAlt} />],
  ['image', <FontAwesomeIcon icon={faFileImage} />],
  ['archive', <FontAwesomeIcon icon={faFileArchive} />],
  ['code', <FontAwesomeIcon icon={faFileCode} />],
  ['csv', <FontAwesomeIcon icon={faFileCsv} />],
  ['audio', <FontAwesomeIcon icon={faFileAudio} />],
  ['video', <FontAwesomeIcon icon={faFileVideo} />],
]);

export default FileIcon;
