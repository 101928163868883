import { Link, useNavigate } from 'react-router-dom';
import { ValidationError, object, string } from 'yup';
import { useMemo, useRef, useState } from 'react';
import { Input, Text } from '@chakra-ui/react';
import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi.js';
import { navigateToDefaultWorkspace } from '../../utils/getDefaultWorkspace.js';
import { t } from 'i18next';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { handleJoin } from '../../utils/inviteLink';
import { Alert } from '../../components/ui/alert.js';
import { Button } from '../../components/ui/button.js';
import { Field } from '../../components/ui/field.js';
import { toaster } from '../../components/ui/toaster.js';
import { PasswordInput } from '../../components/ui/password-input.js';

type SignInError = {
  email?: string;
  password?: string;
  general?: string;
};

export default function SigninForm() {
  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();
  const [searchParams] = useTypedSearchParams(ROUTES.AUTH.SIGN_IN);

  const email = useRef('');
  const password = useRef('');

  const [signInError, setSignInError] = useState<SignInError>({});
  const [loading, setLoading] = useState<boolean>(false);

  const signInSchema = useMemo(() => {
    return object().shape({
      email: string()
        .required(`${t('loginView.emptyMail')}`)
        .email(`${t('loginView.mailNotValid')}`), // optional and should be valid email if provided
      password: string().required(`${t('loginView.emptyPw')}`), // optional and no specific validation if provided
    });
  }, [t]);

  const handleSignIn = async (email: string, password: string) => {
    setLoading(true);
    setSignInError({});
    try {
      await signInSchema.validate(
        {
          email,
          password,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        const signErr: SignInError = {};
        error.inner.forEach((element) => {
          signErr[element.path as keyof SignInError] = element.message;
        });
        setSignInError(signErr);
        setLoading(false);
        return;
      }
    }

    if (!supabase) {
      setSignInError({
        general: `${t(`loginView.clientError`)}`,
      });
    } else {
      try {
        const authResponse = await fetchApi(
          supabase,
          '/auth',
          '/login',
          {
            method: 'POST',
            email,
            password,
          },
          true
        );
        if (!authResponse.success) {
          switch (authResponse.status) {
            case 400:
              setSignInError({
                general: `${t('loginView.invalidCredentials')}`,
              });
              break;
            default:
              setSignInError({
                general: `${t('general.reloadError')}`,
              });
              break;
          }
          setLoading(false);
          return;
        }

        // user has two factor authentication enabled, Redirect to two factor authentication page
        if (authResponse.status === 243) {
          // user has both MFA methods enabled, Redirect to MFA method selection page
          setLoading(false);
          navigate(ROUTES.AUTH.TWO_FACTOR_SELECT.buildPath({}), {
            state: ROUTES.AUTH.TWO_FACTOR_SELECT.buildState({
              email,
              password,
              inviteCode: searchParams.inviteCode,
            }),
          });
        } else if (
          authResponse.status === 242 &&
          'challengeId' in authResponse.data
        ) {
          // user has one MFA method enabled, Redirect to MFA verification page with MFA challengeId and type
          setLoading(false);
          navigate(ROUTES.AUTH.TWO_FACTOR.buildPath({}), {
            state: ROUTES.AUTH.TWO_FACTOR.buildState({
              email,
              password,
              challengeId: authResponse.data.challengeId,
              type: authResponse.data.type,
              inviteCode: searchParams.inviteCode,
            }),
          });
          // user has been authenticated successfully, set active session and redirect to workspace
        } else if (
          authResponse.status === 200 &&
          'userId' in authResponse.data
        ) {
          const sessionRes = await supabase.auth.setSession({
            access_token: authResponse.data.accessToken,
            refresh_token: authResponse.data.refreshToken,
          });
          if (sessionRes.error) {
            throw new Error(sessionRes.error.message);
          } else {
            if (searchParams.inviteCode) {
              // already handles the redirect to the workspace
              handleJoin(
                searchParams.inviteCode,
                supabase,
                t,
                navigate,
                toaster
              );
            } else {
              navigateToDefaultWorkspace({
                navigateFn: navigate,
                toaster,
                supabase,
                translationFn: t,
              });
            }
            setLoading(false);
          }
        }
      } catch (error) {
        captureException(error);
        setSignInError({
          general: `${t('general.reloadError')}`,
        });
      }
    }
    setLoading(false);
  };

  return (
    <>
      {searchParams.emailConfirmed ? (
        <Alert
          status="success"
          className="mb-2"
          title={t('loginView.mailConfirmed')}
        />
      ) : null}
      {signInError.general && (
        <Alert status="error">{signInError.general}</Alert>
      )}
      <Field
        label="Email"
        invalid={!!signInError.email}
        errorText={t('loginView.emptyMail')}
      >
        <Input
          id="email"
          type="email"
          tabIndex={1}
          onChange={(v) => (email.current = v.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSignIn(email.current, password.current);
            }
          }}
        />
      </Field>
      <Field
        className="pt-2"
        label={t('loginView.password')}
        invalid={!!signInError.password}
        errorText={signInError.password}
      >
        <PasswordInput
          tabIndex={2}
          onChange={(e) => {
            password.current = e.target.value;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSignIn(email.current, password.current);
            }
          }}
        />
      </Field>
      <div className="flex items-center justify-between pt-6">
        <Link to={ROUTES.AUTH.FORGOT_PASSWORD.buildPath({})}>
          <Text className="hover:underline" fontSize="sm">
            {t('loginView.forgot')}
          </Text>
        </Link>
        <Button
          colorPalette={'maia-accent'}
          onClick={() => {
            handleSignIn(email.current, password.current);
          }}
          tabIndex={3}
          loading={loading}
        >
          {t('loginView.login')}
        </Button>
      </div>
    </>
  );
}
