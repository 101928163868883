/**
 * Sorts an array of `MergedLayoutItemsWithAnnotations` first by `documentId`,
 * and then by `orderOfItem` within each `documentId`.
 *
 * After sorting, it groups the snippets by `documentId`, returning an array where
 * each sub-array contains the snippets corresponding to a specific document, sorted by `orderOfItem`.
 *
 * @param toSort - An array of `MergedLayoutItemsWithAnnotations` that will be sorted and grouped.
 * @returns A two-dimensional array of `MergedLayoutItemsWithAnnotations`, where each sub-array contains
 * snippets for a specific document, sorted by `orderOfItem`.
 */
export function sortDocumentIdOrderOfItem(toSort) {
    toSort.sort((a, b) => {
        // Compare by documentId first
        if (a.documentId < b.documentId)
            return -1;
        if (a.documentId > b.documentId)
            return 1;
        // If documentId is equal, compare by orderOfItem
        return a.orderOfItem - b.orderOfItem;
    });
    const docBased = [];
    let currentDocId = '';
    for (const item of toSort) {
        if (currentDocId !== item.documentId) {
            docBased.push([item]);
            currentDocId = item.documentId;
        }
        else {
            docBased[docBased.length - 1].push(item);
        }
    }
    return docBased;
}
/**
 * Merges and sorts layout items by `documentId`, then combines consecutive sibling snippets based on their order within the document.
 *
 * The function first sorts the `snippets` by their `documentId` and `orderOfItem` properties. It then merges all snippets that are direct siblings
 * (i.e., have consecutive or identical `orderOfItem` values), combining their content and annotations.
 *
 * The merged content of the snippets is passed through the `filterSnippetContent` function to allow for customization of the final content output.
 *
 * @param snippets THE ORDER OF MergedLayoutItemsWithAnnotations[] IS NOT RELEVANT!
 * @param filterSnippetContent - An optional function to process the content of each snippet. By default, it returns the `content` property of each snippet unchanged.
 * @returns An array of merged `MergedLayoutItemsWithAnnotations`, where consecutive sibling snippets are combined based on their order in the document.
 */
export function mergeSnippets(snippets, filterSnippetContent = (passthrough) => passthrough.content) {
    const combined = [];
    const docBasedSnippets = sortDocumentIdOrderOfItem(snippets);
    docBasedSnippets.forEach((snippets) => {
        let currIndex = 0;
        let currentItemOrder = snippets[currIndex].orderOfItem;
        let content = filterSnippetContent(snippets[currIndex]);
        let annotations = snippets[currIndex].annotations;
        snippets.forEach((item, index) => {
            // because initialized with 0, no double add
            if (index === 0)
                return;
            if (item.orderOfItem === currentItemOrder ||
                item.orderOfItem === currentItemOrder + 1) {
                content += `\n${filterSnippetContent(item)}`;
                annotations = [
                    ...annotations,
                    ...item.annotations.map((annotation) => ({
                        ...annotation,
                        offsetStart: annotation.offsetStart + content.length + 1,
                        offsetEnd: annotation.offsetEnd + content.length + 1,
                    })),
                ];
            }
            else {
                combined.push({
                    ...snippets[currIndex],
                    content,
                    annotations,
                });
                currIndex = index;
                content = filterSnippetContent(snippets[currIndex]);
                annotations = snippets[currIndex].annotations;
            }
            currentItemOrder = item.orderOfItem;
        });
        if (currIndex < snippets.length - 1 || currIndex === 0) {
            combined.push({
                ...snippets[currIndex],
                content,
                annotations,
            });
        }
    });
    return combined;
}
