import { useTranslation } from 'react-i18next';
import useTimer from './useTimer';
import { LinkButton } from '../../../components/ui/link-button';

type ResendEmail2FAProps = {
  sendNewOtp: () => Promise<void>;
};
export default function ResendEmail2FA({ sendNewOtp }: ResendEmail2FAProps) {
  const { t } = useTranslation();

  const { timeLeft, reset } = useTimer(60);

  const handleResendOtp = async () => {
    await sendNewOtp();
    reset();
  };

  return (
    <div className="text-chakra-gray-500 my-4 flex w-full flex-col items-center justify-center text-xs">
      <div className="flex flex-col">
        <span className="mr-1">
          {t('userSettings.security.emailVerificationModal.didNotReceiveCode')}
        </span>
        {timeLeft > 0 ? (
          <span>
            <span className="mr-1">
              {t(
                'userSettings.security.emailVerificationModal.getNewCodePrompt'
              )}
            </span>
            <span className="text-maia-purple-500">{timeLeft}s</span>
          </span>
        ) : (
          <LinkButton
            size={'xs'}
            colorPalette="maia-purple"
            onClick={handleResendOtp}
            // disabled works but ts throws an error
            // @ts-ignore
            disabled={timeLeft > 0}
          >
            {t('userSettings.security.emailVerificationModal.resendButton')}
          </LinkButton>
        )}
      </div>
    </div>
  );
}
