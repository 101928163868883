import { createListCollection, Input, SelectContent } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Database } from 'common-ts';
import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogCloseTrigger,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../components/ui/dialog';
import { Field } from '../../components/ui/field';
import { Button } from '../../components/ui/button';
import {
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from '../../components/ui/select';

type EditUserModalProps = {
  user:
    | Database['public']['Views']['workspace_users_admin_view']['Row']
    | undefined;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (
    userId: string,
    userType: Database['public']['Enums']['workspace_user_type']
  ) => void;
};

function EditUserModal({
  user,
  isOpen,
  onClose,
  onUpdate,
}: EditUserModalProps) {
  const { t } = useTranslation();

  const [role, setRole] = useState<
    Database['public']['Enums']['workspace_user_type'][]
  >(user?.user_type ? [user.user_type] : ['USER']);

  useEffect(() => {
    setRole(user?.user_type ? [user.user_type] : ['USER']);
  }, [user]);

  const roles = createListCollection<{
    value: Database['public']['Enums']['workspace_user_type'];
    label: string;
  }>({
    items: [
      { value: 'USER', label: 'User' },
      { value: 'ADMIN', label: 'Admin' },
    ],
  });

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('workspaceSettings.editUserModal.title')}
          </DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody className="flex flex-col gap-4">
          <Field label={t('workspaceSettings.editUserModal.userNameLabel')}>
            <Input
              value={
                user?.first_name
                  ? `${user.first_name} ${user.last_name ?? ''}`
                  : '-'
              }
              disabled
            />
          </Field>
          <Field label={t('workspaceSettings.editUserModal.emailLabel')}>
            <Input disabled value={user?.email ?? ''} />
          </Field>
          <Field label={t('workspaceSettings.editUserModal.userTypeLabel')}>
            <SelectRoot
              collection={roles}
              size={'sm'}
              value={role}
              onValueChange={({ value }) => {
                setRole(
                  value as Database['public']['Enums']['workspace_user_type'][]
                );
              }}
              defaultValue={role}
            >
              <SelectTrigger>
                <SelectValueText />
              </SelectTrigger>
              <SelectContent>
                {roles.items.map((role) => (
                  <SelectItem key={role.value} item={role.value}>
                    {role.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </Field>
        </DialogBody>
        <DialogFooter>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              className="text-maia-text-dark font-medium"
              onClick={onClose}
            >
              {t('general.cancelButton')}
            </Button>
            <Button
              className="text-maia-text-light font-medium"
              colorPalette="maia-accent"
              disabled={!user}
              mr={3}
              onClick={() => {
                onUpdate(user!.user_id!, role.at(0)!);
                onClose();
              }}
            >
              {t('general.updateButtons')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default EditUserModal;
