import { AvailableWorkspace } from './WorkspaceSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from 'common-ts';
import WorkspaceIcon from './WorkspaceIcon';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '../ui/tooltip';

type WorkspaceListItemProps = {
  workspace: AvailableWorkspace;
  isSelected: boolean;
};

export const WorkspaceListItem = ({
  workspace,
  isSelected,
}: WorkspaceListItemProps) => {
  const navigate = useNavigate();

  /**
   * Transforms the license string to a more readable format.
   * First character of each word is capitalized.
   * Replaces underscores with whitespaces.
   * @param input
   */
  const transformLicenseString = (input: string): string => {
    // Replace underscores with whitespaces and convert to lowercase
    const stringWithWhitespace = input.replace(/_/g, ' ').toLowerCase();

    // Capitalize the first letter of each word
    return stringWithWhitespace.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSwitchWorkspace = () => {
    if (
      (workspace.isInactive || workspace.isUserDisabled) &&
      workspace.userType === 'USER'
    ) {
      return;
    }
    navigate(ROUTES.HOME.CHAT.buildPath({ workspaceId: workspace.id }));
  };

  return (
    <Tooltip
      content={
        workspace.isInactive
          ? t('general.workspaceInactiveHint')
          : workspace.isUserDisabled
            ? t('general.workspaceUserDisabledHint')
            : ''
      }
      aria-label="workspace name"
      openDelay={500}
      disabled={!workspace.isInactive && !workspace.isUserDisabled}
    >
      <div
        className="hover:bg-maia-blue-100 flex cursor-pointer items-center gap-1.5 rounded-lg px-4 py-2 transition duration-300"
        onClick={handleSwitchWorkspace}
      >
        <WorkspaceIcon licenseType={workspace.licenseType} />
        <div className="min-w-0">
          <div className="min-w-0 truncate text-sm font-semibold">
            {workspace.name}
          </div>
          <div className="text-xs text-gray-500">
            {transformLicenseString(workspace.licenseType)} -{' '}
            {`${workspace.memberCount} ${workspace?.memberCount > 1 ? t('general.members') : t('general.member')}`}
          </div>
          {workspace.isInactive || workspace.isUserDisabled ? (
            <div className="text-maia-support-red text-xs">
              {workspace.isInactive
                ? t('general.inactive')
                : t('general.userDisabled')}
            </div>
          ) : null}
        </div>
        {isSelected && (
          <>
            <div className="flex-grow" />
            <FontAwesomeIcon icon={faCheck} size="lg" />
          </>
        )}
      </div>
    </Tooltip>
  );
};
