import { ReactElement, useEffect, useState } from 'react';

import SearchBar from '../../components/SearchBar';
import { useApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useTranslation } from 'react-i18next';
import { zeros } from 'common-ts';
import { toaster } from '../../components/ui/toaster';
import { Checkbox } from '../../components/ui/checkbox';
import { Skeleton } from '../../components/ui/skeleton';
import { Button } from '../../components/ui/button';
import {
  DialogRoot,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogBackdrop,
  DialogTitle,
  DialogBody,
} from '../../components/ui/dialog';

type SharepointSiteSelectionModalProps = {
  isOpen: boolean;
  initialSelectedSiteIds: string[];
  onNewSitesSelected: (sharepointSiteIds: string[]) => void;
  onClose: () => void;
};

function SharepointSiteSelectionModal({
  isOpen,
  initialSelectedSiteIds,
  onNewSitesSelected,
  onClose,
}: SharepointSiteSelectionModalProps) {
  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedSiteIds, setSelectedSiteIds] = useState(
    initialSelectedSiteIds
  );

  const { data: sharepointSites, error } = useApi(
    supabase,
    '/integrations',
    '/sharepoint/available_sites',
    { method: 'POST', workspaceId }
  );

  useEffect(() => {
    if (error) {
      toaster.create({
        title: t('general.tryAgainError'),
        type: 'error',
      });
    }
  }, [error]);

  function handleSiteSelectToggle({ siteId }: { siteId: string }) {
    if (selectedSiteIds.includes(siteId)) {
      setSelectedSiteIds(selectedSiteIds.filter((id) => id !== siteId));
    } else {
      setSelectedSiteIds([...selectedSiteIds, siteId]);
    }
  }

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent className="max-h-[512px] self-stretch">
        <DialogHeader>
          <DialogTitle>
            <DialogTitle>
              {t('integrationSettings.sharepoint.siteSelectionModal.title')}
            </DialogTitle>
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogBody
          className="flex min-h-0 flex-col gap-4"
          colorPalette={'maia-accent'}
        >
          <div>
            {t(
              'integrationSettings.sharepoint.siteSelectionModal.availableSites'
            )}
          </div>
          <SearchBar
            onSearchTermChange={(newTerm) => {
              setSearchTerm(newTerm);
            }}
          />

          <div className="flex min-h-0 flex-col gap-2 overflow-y-auto">
            {sharepointSites
              ? sharepointSites?.sites.reduce((elements, site) => {
                  if (
                    initialSelectedSiteIds.includes(site.siteId) ||
                    !site.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return elements;
                  }

                  elements.push(
                    <Checkbox
                      className="flex-shrink-0 truncate"
                      key={site.siteId}
                      checked={selectedSiteIds.includes(site.siteId)}
                      onChange={() => {
                        handleSiteSelectToggle({ siteId: site.siteId });
                      }}
                    >
                      {site.name}
                    </Checkbox>
                  );
                  return elements;
                }, [] as ReactElement[])
              : zeros(5).map((_, index) => {
                  return (
                    <Skeleton key={index}>
                      {/* This is just here to get the right height for the skeleton */}
                      <Checkbox>Loading</Checkbox>
                    </Skeleton>
                  );
                })}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            colorPalette="gray"
            variant={'outline'}
            mr={2}
            onClick={onClose}
          >
            {t('general.cancelButton')}
          </Button>
          <Button
            colorPalette="maia-accent"
            onClick={() => {
              onNewSitesSelected(
                selectedSiteIds.filter(
                  (id) => !initialSelectedSiteIds.includes(id)
                )
              );
              onClose();
            }}
          >
            {t(
              'integrationSettings.sharepoint.siteSelectionModal.confirmButton'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default SharepointSiteSelectionModal;
