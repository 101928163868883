import FileIcon from '../components/FileIcon.js';
import FilePreviewIconButton from '../pages/chat/FilePreviewIconButton.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import { ResultV1V2Abstraction } from './resultsV1V2Conversion.js';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import RenderError from '../components/RenderError.js';
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from '../components/ui/accordion';
import { IconButton } from '@chakra-ui/react';

type SnippetResultType = {
  result: ResultV1V2Abstraction[];
  focusInlineReference: number;
  clearInlineReference: () => void;
};

const extractFirstPageNumber = (pageRange: string | undefined): number => {
  if (!pageRange) return 0;

  const match = pageRange.match(/^\d+/);
  return match ? parseInt(match[0], 10) : 0;
};

function SnippetResult({
  result,
  focusInlineReference,
  clearInlineReference,
}: SnippetResultType) {
  const accordionRef = createRef<HTMLDivElement>();
  const [value, setValue] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (focusInlineReference >= 0 && accordionRef.current) {
      setValue([focusInlineReference.toString()]);
    }
  }, [focusInlineReference]);

  useEffect(() => {
    if (value.length && focusInlineReference !== -1) {
      const timer = setTimeout(() => {
        const accordionItem =
          accordionRef.current?.children[focusInlineReference];
        if (accordionItem) {
          accordionItem.scrollIntoView({ behavior: 'smooth' });
          clearInlineReference();
        }
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [value, focusInlineReference]);

  return (
    <AccordionRoot
      value={value}
      onValueChange={(e) => setValue(e.value)}
      className="flex flex-col gap-y-2"
      ref={accordionRef}
      multiple
    >
      {result.map((snippet, index) => (
        <AccordionItem
          key={index}
          value={index.toString()}
          className="border-maia-border w-full gap-[10px] rounded border px-0 py-0 md:px-4 md:py-3"
        >
          <AccordionItemTrigger
            indicatorPlacement="start"
            className="hover:bg-gray-100"
          >
            <div className="flex w-full items-center justify-between font-bold">
              <div className="justify-cente flex items-center">
                <FileIcon
                  className="text-chakra-gray-400 mr-3 font-black"
                  name={snippet.filename}
                />
                <div className="flex min-w-0 max-w-[420px] items-center text-sm">
                  <div className="flex max-w-full flex-col items-start">
                    <div className="flex max-w-full items-center truncate">
                      <span className="text-maia-text-dark mr-1 font-medium">
                        [{index + 1}]
                      </span>
                      <div className="text-maia-text-dark truncate font-medium leading-[26px]">
                        {snippet.filename}
                      </div>
                    </div>
                    {snippet.pageNumbers.length > 0 &&
                      snippet.filename.endsWith('.pdf') && (
                        <div className="text-chakra-gray-500 max-w-full truncate text-xs font-medium">
                          {`${snippet.pageNumbers.length === 1 && !snippet.pageNumbers[0]?.includes('-') ? t('chat.page') : t('chat.pages')}: ${snippet.pageNumbers.join(', ')}`}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="flex content-center">
                <FilePreviewIconButton
                  fileId={snippet.fileId}
                  documentId={snippet.documentId}
                  fileName={snippet.filename}
                  startingPage={extractFirstPageNumber(snippet.pageNumbers[0])}
                />
                <IconButton
                  aria-label={`View ${snippet.filename}`}
                  variant={'ghost'}
                  className="text-maia-text-dark font-medium"
                >
                  <FontAwesomeIcon icon={faEye} />
                </IconButton>
              </div>
            </div>
          </AccordionItemTrigger>
          <AccordionItemContent>
            <ErrorBoundary fallback={<RenderError content={snippet.content} />}>
              <ReactMarkdown
                className="prose min-w-0 max-w-none text-sm"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw as any]}
              >
                {snippet.content}
              </ReactMarkdown>
            </ErrorBoundary>
          </AccordionItemContent>
        </AccordionItem>
      ))}
    </AccordionRoot>
  );
}

export default SnippetResult;
