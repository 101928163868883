import { useEffect, useState } from 'react';

import Disable2FAModal from './modals/Disable2FAModal';
import SettingsSwitch from '../../settings/SettingsSwitch';
import TotpModal from './modals/TotpModal';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { toaster } from '../../../components/ui/toaster';

type AuthenticatorAppMFAProps = {
  setMfaCheck: (value: boolean) => void;
};

export default function AuthenticatorAppMFA({
  setMfaCheck,
}: AuthenticatorAppMFAProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const userInfo = useBoundStore((state) => state.userInfo);

  const [totpModal, setTotpModal] = useState(false);
  const [disable2faModal, setDisable2faModal] = useState(false);

  const [factorId, setFactorId] = useState<string>('');
  const isMFAEnabled = factorId !== '';

  const getAuthenticationLevel = async () => {
    const { data, error } = await supabase.auth.mfa.listFactors();

    if (error) {
      toaster.create({
        title: t('userSettings.security.generalError'),
        type: 'error',
      });
      console.error(error.message);
    }

    // since supabase currently support only one factor i.e. TOTP
    const enabledFactorId = data?.totp?.[0]?.id || '';

    setFactorId(enabledFactorId);
    setMfaCheck(true);
  };

  const handleDisable2FA = async () => {
    if (!factorId || !userInfo?.userId) return;
    const { error } = await supabase.auth.mfa.unenroll({
      factorId,
    });
    if (error) {
      console.error(error.message);
      return;
    }
    await supabase.auth.refreshSession();
    await getAuthenticationLevel();
    setMfaCheck(true);
  };

  useEffect(() => {
    getAuthenticationLevel();
  }, [totpModal]);
  return (
    <>
      <SettingsSwitch
        title={t('userSettings.security.authenticatorAppTitle')}
        subtitle={t('userSettings.security.authenticatorAppSubtitle')}
        switchProps={{
          checked: isMFAEnabled,
          onChange: isMFAEnabled
            ? () => setDisable2faModal(true)
            : () => setTotpModal(true),
        }}
      />

      {totpModal && (
        <TotpModal isOpen={totpModal} onClose={() => setTotpModal(false)} />
      )}
      {disable2faModal && (
        <Disable2FAModal
          title={t('userSettings.security.disable2FAModal.authenticatorTitle')}
          subtitle={t(
            'userSettings.security.disable2FAModal.authenticatorSubtitle'
          )}
          isOpen={disable2faModal}
          onClose={() => setDisable2faModal(false)}
          onConfirm={handleDisable2FA}
        />
      )}
    </>
  );
}
