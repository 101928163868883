import { BreadcrumbLink, BreadcrumbRoot } from '@/components/ui/breadcrumb';

type SharepointBreadCrumbsProps = {
  className?: string;
  folders: { id: string; name: string }[];
  onFolderSelect: (index: number) => void;
};

function SharepointBreadCrumbs({
  className,
  folders,
  onFolderSelect,
}: SharepointBreadCrumbsProps) {
  return (
    <BreadcrumbRoot separator={'>'} className={className}>
      {folders.map((folder, index) => {
        return (
          <BreadcrumbLink
            key={index}
            as={'div'}
            onClick={() => onFolderSelect(index)}
          >
            {folder.name}
          </BreadcrumbLink>
        );
      })}
    </BreadcrumbRoot>
  );
}

export default SharepointBreadCrumbs;
