import {
  IconDefinition,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faWarning,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';

import { Database } from 'common-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@chakra-ui/react';
import LogoPure from '../../components/Logo/LogoPure';
import { fetchApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useState } from 'react';

const typeClassNameMap: {
  [type in Database['public']['Enums']['banner_type']]: string;
} = {
  WARNING: 'border-yellow-300 bg-yellow-50',
  ERROR: 'border-red-400 bg-red-100',
  INFO: 'border-blue-300 bg-blue-50',
  SUCCESS: 'border-green-300 bg-green-50',
  MAIA: 'from-maia-brand-purple to-maia-brand-blue bg-gradient-to-r text-white',
};

const typeIconClassNameMap: {
  [type in Exclude<Database['public']['Enums']['banner_type'], 'MAIA'>]: string;
} = {
  WARNING: 'text-yellow-400',
  ERROR: 'text-red-500',
  INFO: 'text-blue-400',
  SUCCESS: 'text-green-500',
};

const typeIconMap: {
  [type in Exclude<
    Database['public']['Enums']['banner_type'],
    'MAIA'
  >]: IconDefinition;
} = {
  WARNING: faWarning,
  ERROR: faCircleExclamation,
  INFO: faCircleInfo,
  SUCCESS: faCircleCheck,
};

type GenericBannerProps =
  Database['public']['Views']['user_banner_view']['Row'];

function GenericBanner({
  title,
  body,
  status_type,
  id,
  workspace_id: bannerWorkspaceId,
}: GenericBannerProps) {
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);
  const [showBanner, setShowBanner] = useState(true);

  async function handleHideBanner() {
    // If the call to acknowledge the banner is not successful, the banner will show up again on the next reload. Not a big deal.
    setShowBanner(false);

    await fetchApi(supabase, '/user', '/acknowledge_banner', {
      method: 'POST',
      bannerId: id!, // This can not be null, but the typing is 'wrong' because of how views work https://dba.stackexchange.com/a/285411
    });
  }

  if (
    showBanner &&
    (bannerWorkspaceId === workspaceId || bannerWorkspaceId === null)
  ) {
    return (
      <div
        className={`absolute z-30 flex w-full items-center justify-between gap-4 border-b-2 px-8 py-3 ${typeClassNameMap[status_type!]}`}
      >
        {status_type === 'MAIA' ? (
          <LogoPure className="fill-white" size={26} />
        ) : (
          <FontAwesomeIcon
            icon={typeIconMap[status_type!]}
            className={`text-2xl ${typeIconClassNameMap[status_type!]}`}
          />
        )}
        <div className="flex flex-col gap-2">
          {title && (
            <div
              className="text-lg font-semibold"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: body! }} />
        </div>
        <IconButton
          onClick={handleHideBanner}
          variant={'ghost'}
          colorPalette={status_type === 'MAIA' ? 'white' : 'gray'}
          aria-label="close"
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </div>
    );
  } else {
    return null;
  }
}

export default GenericBanner;
