import { Separator, HStack, DialogTitle } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../../../utils/useApi';
import { useEffect, useRef, useState } from 'react';
import ResendEmail2FA from '../ResendEmail2FA';
import { useBoundStore } from '../../../../store/useBoundStore.js';
import { toaster } from '../../../../components/ui/toaster';
import { Button } from '../../../../components/ui/button';
import {
  DialogRoot,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@/components/ui/dialog';
import { PinInput } from '@/components/ui/pin-input';

type EmailVerificationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function EmailVerificationModal({
  isOpen,
  onClose,
}: EmailVerificationModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);

  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const [challengeId, setChallengeId] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const setup2FA = async () => {
    // enroll in 2FA
    const { success, data } = await fetchApi(
      supabase,
      '/auth',
      '/email/enroll',
      {
        method: 'POST',
      }
    );
    if (success) {
      setChallengeId(data.challengeId);
    } else {
      toaster.create({
        title: t('userSettings.security.generalError'),
        type: 'error',
      });
    }
    return success;
  };

  const handle2FASetupConfirmation = async () => {
    if (!challengeId) return;

    // verify 2FA
    const res = await fetchApi(supabase, '/auth', '/email/enroll_verify', {
      method: 'POST',
      challengeId,
      otp: code,
    });
    if (res.success) {
      toaster.create({
        title: t('userSettings.security.emailVerificationModal.success'),
        type: 'success',
      });
      onClose();
    } else if (res.status === 400 && res.data?.error) {
      if (res.data.error === 'Invalid OTP') {
        toaster.create({
          title: t(
            'userSettings.security.emailVerificationModal.invalidOtpError'
          ),
          type: 'error',
        });
      } else if (res.data.error === 'OTP Expired') {
        toaster.create({
          title: t(
            'userSettings.security.emailVerificationModal.otpExpiredError'
          ),
          type: 'error',
        });
      }
    } else {
      toaster.create({
        title: t('userSettings.security.generalError'),
        type: 'error',
      });
    }
  };

  const sendNewOtp = async () => {
    const res = await setup2FA();
    res
      ? toaster.create({
          title: t(
            'userSettings.security.emailVerificationModal.resendCodeSuccess'
          ),
          type: 'success',
        })
      : toaster.create({
          title: t('userSettings.security.generalError'),
          type: 'error',
        });
  };

  useEffect(() => {
    setup2FA();
  }, []);

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('userSettings.security.emailVerificationModal.title')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody className="flex w-full flex-col items-center gap-y-4 pb-12">
          <div className="mb-4">
            {t('userSettings.security.emailVerificationModal.subtitle')}
          </div>
          <div className="flex w-full flex-col items-center gap-y-2">
            <span className="font-medium">
              {t(
                'userSettings.security.emailVerificationModal.enterAuthenticationCodePrompt'
              )}
            </span>
            <HStack>
              <PinInput
                variant={'flushed'}
                colorPalette={'maia-accent'}
                onValueChange={(e) => setCode(e.value.join(''))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handle2FASetupConfirmation();
                }}
                size={'md'}
                autoFocus
                otp
              />
            </HStack>
            <ResendEmail2FA sendNewOtp={sendNewOtp} />
          </div>
        </DialogBody>
        <Separator />
        <DialogFooter gap={2}>
          <Button
            size={'sm'}
            className="font-medium"
            variant="outline"
            onClick={onClose}
          >
            {t('userSettings.security.emailVerificationModal.secondaryButton')}
          </Button>
          <Button
            ref={confirmButtonRef}
            colorPalette="maia-purple"
            size={'sm'}
            onClick={handle2FASetupConfirmation}
            disabled={code.length < 6}
          >
            {t('userSettings.security.emailVerificationModal.primaryButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
