import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decodeName } from 'common-ts';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi } from '../../../utils/useApi.js';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '../../../components/ui/dialog.js';
import { Field } from '../../../components/ui/field.js';
import { DialogTitle, Input } from '@chakra-ui/react';
import { Alert } from '../../../components/ui/alert.js';
import { Tooltip } from '../../../components/ui/tooltip.js';
import { Button } from '../../../components/ui/button.js';

type EditCollectionModalProps = {
  deleteTooltipLabel?: string;
  deleteIsDisabled?: boolean;
  isOpen: boolean;
  selectedCollectionId: string | undefined;
  selectedCollectionName: string | undefined;
  onClose: () => void;
  onDelete: () => void;
  fetchCollections: () => void;
};

function EditCollectionModal({
  deleteTooltipLabel,
  deleteIsDisabled,
  isOpen,
  selectedCollectionId,
  selectedCollectionName = '',
  onClose,
  onDelete,
  fetchCollections,
}: EditCollectionModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    setShowDeleteWarning(false);
  }, [isOpen]);

  const [collectionName, setCollectionName] = useState(
    decodeName(selectedCollectionName)
  );
  const [collectionNameError, setCollectionNameError] = useState(false);

  async function handleUpdateCollectionTitle() {
    if (!collectionName) {
      setCollectionNameError(true);
      return;
    }
    if (collectionNameError) setCollectionNameError(false);
    if (!selectedCollectionId) return;

    const resp = await fetchApi(supabase, '/share', '/rename_bucket', {
      method: 'POST',
      bucketId: selectedCollectionId,
      name: collectionName,
      workspaceId,
    });
    if (resp.data?.success) fetchCollections();
    onClose();
  }

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && onClose()}
      motionPreset={'slide-in-bottom'}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('fileManagerPanel.editCollectionModalHeader')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody>
          <Field
            invalid={collectionNameError}
            label={t('fileManagerPanel.editCollectionModalInputLabel')}
            errorText={t('fileManagerPanel.editCollectionInputEmptyError')}
            colorPalette={'maia-accent'}
          >
            <Input
              maxLength={128}
              onChange={(e) => setCollectionName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateCollectionTitle();
                }
              }}
              value={collectionName}
              disabled={showDeleteWarning}
              autoFocus
            />
          </Field>
          {showDeleteWarning && (
            <Alert
              variant={'subtle'}
              status="warning"
              mt={'3'}
              title={t('fileManagerPanel.deleteCollectionModalTitle')}
            >
              {t('fileManagerPanel.deleteCollectionModalSubtitle')}
            </Alert>
          )}
        </DialogBody>
        <DialogFooter>
          {showDeleteWarning ? (
            <div className="flex items-center gap-2">
              <Button
                className="font-medium"
                onClick={onClose}
                variant="outline"
              >
                {t('general.cancelButton')}
              </Button>
              <Tooltip
                disabled={!deleteTooltipLabel}
                content={deleteTooltipLabel}
                openDelay={500}
              >
                <Button
                  colorPalette={'red'}
                  disabled={deleteIsDisabled}
                  onClick={onDelete}
                >
                  {t('general.deleteButton')}
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div className="flex w-full items-center justify-between">
              <Button
                disabled={deleteIsDisabled}
                colorPalette="red"
                variant={'ghost'}
                onClick={() => setShowDeleteWarning(true)}
              >
                <FontAwesomeIcon icon={faTrash} />
                {t('general.deleteButton')}
              </Button>
              <div className="flex items-center gap-2">
                <Button
                  className="font-medium"
                  onClick={onClose}
                  variant="outline"
                >
                  {t('general.cancelButton')}
                </Button>
                <Button
                  className="font-medium"
                  colorPalette="maia-accent"
                  onClick={handleUpdateCollectionTitle}
                >
                  {t('general.saveButtons')}
                </Button>
              </div>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default EditCollectionModal;
