import { useCallback, useState } from 'react';
import { HistoryItemCategory } from '../../utils/historyItemsCategories';

export type ChatItem = {
  created_at: string;
  deleted_at: string | null;
  id: string;
  topic: string | null;
  category: HistoryItemCategory;
};

/**
 * Custom hook that manages the state of chatItems, providing a way to store and update chat data.
 *
 * @returns Returns a tuple containing:
 *          1. The current map of chats.
 *          2. A function to update the chat map, which also marks the hook as initialized on the first call.
 *          3. A boolean indicating whether the chat data has been initialized.
 */
export function useChatsItems() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [chats, setChatsState] = useState<Map<string, ChatItem>>(new Map());

  // Custom setChats function that also sets 'initialized' to true the first time it's called
  const setChats = useCallback(
    (newChats: Map<string, ChatItem>) => {
      setChatsState(newChats);
      if (!initialized) {
        setInitialized(true);
      }
    },
    [initialized]
  );

  return [chats, setChats, initialized] as const;
}
