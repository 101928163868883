import { Navigate, Route, Routes } from 'react-router-dom';

import ChatPage from '../chat/ChatPage';
import FileManager from '../fileManager/FileManager';
import OnboardingProcess from '../onboarding/OnboardingProcess';
import Pricing from '../pricing/Pricing';
import { ROUTES } from 'common-ts';
import Search from '../search/Search.js';
import Settings from '../settings/Settings.js';
import { fetchApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useEffect } from 'react';

function HomeRoutes() {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const isOnboarded = useBoundStore((state) => state.isOnboarded);
  const featureFlags = useBoundStore((state) => state.featureFlags);

  useEffect(() => {
    // If sharepoint integration is activated, silently request permission sync
    async function requestPermissionSync() {
      await fetchApi(
        supabase,
        '/integrations',
        '/sharepoint/request_permission_sync',
        { method: 'POST', workspaceId }
      );
    }

    if (featureFlags?.sharepoint) {
      requestPermissionSync();
    }
  }, [workspaceId]);

  return (
    <Routes>
      {workspaceLicenseType !== 'FREE' && (
        <>
          <Route
            path=""
            element={<Navigate to={ROUTES.HOME.$.CHAT.buildRelativePath({})} />}
          />
          <Route
            path={ROUTES.HOME.$.CHAT.relativePath}
            element={<ChatPage />}
          />
          <Route
            path={ROUTES.HOME.$.FILE_MANAGER.relativePath}
            element={<FileManager />}
          />
          <Route
            path={ROUTES.HOME.$.SEARCH.relativePath}
            element={<Search />}
          />
        </>
      )}
      <Route
        path={ROUTES.HOME.$.SETTINGS.relativePath}
        element={<Settings />}
      />
      <Route path={ROUTES.HOME.$.PRICING.relativePath} element={<Pricing />} />
      <Route
        path={ROUTES.HOME.$.ONBOARDING.relativePath}
        element={<OnboardingProcess />}
      />
      <Route
        path="*"
        element={
          <Navigate
            to={
              workspaceLicenseType === 'FREE'
                ? isOnboarded
                  ? ROUTES.HOME.$.SETTINGS.buildRelativePath({})
                  : ROUTES.HOME.$.PRICING.buildRelativePath(
                      {},
                      {
                        navBackOnClose:
                          ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL.buildPath({
                            workspaceId,
                          }),
                      }
                    )
                : ROUTES.HOME.$.CHAT.buildRelativePath({})
            }
          />
        }
      />
    </Routes>
  );
}

export default HomeRoutes;
