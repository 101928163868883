import {
  AvailableModelEnum,
  Database,
  EnrichedBasicLayoutItem,
  ExperimentalModelEnum,
  FileNameId,
  MaiaApiRoutes,
  MergedLayoutItemsWithAnnotations,
  SearchClient,
  SearchServer,
  SnippetResultsV1,
  decodeName,
  isExperimentalModel,
} from 'common-ts';
import { Text, Textarea } from '@chakra-ui/react';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { iframeSearchSocket, searchSocket } from '../../utils/socket.js';

import ChatMessage from '../chat/ChatMessage.js';
import DocumentChatMessageGroup from '../chat/DocumentChatMessageGroup.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardEvent } from 'react';
import MessageLimit from './MessageLimit.js';
import QueryResults from '../../utils/QueryResults.js';
import StreamingChatMessage from '../chat/StreamingChatMessage.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useSocketIo } from '../../utils/useSocketIo.js';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { toaster } from '../../components/ui/toaster.js';
import { Button } from '../../components/ui/button.js';
import {
  MenuRoot,
  MenuTrigger,
  MenuContent,
  MenuItem,
} from '../../components/ui/menu.js';
import MessageLoadingIndicator from '../chat/MessageLoadingIndicator.js';

type Collection = {
  id: string;
  name: string;
  workspaceId?: string;
};

type SearchProps = {
  llmModel: AvailableModelEnum | ExperimentalModelEnum;
  collections: Collection[];
  sentRequests: number;
  maxRequests: number | null;
  handleRateLimit: () => void;
  streamingMessageContentRef?: React.MutableRefObject<string>;
  shouldClearSearch?: boolean;
  accessToken?: string;
  workspaceId?: string;
  lastFetched?: string;
  searchAnswerId?: string;
  iframeId?: string | null;
  iFrameSettings?: MaiaApiRoutes['/iframe']['/:iframe_id']['response'];
  userIsMarker?: boolean;
  ModelSelectionJSX: ReactNode;
  powerModeAccess: boolean;
  handleShouldClearSearch?: (clearSearch: boolean) => void;
  fetchRateLimitInfo?: () => Promise<void>;
  optimisticUpdateSentRequests?: (sentRequests: number) => void;
  loadSearchHistory?: () => void;
  handleLastFetched?: (answerId: string) => void;
};

export default function BasicSearch(props: SearchProps) {
  const {
    llmModel,
    collections,
    sentRequests,
    maxRequests,
    handleRateLimit,
    lastFetched = '',
    accessToken = '',
    searchAnswerId = '',
    workspaceId = '',
    iframeId = null,
    iFrameSettings,
    ModelSelectionJSX = <></>,
    shouldClearSearch = false,
    userIsMarker = false,
    handleShouldClearSearch = () => {},
    fetchRateLimitInfo = async () => {},
    optimisticUpdateSentRequests = () => {},
    loadSearchHistory = () => {},
    handleLastFetched = () => {},
    powerModeAccess,
  } = props;

  const supabase = useBoundStore((state) => state.supabase);
  const { t } = useTranslation();
  const [focusInlineReference, setFocusInlineReference] = useState<number>(-1);
  const isPowerModeEnabled = powerModeAccess;
  const [query, setQuery] = useState<string>('');
  const [snippets, setSnippets] = useState<
    SnippetResultsV1 | EnrichedBasicLayoutItem | undefined
  >(undefined);
  const [wholeFiles, setWholeFiles] = useState<FileNameId[]>([]);
  const [fileSummaries, setFileSummaries] = useState<FileNameId[]>([]);
  const [open, setOpen] = useState(false);
  const [glossaryUsed, setGlossaryUsed] = useState(false);

  const [searchAnswerType, setSearchAnswerType] =
    useState<Database['public']['Enums']['search_answer_type']>();
  const [loadingSnippets, setLoadingSnippets] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] = useState<
    Collection | undefined
  >(undefined);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const streamingMessageRef = useRef('');
  const [searchRequest, setSearchRequest] = useState<{
    searchRequestId: string;
    like: boolean | null;
  }>({
    searchRequestId: '',
    like: null,
  });
  const [loadingStream, setLoadingStream] = useState<boolean>(false);
  const { isConnected, lastMessage, sendMessage } = useSocketIo<
    SearchClient,
    SearchServer
  >({
    socket: iframeId ? iframeSearchSocket : searchSocket,
    onReconnectStop: () => {
      toaster.create({
        title: t('general.reloadError'),
        type: 'error',
      });
    },
  });

  const getWorkspaceId = () => {
    if (workspaceId) return workspaceId;

    if (selectedCollection) {
      const tempWorkspaceId = collections.find(
        (collection) => collection.id === selectedCollection?.id
      )?.workspaceId;

      return tempWorkspaceId;
    }

    if (iFrameSettings?.searchAllCollections) {
      return collections[0]?.workspaceId;
    }
  };

  async function handleSearch() {
    if (
      query === '' ||
      (!selectedCollection && !iFrameSettings?.searchAllCollections)
    )
      return;
    setSearchRequest({
      searchRequestId: '',
      like: null,
    });
    setSearchAnswerType(isPowerModeEnabled ? 'POWER_MODE_SEARCH' : 'SEARCH');

    if (maxRequests !== null && sentRequests >= maxRequests) {
      handleRateLimit();
      return;
    }

    // Keep original search query in case user starts typing again while snippets are loading.
    const tempSearchQuery = query;
    setSnippets(undefined);
    setFileSummaries([]);
    setWholeFiles([]);
    streamingMessageRef.current = '';
    const searchRequestId = v4();
    setSearchRequest({
      searchRequestId,
      like: null,
    });

    const wsId = getWorkspaceId();

    if (!wsId) {
      toaster.create({
        title: t('general.tryAgainError'),
        type: 'error',
      });
      return;
    }
    // In case of power mode or experimental models, use 4o as goto model
    const model =
      isPowerModeEnabled || isExperimentalModel(llmModel)
        ? AvailableModelEnum.GPT_4_O
        : llmModel;

    const message: SearchServer = iframeId
      ? {
          type: isPowerModeEnabled ? 'POWER_MODE_SEARCH' : 'SEARCH',
          model,
          requestId: searchRequestId,
          query: tempSearchQuery,
          bucketIds: iFrameSettings?.searchAllCollections
            ? collections.map((collection) => collection.id)
            : [selectedCollection!.id],
          iframeId,
          workspaceId: wsId,
        }
      : {
          type: isPowerModeEnabled ? 'POWER_MODE_SEARCH' : 'SEARCH',
          model,
          requestId: searchRequestId,
          query: tempSearchQuery,
          bucketIds: [selectedCollection!.id],
          accessToken: accessToken || '',
          workspaceId,
        };
    sendMessage(message);
    setLoadingStream(true);
    setLoadingSnippets(true);
    accessToken && optimisticUpdateSentRequests(1);
  }

  const handleInlineReferenceChange = (index: number) => {
    setFocusInlineReference(index);
  };
  const clearSearch = () => {
    setSnippets(undefined);
    setFileSummaries([]);
    setWholeFiles([]);
    setQuery('');
    streamingMessageRef.current = '';
  };

  const handleAbort = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      if (isConnected) {
        sendMessage({
          type: 'USER_ABORT',
          workspaceId,
        } as SearchServer);
      }
    }
  };

  useEffect(() => {
    if (
      shouldClearSearch &&
      query !== '' &&
      snippets !== undefined &&
      streamingMessageRef.current !== ''
    ) {
      handleShouldClearSearch(false);
      clearSearch();
    }
  }, [shouldClearSearch]);

  useEffect(() => {
    if (lastMessage) {
      switch (lastMessage.type) {
        case 'MAIA_DOCUMENT_SNIPPETS':
          setSnippets(lastMessage.snippets);
          if (lastMessage.wholeFiles) {
            setWholeFiles(lastMessage.wholeFiles);
          }
          if (lastMessage.fileSummaries) {
            setFileSummaries(lastMessage.fileSummaries);
          }

          setLoadingSnippets(false);
          break;
        case 'MAIA_GLOSSARY_USED': {
          setGlossaryUsed(lastMessage.used);
          break;
        }
        case 'MAIA_STREAM': {
          setLoadingStream(false);
          streamingMessageRef.current = `${streamingMessageRef.current}${lastMessage.text}`;
          break;
        }
        case 'MAIA_STREAM_END': {
          setLoadingStream(false);
          if (accessToken) {
            handleLastFetched(lastMessage.answerId);
            loadSearchHistory();
          }
          fetchRateLimitInfo();
          break;
        }
        case 'MAIA_STREAM_ERROR':
          setLoadingStream(false);
          switch (lastMessage.code) {
            case 520: {
              toaster.create({
                title: t('general.socket.genericStreamError'),
                type: 'error',
              });
              break;
            }
            case 521: {
              toaster.create({
                title: t('general.socket.messageTooLongError'),
                type: 'error',
              });
              break;
            }
            case 522: {
              toaster.create({
                title: t('general.socket.contentFilterError'),
                type: 'error',
              });
              break;
            }
            case 523: {
              toaster.create({
                title: t('general.socket.powerModeNotAllowedError'),
                type: 'error',
              });
              break;
            }
            case 524: {
              toaster.create({
                title: t('general.socket.powerModeJsonError'),
                type: 'error',
              });
              break;
            }
          }
          break;
        case 'MAIA_SPAM_PROTECTION_ERROR':
          setLoadingStream(false);
          toaster.create({
            title: t('general.spamProtectionHeader'),
            description: t('general.spamProtectionDescription'),
            type: 'error',
          });
          break;
        case 'WS_ERROR':
          toaster.create({
            title: t('general.socket.webSocketError'),
            type: 'error',
          });
          setLoadingStream(false);
          break;
        case 'MAIA_UNAUTHORIZED_ERROR':
          toaster.create({
            title: t('general.socket.unauthorizedError'),
            type: 'error',
          });
          break;
        case 'MAIA_DB_ERROR':
        case 'MAIA_INACTIVE_WORKSPACE_ERROR':
        // @ts-ignore Fallthrough intended
        case 'MAIA_MODEL_NOT_SUPPORTED':
          console.error(lastMessage.type);
          toaster.create({
            type: 'error',
            title: t('general.tryAgainError'),
          });
        case 'MAIA_ABORTED':
          setLoadingSnippets(false);
          setLoadingStream(false);
          break;
        case 'MAIA_SEARCH_IN_FILES_WARNING':
        case 'MAIA_EMPTY_COLLECTION':
          setLoadingSnippets(false);
          setLoadingStream(false);
          toaster.create({
            type: 'error',
            title: t('general.emptyCollectionError'),
          });
          break;
        case 'MAIA_IFRAME_LIMIT':
          setLoadingSnippets(false);
          setLoadingStream(false);
          toaster.create({
            type: 'error',
            title: t('iframe.limitReachedError'),
          });
          break;
        case 'POWER_MODE_EXTRACTIONS_STARTED':
        case 'POWER_MODE_FILE_INFO_OVERFLOW':
        case 'POWER_MODE_SNIPPET_CONTEXT_CUTOFF':
        case 'POWER_MODE_WHOLE_FILE_DISCARDED':
        case 'POWER_MODE_PART_OF_DOC_DISCARDED':
        case 'POWER_MODE_DOC_SUMMARY_DISCARDED':
        // These are only for the power mode logs (on dev and local) that are currently only available in chat.
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (
      lastFetched !== searchAnswerId &&
      searchAnswerId !== '' &&
      !shouldClearSearch
    ) {
      streamingMessageRef.current = '';
      supabase
        .from('search_answer')
        .select(
          '*, search_answer_result_v1(*), search_request_view(*), search_answer_result_v2(*)'
        )
        .eq('id', searchAnswerId)
        .then((res) => {
          const searchAnswer = res.data;
          if (searchAnswer && searchAnswer[0]) {
            if (searchAnswer[0].search_answer_result_v2[0]) {
              setSnippets({
                type: 'items',
                items: searchAnswer[0].search_answer_result_v2[0]
                  .metadata as MergedLayoutItemsWithAnnotations[],
              });
              setFileSummaries(
                (searchAnswer[0].search_answer_result_v2[0].file_summaries ??
                  []) as FileNameId[]
              );
              setWholeFiles(
                (searchAnswer[0].search_answer_result_v2[0].whole_files ??
                  []) as FileNameId[]
              );
              searchAnswer[0].type && setSearchAnswerType(searchAnswer[0].type);
            } else if (searchAnswer[0].search_answer_result_v1[0])
              setSnippets(
                searchAnswer[0].search_answer_result_v1[0]
                  .metadata as SnippetResultsV1
              );

            streamingMessageRef.current = searchAnswer[0].text;
            if (searchAnswer[0].search_request_view) {
              setQuery(searchAnswer[0].search_request_view.query || '');
              setSearchRequest({
                searchRequestId: searchAnswer[0].search_request_view.id || '',
                like: searchAnswer[0].search_request_view.like_status,
              });
            }
            supabase
              .from('search_request_searched_files')
              .select('*, storage_file_view(bucket_id)')
              .eq('search_request_id', searchAnswer[0].search_request_id)
              .then((res) => {
                if (res.data?.[0]) {
                  const bucketIdRes = res.data[0].storage_file_view?.bucket_id;
                  if (bucketIdRes) {
                    const { name = '' } =
                      collections.find(
                        (collection) => collection.id === bucketIdRes
                      ) || {};
                    setSelectedCollection({
                      id: bucketIdRes,
                      name,
                    });
                  }
                }
              });
          }
        });
    }
  }, [searchAnswerId, lastFetched]);

  useEffect(() => {
    clearSearch();
    loadSearchHistory();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        textAreaRef.current?.focus();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const memoizedQueryResults = useMemo(() => {
    if (!snippets) return null;

    return (
      <div className="relative my-8 h-full">
        {iFrameSettings?.hideSnippets ? null : (
          <QueryResults
            snippets={snippets}
            wholeFiles={wholeFiles}
            fileSummaries={fileSummaries}
            focusInlineReference={focusInlineReference}
            clearInlineReference={() => setFocusInlineReference(-1)}
            isPowerMode={isPowerModeEnabled}
            isExpanded
            glossaryUsed={glossaryUsed}
          />
        )}
      </div>
    );
  }, [
    snippets,
    wholeFiles,
    fileSummaries,
    focusInlineReference,
    isPowerModeEnabled,
    iFrameSettings,
  ]);

  return (
    <div className="flex h-full w-full max-w-5xl flex-col items-center justify-center gap-y-8">
      <div
        className={`border-maia-border flex w-full flex-col items-center gap-6 border-b bg-white p-8 md:rounded-xl md:border`}
        onKeyUp={handleAbort}
      >
        <div className="flex w-full items-center justify-between">
          <div className="text-maia-purple-900 flex w-full text-3xl font-bold">
            {iFrameSettings?.whitelabel ? '' : t('searchPanel.heading')}
          </div>
          <div>{ModelSelectionJSX}</div>
        </div>
        {iFrameSettings?.searchAllCollections ? null : (
          <div
            className={`flex w-full flex-col justify-between font-normal md:flex-row`}
          >
            <Text className="py-1.5">{t('searchPanel.inputText')}</Text>
            <MenuRoot
              open={open}
              onOpenChange={(e) => {
                setOpen(e.open);
              }}
            >
              <MenuTrigger
                className="border-maia-border hover:border-maia-accent w-full cursor-pointer rounded-md border px-3 py-1.5 text-left lg:max-w-xs"
                asChild
              >
                <Button className="flex items-center justify-between" unstyled>
                  {selectedCollection?.name
                    ? decodeName(selectedCollection.name)
                    : t('searchPanel.dataChooser')}
                  {open ? (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      className="text-maia-accent"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </Button>
              </MenuTrigger>
              <MenuContent className="tall:max-h-96 flex max-h-32 w-80 flex-col gap-1 overflow-auto py-1.5 text-left shadow">
                {collections.map((collection) => (
                  <MenuItem
                    value={collection.name}
                    key={collection.id}
                    onClick={() => {
                      setSelectedCollection(collection);
                    }}
                    className="hover:bg-maia-blue-100"
                  >
                    {decodeName(collection.name)}
                  </MenuItem>
                ))}
              </MenuContent>
            </MenuRoot>
          </div>
        )}
        <Textarea
          ref={textAreaRef}
          disabled={loadingSnippets}
          className="border-maia-border focus-visible:shadow-textboxActive text-sm"
          placeholder={t('searchPanel.promptPlaceholder')}
          value={query}
          minHeight={131}
          onChange={(e) => setQuery(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSearch();
            }
          }}
        />
        <div className="flex w-full items-center justify-end">
          <Button
            colorPalette="maia-accent"
            flexShrink={0}
            size={'sm'}
            loading={loadingSnippets || loadingStream}
            disabled={
              query.length === 0 ||
              !(selectedCollection || iFrameSettings?.searchAllCollections)
            }
            loadingText={t('searchPanel.searchLoadingMessage')}
            onClick={handleSearch}
          >
            {t('searchPanel.searchButton')}
          </Button>
        </div>
      </div>
      <div className="flex w-full min-w-0 justify-center">
        {!loadingSnippets &&
        !loadingStream &&
        streamingMessageRef.current.length === 0 ? (
          <></>
        ) : loadingSnippets && loadingStream ? (
          <MessageLoadingIndicator
            chatInputSelected={false}
            powerMode={false}
            handleAbortAnswerGeneration={() => {}}
          />
        ) : searchAnswerType ? (
          <DocumentChatMessageGroup
            className="w-full"
            mode={searchAnswerType.includes('POWER') ? 'POWER' : 'NORMAL'}
          >
            <ChatMessage
              props={{ className: 'min-w-0 w-full' }}
              msg={{
                type: 'SEARCH_REQUEST',
                msg: streamingMessageRef.current,
                msgId: searchAnswerId,
                like: searchRequest.like,
              }}
              userIsMarker={userIsMarker}
              handleInlineReferenceChange={handleInlineReferenceChange}
              whiteLabel={iFrameSettings?.whitelabel}
            />
          </DocumentChatMessageGroup>
        ) : (
          <StreamingChatMessage
            chatMessageProps={{
              className: `w-full m-4 md:m-0`,
            }}
            msg={{
              type: 'SEARCH_REQUEST',
              msg: '',
              msgId: '',
              like: searchRequest.like,
            }}
            streamingMessageContentRef={streamingMessageRef}
            onKeyUp={handleAbort}
          />
        )}
      </div>
      <div className={`flex w-full flex-grow flex-col justify-between`}>
        {memoizedQueryResults}
        <MessageLimit
          className="mt-4"
          sentMessages={sentRequests}
          maxMessages={maxRequests}
        />
      </div>
    </div>
  );
}
