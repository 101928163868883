import { ReactNode, useEffect, useState } from 'react';
import TableHeader, { SortDirection } from './TableHeader.js';
import { Table } from '@chakra-ui/react';
import { Checkbox } from '../ui/checkbox.js';

/**
 * Function that returns the *value* of a cell given the object of a row.
 */
export type CellValue<T> =
  | ((object: T) => string | undefined)
  | ((object: T) => number | undefined)
  | ((object: T) => string[])
  | ((object: T) => number[]);

export type SortableTableColumn<T> = {
  title: ReactNode;
  hidden?: boolean;
  cellValue: CellValue<T>;
  cellRender?: (object: T) => ReactNode;
  filterOptionRender?: (object: T) => string;
  sortingRuleASC?: (objectA: T, objectB: T) => number;
  sortingRuleDESC?: (objectA: T, objectB: T) => number;
  shouldSort?: boolean;
  shouldFilter?: boolean;
  filterOptions?: Set<string>;
  columnClassName?: string;
};

type SortableTableProps<T> = {
  objects: T[];
  customColumns: Record<string, SortableTableColumn<T>>;
  onRowClick?: (object: T) => void;
  cellReplacement?: (
    object: T,
    cellContent: ReactNode,
    columnKey?: string
  ) => ReactNode;
  rowsSelectable?: {
    rowSelected: (object: T) => boolean;
    onSelectionChange: (object: T, selected: boolean) => void;
  };
} & Table.RootProps;

/**
 * Generic table that can sort and filter by each column
 * Filter options will be automatically generated based on the values present in a column
 * !Default sorting rules will only work for columns with a single value for each entry
 */
function SortableTable<T>(props: SortableTableProps<T>) {
  const {
    objects,
    customColumns: columns,
    onRowClick,
    cellReplacement,
    rowsSelectable,
    ...chakraTableProps
  } = props;

  const [sortBy, setSortBy] = useState<{
    key: keyof typeof columns;
    direction: SortDirection;
  }>();
  const [filterWindowOpen, setFilterWindowOpen] = useState<
    Map<keyof typeof columns, boolean>
  >(new Map());

  // These are the filter options that should be excluded
  const [selectedFilters, setSelectedFilters] = useState<
    Map<keyof typeof columns, string[]>
  >(new Map());

  useEffect(() => {
    // generate filter options
    Object.entries(columns).forEach(([_, col]) => {
      if (col.shouldFilter) {
        const filterOptions = new Set<string>();
        objects.forEach((item) => {
          const value = col.filterOptionRender?.(item) ?? col.cellValue(item);
          if (!(value === undefined)) {
            if (typeof value === 'string' || typeof value === 'number') {
              filterOptions.add(value.toString());
            } else {
              value.forEach((val) => filterOptions.add(val.toString()));
            }
          }
        });
        filterOptions.add('no value');
        col.filterOptions = filterOptions;
      }
    });
  }, [columns, objects]);

  function changeSortDirection(key: keyof typeof columns) {
    if (sortBy?.key === key) {
      setSortBy({ key, direction: (sortBy?.direction + 1) % 2 });
    } else {
      setSortBy({ key, direction: SortDirection.DOWN });
    }
  }

  function activateFilter(key: keyof typeof columns) {
    setFilterWindowOpen(
      new Map(
        filterWindowOpen.set(
          key.toString(),
          !filterWindowOpen.get(key.toString())
        )
      )
    );
  }

  function handleFilterChange(key: keyof typeof columns, filters: string[]) {
    setSelectedFilters(new Map(selectedFilters.set(key, filters)));
  }

  const [sortedItems, setSortedItems] = useState(objects);

  useEffect(() => {
    handleSortAndFilter();
  }, [objects]);

  function handleSortAndFilter() {
    let itemsToSortAndFilter = objects;
    itemsToSortAndFilter = handleFilter(itemsToSortAndFilter);
    handleSort(itemsToSortAndFilter);
  }

  // ---------------------------------------------- Sorting ----------------------------------------------
  function handleSort(itemsToSort: T[]) {
    if (sortBy?.key && itemsToSort.length > 0) {
      // Sort ascending
      if (sortBy.direction === SortDirection.UP) {
        if (columns[sortBy.key]?.sortingRuleASC) {
          setSortedItems(
            [...itemsToSort].sort(columns[sortBy.key]!.sortingRuleASC)
          );
        } else if (
          ['number', 'string', 'undefined'].includes(
            typeof columns[sortBy.key]?.cellValue(itemsToSort[0]!)
          )
        ) {
          setSortedItems(
            [...itemsToSort].sort((a, b) =>
              defaultSortingRuleASC(
                a,
                b,
                columns[sortBy.key]!.cellValue as sortableCellValue<T>
              )
            )
          );
        }
      }
      // Sort descending
      else {
        if (columns[sortBy.key]?.sortingRuleDESC) {
          setSortedItems(
            [...itemsToSort].sort(columns[sortBy.key]!.sortingRuleDESC)
          );
        } else if (
          ['number', 'string', 'undefined'].includes(
            typeof columns[sortBy.key]?.cellValue(itemsToSort[0]!)
          )
        ) {
          setSortedItems(
            [...itemsToSort].sort((a, b) =>
              defaultSortingRuleDESC(
                a,
                b,
                columns[sortBy.key]!.cellValue as sortableCellValue<T>
              )
            )
          );
        }
      }
    } else {
      setSortedItems(itemsToSort);
    }
  }

  // ---------------------------------------------- Filtering ----------------------------------------------
  function handleFilter(itemsToFilter: T[]) {
    // If no filters are selected, show all items
    if (selectedFilters.size === 0) {
      return objects;
    }

    const columnsToFilter = [...selectedFilters.keys()];
    const filteredRows = itemsToFilter.filter((item) => {
      // for each column that has a filter selected, apply all filters to items
      let includeItem = true;
      for (const column of columnsToFilter) {
        if (!includeItem) {
          continue;
        }

        const excludedValues = selectedFilters.get(column);
        if (excludedValues?.length === 0) {
          // after the filters has been reset
          continue;
        }

        const itemValue =
          columns[column]!.filterOptionRender?.(item) ??
          columns[column]!.cellValue(item);

        if (excludedValues) {
          if (
            (itemValue === undefined ||
              (typeof itemValue === 'object' && itemValue.length === 0)) &&
            !excludedValues.includes('no value')
          ) {
            includeItem = true;
          } else if (
            typeof itemValue === 'string' ||
            typeof itemValue === 'number'
          ) {
            // regular filtering, if the item does not have desired property, it should be filtered out
            includeItem = !excludedValues.includes(itemValue.toString());
          } else if (itemValue === undefined) {
            includeItem = false;
          } else {
            // This typecast is stupid but typescript is forcing my hand https://github.com/microsoft/TypeScript/issues/33591
            includeItem = !(itemValue as (string | number)[]).every((val) =>
              excludedValues.includes(val.toString())
            );
          }
        }
      }

      return includeItem;
    });

    return filteredRows;
  }

  useEffect(() => {
    handleSortAndFilter();
  }, [sortBy, selectedFilters]);

  return (
    <div className="border-maia-border flex w-full flex-col overflow-auto rounded-xl border border-solid">
      <Table.Root {...chakraTableProps}>
        <Table.Header className="ring-maia-border sticky top-0 z-[1] bg-white ring-1">
          <Table.Row>
            {rowsSelectable ? <Table.ColumnHeader className="w-10" /> : null}
            {Object.entries(columns)
              .filter((col) => !col[1].hidden)
              .map(([key, col]) => {
                const {
                  shouldSort = true,
                  shouldFilter = false,
                  filterOptions,
                  columnClassName,
                } = col;
                return (
                  <TableHeader
                    key={key}
                    onClickSort={() => shouldSort && changeSortDirection(key)}
                    onClickFilter={() => activateFilter(key)}
                    sortingDirection={
                      sortBy?.key === key ? sortBy?.direction : undefined
                    }
                    showChevrons={shouldSort}
                    showFilter={shouldFilter}
                    filterOpen={filterWindowOpen.get(key)}
                    filtersActive={
                      selectedFilters.get(key)
                        ? selectedFilters.get(key)!.length > 0
                        : false
                    }
                    filterOptions={filterOptions}
                    onFilterChange={(selectedFilters) =>
                      handleFilterChange(key, selectedFilters)
                    }
                    onFilterClose={() =>
                      setFilterWindowOpen(
                        new Map(filterWindowOpen.set(key, false))
                      )
                    }
                    className={columnClassName}
                  >
                    {col.title}
                  </TableHeader>
                );
              })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedItems.map((item, index) => {
            return (
              <Table.Row
                key={index}
                className={`text-maia-text-dark h-8 ${rowsSelectable && rowsSelectable.rowSelected(item) ? 'bg-maia-blue-100' : 'white'} `}
                onClick={() => onRowClick?.(item)}
              >
                {rowsSelectable ? (
                  <Table.Cell>
                    <Checkbox
                      checked={rowsSelectable.rowSelected(item)}
                      size={'md'}
                      colorPalette="maia-purple"
                      className="hover:bg-maia-purple-50 border-maia-border rounded px-4 text-xl"
                      onCheckedChange={({ checked }) =>
                        rowsSelectable.onSelectionChange(
                          item,
                          checked === 'indeterminate' ? false : checked
                        )
                      }
                    />
                  </Table.Cell>
                ) : null}
                {Object.entries(columns)
                  .filter((col) => !col[1].hidden)
                  .map(([key, col]) => {
                    const cellContent =
                      col.cellRender?.(item) || col.cellValue(item);

                    return cellReplacement ? (
                      cellReplacement(item, cellContent, key)
                    ) : (
                      <Table.Cell key={key}>{cellContent}</Table.Cell>
                    );
                  })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
    </div>
  );
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

function ifStringToLowerCase(val: string | number | boolean | undefined) {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}

type sortableCellValue<T> =
  | ((object: T) => string | undefined)
  | ((object: T) => number | undefined);

function defaultSortingRuleASC<T>(a: T, b: T, cellValue: sortableCellValue<T>) {
  const valA = ifStringToLowerCase(cellValue(a));
  const valB = ifStringToLowerCase(cellValue(b));
  return valA === valB
    ? 0
    : (!isNullOrUndefined(valA) && // Sort null values to the bottom
          valA! < valB!) ||
        isNullOrUndefined(valB)
      ? -1 // Sort a before b.
      : 1;
}

function defaultSortingRuleDESC<T>(
  a: T,
  b: T,
  cellValue: sortableCellValue<T>
) {
  const valA = ifStringToLowerCase(cellValue(a));
  const valB = ifStringToLowerCase(cellValue(b));
  return valA === valB
    ? 0
    : valA! < valB! || isNullOrUndefined(valA) // Sort null values to the bottom
      ? 1 // Sort b before a.
      : -1;
}

export default SortableTable;
