import { defineSlotRecipe } from '@chakra-ui/react';
import { tableAnatomy } from '@chakra-ui/react/anatomy';

export const tableSlotRecipe = defineSlotRecipe({
  slots: tableAnatomy.keys(),
  base: {
    columnHeader: {
      color: 'maia-text-dark',
      fontWeight: 'semibold',
    },
  },
});
