import { Separator, IconButton, Input } from '@chakra-ui/react';
import {
  faCopy,
  faQuestionCircle,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import AccessControl from '../../components/AccessControl';
import ContentHeader from '../../components/ContentHeader';
import DeleteModal from '../../components/DeleteModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenerateInviteLinkWarningModal from './GenerateInviteLinkWarningModal';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import { fetchApi } from '../../utils/useApi';
import i18n from '../../i18n';
import { supportedLanguages } from 'common-ts';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { toaster } from '../../components/ui/toaster';
import { Button } from '../../components/ui/button';
import { Field } from '../../components/ui/field';
import { Tooltip } from '../../components/ui/tooltip';

function GeneralSettings() {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [
    showGenerateInviteLinkWarningModal,
    setShowGenerateInviteLinkWarningModal,
  ] = useState(false);
  const [showDeleteInviteLinkModal, setShowDeleteInviteLinkModal] =
    useState(false);

  async function fetchWorkspaceInfos() {
    const res = await supabase
      .from('workspace')
      .select(
        'name, workspace_users_admin_view!workspace_user_workspace_id_fkey(user_type, first_name, last_name, email), workspace_admin_invite_code_view(invite_code)'
      )
      .eq('id', workspaceId);

    if (res.data?.[0]?.name) {
      setOldWorkspaceName(res.data[0].name);
    }
    const workspaceUsers = res.data?.[0]?.workspace_users_admin_view;
    const workspaceOwner = workspaceUsers?.find(
      (user) => user.user_type === 'OWNER'
    );
    if (workspaceOwner) {
      setWorkspaceOwnerName(
        workspaceOwner.first_name
          ? `${workspaceOwner.first_name} ${workspaceOwner.last_name ?? ''}`
          : workspaceOwner.email ?? ''
      );
      setWorkspaceOwnerMail(workspaceOwner.email ?? '');
    }
    const inviteCode =
      res.data?.[0]?.workspace_admin_invite_code_view?.invite_code;

    const inviteLink = inviteCode
      ? `${import.meta.env.VITE_MAIA_APP_PUBLIC_URL}/invite/${inviteCode}`
      : '';
    setInviteLink(inviteLink);
  }

  useEffect(() => {
    fetchWorkspaceInfos();
  }, []);

  async function handleWorkspaceNameChange(newName: string) {
    const res = await fetchApi(supabase, '/workspace', '/name', {
      method: 'PUT',
      name: newName,
      workspaceId,
    });

    if (!res.success) {
      toaster.create({ title: t('general.tryAgainError'), type: 'error' });
      return;
    }

    toaster.create({
      title: t('workspaceSettings.workspaceRenameSuccess'),
      type: 'success',
    });
    setOldWorkspaceName(newName);
  }

  async function handleGenerateInviteLink() {
    const res = await fetchApi(
      supabase,
      '/workspace',
      '/generate_invite_code',
      {
        method: 'POST',
        lang:
          supportedLanguages.find(
            (supportedLang) =>
              supportedLang.short === i18n.language.split('-')[0]
          )?.short ?? 'en',
        workspaceId,
      }
    );

    if (!res.success) {
      toaster.create({ title: t('general.tryAgainError'), type: 'error' });
      return;
    }
    const inviteCode = res.data.code;

    const inviteLink = inviteCode
      ? `${import.meta.env.VITE_MAIA_APP_PUBLIC_URL}/invite/${inviteCode}`
      : '';
    setInviteLink(inviteLink);
  }

  async function handleDisableInviteLink() {
    const res = await fetchApi(supabase, '/workspace', '/disable_invite_code', {
      method: 'POST',
      workspaceId,
    });

    if (!res.success) {
      toaster.create({ title: t('general.tryAgainError'), type: 'error' });
      return;
    }

    setInviteLink('');
  }

  const [workspaceOwnerName, setWorkspaceOwnerName] = useState('');
  const [workspaceOwnerMail, setWorkspaceOwnerMail] = useState('');
  const [oldWorkspaceName, setOldWorkspaceName] = useState('');
  const [newWorkspaceName, setNewWorkspaceName] = useState('');

  const [InviteLink, setInviteLink] = useState('');
  const [copyTooltipString, setCopyTooltipString] = useState(t('general.copy'));

  const handleCopy = () => {
    if (!InviteLink) return;

    navigator.clipboard.writeText(InviteLink);

    setCopyTooltipString(t('general.copied'));
    setTimeout(() => setCopyTooltipString(t('general.copy')), 1000);
  };

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('workspaceSettings.generalWorkspaceSettings')}
          icon={faUsers}
          buttons={[
            <Button
              className="font-semibold"
              disabled={
                !newWorkspaceName || newWorkspaceName === oldWorkspaceName
              }
              colorPalette="maia-accent"
              onClick={() => {
                handleWorkspaceNameChange(newWorkspaceName);
              }}
            >
              {t('general.saveButtons')}
            </Button>,
          ]}
          iconLabel={oldWorkspaceName}
        />
        <div>
          <Field label={t('workspaceSettings.workspaceNameTitle')}>
            <Input
              colorPalette={'maia-accent'}
              type="text"
              autoFocus
              size="sm"
              maxLength={128}
              placeholder={oldWorkspaceName}
              value={newWorkspaceName}
              onChange={(e) => {
                setNewWorkspaceName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  newWorkspaceName &&
                  newWorkspaceName !== oldWorkspaceName
                ) {
                  handleWorkspaceNameChange(newWorkspaceName);
                }
              }}
            />
          </Field>
        </div>
        <div>
          <Field label={t('workspaceSettings.workspaceOwnerTitle')}>
            <div>{`${workspaceOwnerName} (${workspaceOwnerMail})`}</div>
          </Field>
        </div>
        {/* ---------------------------------------------------------------- Invite link ---------------------------------------------------------------- */}
        <AccessControl allowedLicenses={['ENTERPRISE', 'PROFESSIONAL']}>
          <div>
            <div className="text-maia-text-dark flex items-center gap-2 font-medium">
              <span>{t('workspaceSettings.inviteLink')}</span>
              <Tooltip content={t('workspaceSettings.inviteLinkExplanation')}>
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faQuestionCircle}
                />
              </Tooltip>
            </div>
            <div className="flex items-center gap-2">
              <Input
                disabled
                type="text"
                size="sm"
                value={InviteLink}
                placeholder={t('workspaceSettings.inviteLink')}
                className="cursor-text"
              />
              <Tooltip
                content={copyTooltipString}
                disabled={!InviteLink}
                closeDelay={500}
                positioning={{ placement: 'top' }}
              >
                <IconButton
                  variant={'outline'}
                  aria-label={t('general.copy')}
                  disabled={!InviteLink}
                  onClick={handleCopy}
                  fontSize={'xs'}
                >
                  <FontAwesomeIcon icon={faCopy} className="fill-current" />
                </IconButton>
              </Tooltip>
              <Button
                className="flex-shrink-0"
                colorPalette="maia-accent"
                onClick={() => {
                  if (InviteLink) {
                    setShowGenerateInviteLinkWarningModal(true);
                  } else {
                    handleGenerateInviteLink();
                  }
                }}
              >
                {t('workspaceSettings.generateInviteLinkButton')}
              </Button>
              <Button
                className="flex-shrink-0"
                colorPalette="red"
                onClick={() => {
                  setShowDeleteInviteLinkModal(true);
                }}
              >
                {t('workspaceSettings.deactivateInviteLinkButton')}
              </Button>
            </div>
          </div>
        </AccessControl>
        <Separator />
        <div className="text-xs">
          {t('workspaceSettings.workspaceSupportInfo')}{' '}
          <a className="underline" href="mailto:info@prodlane.io">
            info@prodlane.io
          </a>
        </div>
      </MaiaFullGridWidthContent>
      <GenerateInviteLinkWarningModal
        isOpen={showGenerateInviteLinkWarningModal}
        onClose={() => {
          setShowGenerateInviteLinkWarningModal(false);
        }}
        onConfirm={async () => {
          await handleGenerateInviteLink();
          setShowGenerateInviteLinkWarningModal(false);
        }}
      />
      <DeleteModal
        isOpen={showDeleteInviteLinkModal}
        onClose={() => {
          setShowDeleteInviteLinkModal(false);
        }}
        onConfirm={() => {
          handleDisableInviteLink();
          setShowDeleteInviteLinkModal(false);
        }}
        title={t('workspaceSettings.deactivateLinkWarningTitle')}
        subtitle={t('workspaceSettings.deactivateLinkWarning')}
      />
    </MaiaMainGrid>
  );
}

export default GeneralSettings;
