import { Badge, Box, IconButton, Table } from '@chakra-ui/react';

import AccessControl from '../../components/AccessControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SharepointSiteStatus } from 'common-ts';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from '../../components/ui/menu';

type SharepointSiteStatusTableProps = {
  sites: SharepointSiteStatus[];
  onDisconnectClick: (sharepointSiteId: string) => void;
};

function SharepointSiteStatusTable({
  sites,
  onDisconnectClick,
}: SharepointSiteStatusTableProps) {
  const { t } = useTranslation();

  const statusTranslationMap: {
    [key in SharepointSiteStatus['status']]: string;
  } = {
    PENDING: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.pending'
    ),
    'IN PROGRESS': t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.inProgress'
    ),
    SYNCED: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.synced'
    ),
    FAILED: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.failed'
    ),
  };

  return (
    <Box className="border-maia-border flex-shrink-0 rounded-xl border border-solid bg-white pb-1.5">
      <Table.Root variant="line">
        <Table.Header>
          <Table.Row className="font-semibold">
            <Table.ColumnHeader borderRadius={'12px'}>
              {t('integrationSettings.sharepoint.connectedSitesTable.site')}
            </Table.ColumnHeader>
            <Table.ColumnHeader>
              {t(
                'integrationSettings.sharepoint.connectedSitesTable.syncedFiles'
              )}
            </Table.ColumnHeader>
            <Table.ColumnHeader>
              {t(
                'integrationSettings.sharepoint.connectedSitesTable.totalFiles'
              )}
            </Table.ColumnHeader>
            <Table.ColumnHeader>
              {t(
                'integrationSettings.sharepoint.connectedSitesTable.usedStorage'
              )}
            </Table.ColumnHeader>
            <Table.ColumnHeader>
              {t('integrationSettings.sharepoint.connectedSitesTable.status')}
            </Table.ColumnHeader>
            <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
              <Table.ColumnHeader borderRadius={'12px'} />
            </AccessControl>
          </Table.Row>
        </Table.Header>
        <Table.Body className="text-maia-text-dark">
          {sites.map((site) => {
            return (
              <Table.Row key={site.id}>
                <Table.Cell borderRadius={'12px'}>{site.name}</Table.Cell>
                <Table.Cell>
                  {site.status === 'PENDING' ? '?' : site.syncedFiles}
                </Table.Cell>
                <Table.Cell>
                  {site.status === 'PENDING' ? '?' : site.totalFiles}
                </Table.Cell>
                <Table.Cell>{site.usedStorage.toFixed(2)}MB</Table.Cell>
                <Table.Cell>
                  <Badge
                    size={'sm'}
                    className={`font-bold ${
                      site.status === 'IN PROGRESS'
                        ? 'bg-maia-support-yellow'
                        : site.status === 'FAILED'
                          ? 'bg-maia-support-red'
                          : site.status === 'PENDING'
                            ? 'bg-chakra-gray-400'
                            : 'bg-maia-support-green'
                    } text-xs text-white`}
                  >
                    {statusTranslationMap[site.status]}
                  </Badge>
                </Table.Cell>

                <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
                  <Table.Cell>
                    <MenuRoot>
                      <MenuTrigger>
                        <IconButton
                          size={'sm'}
                          variant={'ghost'}
                          aria-label="Sharepoint site settings"
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </IconButton>
                      </MenuTrigger>
                      <MenuContent className="min-w-[130px]">
                        <MenuItem
                          value="disconnect"
                          onClick={() => {
                            onDisconnectClick(site.siteId);
                          }}
                        >
                          {t(
                            'integrationSettings.sharepoint.connectedSitesTable.disconnect'
                          )}
                        </MenuItem>
                      </MenuContent>
                    </MenuRoot>
                  </Table.Cell>
                </AccessControl>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
    </Box>
  );
}

export default SharepointSiteStatusTable;
