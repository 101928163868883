import { CreateToasterReturn } from '@chakra-ui/react';

import { Database } from 'common-ts';
import { SupabaseClient } from '@supabase/supabase-js';
import { TFunction } from 'i18next';
import { fetchApi } from './useApi';

export async function addUsersToWorkspace(
  emails: string[],
  role: Database['public']['Enums']['workspace_user_type'],
  supabase: SupabaseClient,
  workspaceId: string,
  toaster: CreateToasterReturn,
  tFunction: TFunction
) {
  const res = await fetchApi(supabase, '/workspace', '/add_users', {
    method: 'POST',
    emails,
    role,
    workspaceId,
  });

  if (!res.success) {
    toaster.create({
      title:
        res.status === 540
          ? tFunction('workspaceSettings.userNotExistsError')
          : res.status === 406
            ? tFunction('workspaceSettings.addUserModal.licenseLimitWarning')
            : tFunction('general.tryAgainError'),
      type: 'error',
    });
    return;
  }

  if (res.data.failedEmails.length) {
    toaster.create({
      title: (
        <div>
          {tFunction('workspaceSettings.someUsersNotExistWarning')} <br />{' '}
          {res.data.failedEmails.map((email) => {
            return (
              <>
                {email}
                <br />
              </>
            );
          })}
        </div>
      ),
      type: 'warning',
    });
  }
}
