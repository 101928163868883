import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogRoot,
  DialogTitle,
} from './ui/dialog';

type CorrectAnswerModalProps = {
  title: string;
  subtitle: string;
  answer: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (answer: string) => void;
};

function CorrectAnswerModal({
  title,
  subtitle,
  answer,
  isOpen,
  onClose,
  onConfirm,
}: CorrectAnswerModalProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);
  const value = useRef<string>(answer);
  useEffect(() => {
    value.current = answer;
  }, [answer]);
  return (
    <DialogRoot
      open={isOpen}
      finalFocusEl={() => cancelRef.current}
      onOpenChange={({ open }) => !open && onClose()}
    >
      <DialogBackdrop />
      <DialogContent className="max-w-3xl">
        <DialogHeader fontSize="lg" fontWeight="bold">
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          {subtitle}
          <Textarea
            defaultValue={answer}
            onChange={(e) => (value.current = e.currentTarget.value)}
          />
        </DialogBody>
        <DialogFooter>
          <Button ref={cancelRef} onClick={onClose} variant="outline">
            {t('general.cancelButton')}
          </Button>
          <Button
            colorPalette="green"
            onClick={() => onConfirm(value.current)}
            ml={3}
          >
            {t('general.addTest')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default CorrectAnswerModal;
