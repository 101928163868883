const INLINE_REFERENCE_REGEX = /MAIA_INLINE_REFERENCE_(\d+)/g;

/**
 * Preprocesses the given answer string by replacing inline reference placeholders
 * with HTML span elements for inline references.
 *
 * The function looks for patterns in the form of `MAIA_INLINE_REFERENCE_<number>`
 * and replaces them with a span element containing the reference number.
 *
 * @param answer - The answer string to preprocess.
 * @returns The preprocessed answer string with inline references replaced by HTML span elements.
 */
export const preprocessAnswer = (answer: string): string => {
  return answer.replace(
    INLINE_REFERENCE_REGEX,
    (_, id) =>
      `<span class="inline-reference" aria-label="${id}">[${id}]</span>`
  );
};
