export const colorSemanticTokens = {
  'maia-blue': {
    solid: { value: '{colors.maia-blue.500}' },
    contrast: { value: '{colors.white}' },
    fg: { value: '{colors.maia-blue.700}' },
    muted: { value: '{colors.white}' },
    subtle: { value: '{colors.maia-blue.200}' },
    emphasized: { value: '{colors.maia-blue.300}' },
    focusRing: { value: '{colors.maia-blue.500}' },
  },
  'maia-purple': {
    solid: { value: '{colors.maia-purple.500}' },
    contrast: { value: '{colors.white}' },
    fg: { value: '{colors.maia-purple.700}' },
    muted: { value: '{colors.white}' },
    subtle: { value: '{colors.maia-purple.200}' },
    emphasized: { value: '{colors.maia-purple.300}' },
    focusRing: { value: '{colors.maia-purple.500}' },
  },
  'maia-gray': {
    solid: { value: '{colors.maia-gray.500}' },
    contrast: { value: '{colors.white}' },
    fg: { value: '{colors.maia-gray.700}' },
    muted: { value: '{colors.white}' },
    subtle: { value: '{colors.maia-gray.200}' },
    emphasized: { value: '{colors.maia-gray.300}' },
    focusRing: { value: '{colors.maia-gray.500}' },
  },
  'maia-accent': {
    solid: { value: '{colors.maia-accent.500}' },
    contrast: { value: '{colors.white}' },
    fg: { value: '{colors.maia-accent.700}' },
    muted: { value: '{colors.white}' },
    subtle: { value: '{colors.maia-accent.200}' },
    emphasized: { value: '{colors.maia-accent.300}' },
    focusRing: { value: '{colors.maia-accent.500}' },
  },
};
