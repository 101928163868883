import {
  LayoutItemType,
  MergedLayoutItemsWithAnnotations,
  SnippetResultV1,
  escapeMarkdownSpecialChars,
  highlightWithMarkdown,
} from 'common-ts';

/**
 * Use SnippetResult's layoutBlocks and annotation to generate a markdown formatted representation of that SnippetResult.
 * @param result {SnippetResult} - One snippet result returned from the search with annotations for parts to be highlighted and layoutBlocks for information about formatting.
 * @returns Markdown styled representation of the result.
 * **To be displayed properly needs**:
 * - gfm plugin (for tables)
 * - rehype plugin (for highlighting)
 */
export function resultAndLayoutBlocksToMarkdown(
  result: SnippetResultV1 | MergedLayoutItemsWithAnnotations
) {
  if ('documentId' in result) {
    // always get whole layout item so can make a markdown block out of them afterwards
    const highlightAbsoluteStarts = result.annotations.map(
      (annotation) => annotation.offsetStart
    );

    const highlightAbsoluteEnds = result.annotations.map(
      (annotation) => annotation.offsetEnd
    );

    if (result.type === LayoutItemType.Table) {
      return result.content;
    }
    return highlightWithMarkdown(
      addHighlightStartsAndEnds(
        0,
        result.content,
        highlightAbsoluteStarts,
        highlightAbsoluteEnds
      ),
      result.type
    );
  } else {
    const highlightAbsoluteStarts = result.annotations.map(
      (annotation) => annotation.offset
    );

    const highlightAbsoluteEnds = result.annotations.map(
      (annotation) => annotation.offset + annotation.length
    );
    return addHighlightStartsAndEnds(
      result.offset,
      result.snippet,
      highlightAbsoluteStarts,
      highlightAbsoluteEnds
    );
  }
}

/**
 * Add highlights to snippets using rehype markdown syntax (quasi html in markdown) and escape markdown characters.
 * @param absoluteOffset Absolute offset of the snippet within the file.
 * @param snippet The snippet that the highlighting should be applied to.
 * @param ascSortedAbsoluteHighlightStarts Absolute offsets where highlights should start. !Must be sorted in ascending order!
 * @param ascSortedAbsoluteHighlightEnds Absolute offsets where highlights should end. !Must be sorted in ascending order!
 * @returns Snippet with highlights applied to it. The amount of highlight starts and ends within the result snippet should be the same.
 */
function addHighlightStartsAndEnds(
  absoluteOffset: number,
  snippet: string,
  ascSortedAbsoluteHighlightStarts: number[],
  ascSortedAbsoluteHighlightEnds: number[]
) {
  if (snippet.length === 0) {
    return snippet;
  }

  const highlightStartString = `<span style="background-color:#CCC7EE80">`;
  const highlightEndString = `</span>`;

  // In addition to adding highlights, we also need to escape the snippet.
  // We can't escape the whole snippet before highlighting, because escaping adds extra characters -> the offsets would be wrong.
  // Can't escape after highlighting because that would also escape the highlighting strings
  // -> Sort highlight strings to be inserted ascending. This way we can:
  // Escape the part before highlightString that has not yet been escaped
  // Account for offset added by highlightString and escape characters
  const sortedHighlightStartsAndEnds = [
    ...ascSortedAbsoluteHighlightStarts.map((start) => {
      return { type: 'start', offset: start };
    }),
    ...ascSortedAbsoluteHighlightEnds.map((end) => {
      return { type: 'end', offset: end };
    }),
  ].sort((a, b) => a.offset - b.offset);

  let addedOffset = 0;
  let alreadyEscapedAndHighlightedPart = '';

  for (const startOrEnd of sortedHighlightStartsAndEnds) {
    const relativeOffset = Math.max(0, startOrEnd.offset - absoluteOffset);
    const prefix = snippet.slice(
      alreadyEscapedAndHighlightedPart.length - addedOffset,
      relativeOffset
    );

    const escapedPrefix = escapeMarkdownSpecialChars(prefix);

    const highlightStyleString =
      startOrEnd.type === 'start' ? highlightStartString : highlightEndString;

    alreadyEscapedAndHighlightedPart = `${alreadyEscapedAndHighlightedPart}${escapedPrefix}${highlightStyleString}`;

    addedOffset +=
      escapedPrefix.length - prefix.length + highlightStyleString.length;
  }

  const suffix = snippet.slice(
    alreadyEscapedAndHighlightedPart.length - addedOffset
  );

  return `${alreadyEscapedAndHighlightedPart}${escapeMarkdownSpecialChars(
    suffix
  )}`;
}
