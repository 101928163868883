import React, { useRef, useState } from 'react';
import {
  insertExamplePromptUsage,
  insertPromptUsage,
} from './promptLibraryRequests';

import { Prompt } from './promptLibraryTypes';
import { PromptLibraryModalContent } from './PromptLibraryModalContent';
import { captureException } from '@sentry/react';
import { useBoundStore } from '../../../store/useBoundStore';
import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogRoot,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

type PromptModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUsePromptClicked: (value: string | undefined) => void;
};

function PromptModal({
  isOpen,
  onClose,
  onUsePromptClicked,
}: PromptModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | undefined>();
  const [isSelectedPromptExample, setIsSelectedPromptExample] =
    useState<boolean>(false);
  const userInfo = useBoundStore((state) => state.userInfo);
  const portalRef = useRef<HTMLDivElement>(null);

  /**
   * Handles the use prompt button click.
   * Inserts a prompt usage record in the database. Based on the prompt type, it will insert a different record.
   * Calls the onUsePromptClicked callback with the prompt value, so it can be inserted in the chat input.
   */
  const handleUsePrompt = async () => {
    if (!selectedPrompt || userInfo?.userId === undefined) {
      return;
    }

    if (isSelectedPromptExample) {
      const { error } = await insertExamplePromptUsage(
        selectedPrompt?.id,
        userInfo?.userId,
        supabase
      );

      if (error) {
        // Failed to insert the prompt usage should be logged but not block the user from using the prompt.
        captureException(error);
      }
    } else {
      const { error } = await insertPromptUsage(
        selectedPrompt?.id,
        userInfo?.userId,
        supabase
      );

      if (error) {
        // Failed to insert the prompt usage should be logged but not block the user from using the prompt.
        captureException(error);
      }
    }

    onUsePromptClicked(selectedPrompt?.value);
  };

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && onClose()}
      closeOnInteractOutside={false}
      closeOnEscape={false}
      size={'xl'}
    >
      <DialogBackdrop />
      <DialogContent
        className="h-2/3 max-h-[800px] min-h-[600px]"
        ref={portalRef}
      >
        <DialogBody className="h-full min-h-0 px-1 py-2">
          <PromptLibraryModalContent
            onSelectedPromptChange={(prompt, isExample) => {
              setSelectedPrompt(prompt);
              setIsSelectedPromptExample(isExample);
            }}
            portalRef={portalRef}
          />
        </DialogBody>
        <DialogFooter gap={3}>
          <Button
            variant="outline"
            onClick={onClose}
            className="text-maia-text-dark text-sm font-medium"
          >
            {t('general.closeButtons')}
          </Button>
          <Button
            colorPalette="blue"
            onClick={handleUsePrompt}
            className="text-maia-text-light bg-maia-accent text-sm font-semibold"
          >
            {t('promptLibrary.usePrompt')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default PromptModal;
