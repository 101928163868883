import SelectableCollectionFileList, {
  FileWithStatus,
} from './SelectableCollectionFileList.js';
import { faAngleRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from 'react';

import { ExtendedBucket } from '../../../@types/extendedTypes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@chakra-ui/react';
import ListItem from '../../../components/ListItem.js';
import { captureException } from '@sentry/react';
import { decodeName } from 'common-ts';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { toaster } from '../../../components/ui/toaster.js';

type CollectionNavigatorProps = {
  searchTerm: string;
  collections: ExtendedBucket[];
  isPowerModeEnabled: boolean;
  collectionSelectedFileInfoIdMap: Map<string, Map<string, string>> | undefined;
  onFileSelect: (
    collectionId: string,
    files: { name: string; fileInfoId: string }[]
  ) => void;
  onFileUnselect: (
    collectionId: string,
    files: { name: string; fileInfoId: string }[]
  ) => void;
  onBackClick: () => void;
};

function CollectionNavigator({
  searchTerm,
  collections,
  collectionSelectedFileInfoIdMap,
  onFileSelect,
  onFileUnselect,
  onBackClick,
}: CollectionNavigatorProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);

  const [selectedCollection, setSelectedCollection] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [collectionFilesMap, setCollectionFilesMap] = useState<
    Map<string, FileWithStatus[]>
  >(new Map());

  async function fetchCollectionFiles() {
    for (const collection of collections) {
      const { data: fileInfoData, error: fileInfoError } = await supabase
        .from('storage_file_view')
        .select('embedding_status, file_info_id, name')
        .eq('bucket_id', collection.id);
      if (fileInfoData) {
        const filesWithStatus: FileWithStatus[] = [];

        fileInfoData.forEach((fileInfo) => {
          filesWithStatus.push({
            fileInfoId: fileInfo.file_info_id!, // This is not actually nullable, but the supabase typegen thinks it is, because of how postgres views work (https://dba.stackexchange.com/questions/285302/why-columns-in-views-are-always-nullable)
            name: fileInfo.name ?? '',
            embeddingStatus: fileInfo?.embedding_status ?? 'FAILED',
          });
        });

        setCollectionFilesMap((prev) => {
          prev.set(collection.id, filesWithStatus);
          return new Map(prev);
        });
      } else if (fileInfoError) {
        captureException(fileInfoError);
        toaster.create({
          title: t('chat.fileSelector.loadingFileError'),
          type: 'error',
        });
      }
    }
  }

  useEffect(() => {
    fetchCollectionFiles();
  }, [collections]);

  const filteredCollections = useMemo(() => {
    return collections.filter(
      (collection) =>
        decodeName(collection.display_name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        collectionFilesMap
          ?.get(collection.id)
          ?.find((file) =>
            file.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
    );
  }, [collections, searchTerm, collectionFilesMap]);

  const collectionElements = useMemo(() => {
    return filteredCollections.map((collection) => {
      const decodedName = decodeName(collection.display_name);
      const searchTermIndex = decodedName
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase());
      const prefix = decodedName.slice(0, searchTermIndex);
      const boldPart = decodedName.slice(
        searchTermIndex,
        searchTermIndex + searchTerm.length
      );
      const suffix = decodedName.slice(searchTermIndex + searchTerm.length);

      return (
        <ListItem
          variant="left"
          key={collection.id}
          className={`flex-shrink-0`}
          selected={selectedCollection?.id === collection.id}
          domElementProps={{
            onClick: () => {
              setSelectedCollection({
                id: collection.id,
                name: collection.display_name,
              });
            },
          }}
        >
          <div
            className={`flex w-full items-center justify-between gap-2 truncate`}
          >
            <div className="flex min-w-0 items-center gap-2">
              <FontAwesomeIcon
                icon={faFolder}
                className="text-chakra-gray-400 text-base"
              />
              {searchTermIndex > -1 ? (
                <div className="truncate">
                  <span>{prefix}</span>
                  <span className="font-semibold">{boldPart}</span>
                  <span>{suffix}</span>
                </div>
              ) : (
                <div>{decodedName}</div>
              )}
            </div>
            <div className="text-chakra-gray-500 flex items-center gap-1">
              <div>{collectionFilesMap?.get(collection.id)?.length}</div>
              <FontAwesomeIcon icon={faAngleRight} className="text-xs" />
            </div>
          </div>
        </ListItem>
      );
    });
  }, [filteredCollections, searchTerm, collectionFilesMap, selectedCollection]);

  return (
    <div className="border-maia-border flex h-[515px] w-full flex-col border-b border-t">
      <div className="flex min-h-0 flex-grow">
        {/* ------------------------------------------------ Collections ------------------------------------------------ */}
        <div
          className={`border-maia-border flex min-w-72 flex-grow flex-col overflow-y-auto px-4 py-2 md:flex-grow-0 md:border-r ${selectedCollection ? 'hidden md:flex' : ''}`}
        >
          <IconButton
            className="self-start md:hidden"
            aria-label="back"
            variant={'ghost'}
            onClick={onBackClick}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
          {collectionElements}
        </div>
        {/* ------------------------------------------------ Files ------------------------------------------------ */}
        <div
          className={`min-w-0 flex-grow ${!selectedCollection ? 'hidden md:block' : ''}`}
        >
          {selectedCollection?.id && (
            <SelectableCollectionFileList
              key={selectedCollection?.id}
              className="w-full"
              files={collectionFilesMap?.get(selectedCollection?.id)}
              searchTerm={searchTerm}
              selectedFiles={collectionSelectedFileInfoIdMap?.get(
                selectedCollection?.id
              )}
              onFileSelect={(files) =>
                onFileSelect(selectedCollection?.id, files)
              }
              onFileUnselect={(files) =>
                onFileUnselect(selectedCollection?.id, files)
              }
              onRefreshFilesClick={() => {
                fetchCollectionFiles();
              }}
              onBackClick={() => {
                setSelectedCollection(undefined);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CollectionNavigator;
