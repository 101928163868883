import { DialogBackdrop } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/ui/button';
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogRoot,
} from '../../../components/ui/dialog';

export type FileSelectorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fileSelector: JSX.Element;
};

export const FileSelectorModal = ({
  isOpen,
  onClose,
  fileSelector,
}: FileSelectorModalProps) => {
  const { t } = useTranslation();

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && onClose()}
      size="xl"
    >
      <DialogBackdrop />
      <DialogContent maxWidth="1236px" className="h-full max-md:m-0 md:h-auto">
        <DialogBody padding={0}>{fileSelector}</DialogBody>
        <DialogFooter gap={3}>
          <Button className="font-medium" variant="outline" onClick={onClose}>
            {t('general.doneButtons')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
