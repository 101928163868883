export type MSAuthFlowState = {
  workspaceId: string;
  redirectTo: string;
  action: 'activate' | 'userAuth';
};

export function getMsAuthFlowLink({
  redirectTo,
  workspaceId,
  action,
}: {
  redirectTo: string;
  workspaceId: string;
  action: 'activate' | 'userAuth';
}) {
  const url = new URL(
    'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize'
  );

  url.searchParams.append(
    'client_id',
    import.meta.env.VITE_MAIA_MS_GRAPH_CLIENT_ID
  );
  url.searchParams.append('response_type', 'code');
  url.searchParams.append(
    'redirect_uri',
    `${import.meta.env.VITE_MAIA_APP_PUBLIC_URL}${import.meta.env.VITE_MAIA_MS_GRAPH_AUTHORIZE_REDIRECT_ROUTE}`
  );
  url.searchParams.append('response_mode', 'query');
  url.searchParams.append(
    'scope',
    import.meta.env.VITE_MAIA_MS_GRAPH_USER_AUTH_SCOPE
  );

  const stateSearchParam: MSAuthFlowState = { action, redirectTo, workspaceId };

  url.searchParams.append('state', JSON.stringify(stateSearchParam));

  return url;
}
