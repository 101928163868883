import { Database, SupportedFileType, supportedFileTypes } from 'common-ts';

export type FolderBreadCrumbs = { id: string; name: string }[];

/**
 * Constructs a string from the path given by the `breadCrumbs` in the form _<first_file.id>_<second_file.id>_<third_file.id> ...
 *
 * @param breadCrumbs The breadcrumbs to construct the path string from.
 * @returns - folderPath: The string path to the current folder as given by the `breadCrumbs`
 * - parentPath: The string path of the current folders parent ('' if folder has no parent)
 */
export function stringPathFromBreadCrumbs(breadCrumbs: FolderBreadCrumbs) {
  let parentPath = '';
  const folderPath = breadCrumbs.reduce((path, folder, index) => {
    // Last iteration, take path of previous iteration as parentPath
    if (index === breadCrumbs.length - 1) {
      parentPath = path;
    }

    return `${path}_${folder.id}`;
  }, '');

  return { parentPath, folderPath };
}

/**
 * Checks whether a file is selectable based on its file type and embedding status.
 * @param file The file to check
 * @returns is the file selectable?
 */
export function isFileSelectable(
  file: Database['public']['Views']['sharepoint_file_view']['Row'],
  csv: boolean | undefined
) {
  return (
    supportedFileTypes.includes(file.mime_type as SupportedFileType) &&
    (file.mime_type !== 'text/csv' || csv) &&
    file.embedding_status === 'FINISHED'
  );
}
