import { CreatableSelect, MultiValue } from 'chakra-react-select';

import { Database } from 'common-ts';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toaster } from '../../components/ui/toaster';
import {
  DialogRoot,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogTitle,
} from '../../components/ui/dialog';
import { Field } from '../../components/ui/field';
import {
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SelectContent,
} from '../../components/ui/select';
import { createListCollection } from '@chakra-ui/react';
import { Button } from '../../components/ui/button';

type AddUserModalProps = {
  isOpen: boolean;
  alreadyPartOfWorkspaceUserMails: string[];
  onClose: () => void;
  onInvite: (
    emails: MultiValue<{ label: string; value: string }>,
    role: Database['public']['Enums']['workspace_user_type']
  ) => void;
};

function AddUserModal({
  isOpen,
  alreadyPartOfWorkspaceUserMails,
  onClose,
  onInvite,
}: AddUserModalProps) {
  const { t } = useTranslation();

  const [emails, setEmails] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);
  const [role, setRole] =
    useState<Database['public']['Enums']['workspace_user_type']>('USER');

  function handleClose() {
    setEmails([]);
    setRole('USER');
    onClose();
  }

  const roles = createListCollection<{
    label: string;
    value: Database['public']['Enums']['workspace_user_type'];
  }>({
    items: [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'User', value: 'USER' },
    ],
  });

  const portalRef = useRef<HTMLDivElement>(null);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && handleClose()}
    >
      <DialogBackdrop />
      <DialogContent ref={portalRef}>
        <DialogHeader>
          <DialogTitle>{t('workspaceSettings.addUserModal.title')}</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody
          className="flex flex-col gap-4"
          colorPalette={'maia-accent'}
        >
          <CreatableSelect<{ label: string; value: string }, true>
            size="sm"
            selectedOptionStyle="check"
            tagColorPalette="gray"
            noOptionsMessage={() => ''}
            formatCreateLabel={(currentVal) => {
              return `${t(
                'workspaceSettings.addUserModal.createOptionLabel'
              )} '${currentVal}'`;
            }}
            components={{ DropdownIndicator: null }}
            onChange={(val, actionMeta) => {
              if (
                actionMeta.option &&
                alreadyPartOfWorkspaceUserMails.includes(
                  actionMeta.option?.value
                )
              ) {
                toaster.create({
                  title: t(
                    'workspaceSettings.addUserModal.alreadyMemberWarning'
                  ),
                  type: 'warning',
                });
                return;
              }
              setEmails(val);
            }}
            value={emails}
            isMulti
          />
          <Field>
            <SelectRoot
              collection={roles}
              size={'sm'}
              value={[role]}
              onValueChange={({ value }) => {
                setRole(
                  value[0] as Database['public']['Enums']['workspace_user_type']
                );
              }}
            >
              <SelectTrigger>
                <SelectValueText />
              </SelectTrigger>
              <SelectContent portalRef={portalRef}>
                {roles.items.map((role) => (
                  <SelectItem key={role.value} item={role}>
                    {role.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </Field>
        </DialogBody>
        <DialogFooter>
          <div className="flex items-center gap-2">
            <Button variant="outline" onClick={handleClose}>
              {t('general.cancelButton')}
            </Button>
            <Button
              colorPalette="maia-accent"
              onClick={() => {
                onInvite(emails, role);
                handleClose();
              }}
              disabled={emails.length < 1}
            >
              {t('workspaceSettings.addUserModal.inviteButton')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default AddUserModal;
