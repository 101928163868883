export const colorTokens = {
  'maia-blue': {
    50: { value: '#FAFCFE' },
    100: { value: '#F3FAFE' },
    200: { value: '#EEEBFD' },
    300: { value: '#DEDAF6' },
    400: { value: '#CCC7EE' },
    500: { value: '#8D89BC' },
    600: { value: '#69649D' },
    700: { value: '#5A5781' },
    800: { value: '#343257' },
    900: { value: '#272644' },
    1000: { value: '#171635' },
  },
  'maia-purple': {
    100: { value: '#FAEAFD' },
    200: { value: '#F4D7FB' },
    300: { value: '#E7B0F4' },
    400: { value: '#D77EED' },
    500: { value: '#CB4EEA' },
    600: { value: '#A420C5' },
    700: { value: '#80199A' },
    800: { value: '#5B126E' },
    900: { value: '#370B42' },
  },
  'maia-gray': {
    100: { value: '#FAF6FB' },
    200: { value: '#F2E9F4' },
    300: { value: '#E1D4E4' },
    400: { value: '#B9A6BE' },
    500: { value: '#9C81A3' },
    600: { value: '#7F6386' },
    700: { value: '#6B4E73' },
    800: { value: '#523758' },
    900: { value: '#3D2643' },
    1000: { value: '#251529' },
  },
  'maia-accent': {
    50: { value: '#f4e8ff' },
    100: { value: '#ebd5ff' },
    200: { value: '#dbb5fd' },
    300: { value: '#c485fb' },
    400: { value: '#ae57f5' },
    500: { value: '#BE3ADF' },
    600: { value: '#8424cc' },
    700: { value: '#7022a7' },
    800: { value: '#5c1d86' },
    900: { value: '#3e0863' },
  },
  'maia-text-dark': { value: '#3D2643' },
  'maia-border': { value: '#E2E8F0' },
};
