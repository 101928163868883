import { useEffect, useRef, useState } from 'react';
import {
  DialogTitle,
  HStack,
  IconButton,
  Image,
  Separator,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../../../store/useBoundStore.js';
import { toaster } from '../../../../components/ui/toaster.js';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '../../../../components/ui/dialog.js';
import { Skeleton } from '../../../../components/ui/skeleton.js';
import { Tooltip } from '../../../../components/ui/tooltip.js';
import { Button } from '../../../../components/ui/button.js';
import { PinInput } from '@/components/ui/pin-input';

type TotpModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type Totp = {
  qr_code: string;
  secret: string;
  uri: string;
};

export default function TotpModal({ isOpen, onClose }: TotpModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);

  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const [factorId, setFactorId] = useState<string>('');
  const [totp, setTotp] = useState<Totp>();
  const [code, setCode] = useState<string>('');
  const [copyTooltipString, setCopyTooltipString] = useState(t('general.copy'));

  const handleCopy = () => {
    if (!totp?.secret) return;

    navigator.clipboard.writeText(totp.secret);

    setCopyTooltipString(t('general.copied'));
    setTimeout(() => setCopyTooltipString(t('general.copy')), 1000);
  };
  const setup2FA = async () => {
    const { data, error } = await supabase.auth.mfa.enroll({
      factorType: 'totp',
      friendlyName: 'MAIA',
    });

    if (error) {
      console.error(error.message);
      return;
    }

    // Use the id to create a challenge.
    // The challenge can be verified by entering the code generated from the authenticator app.
    // The code will be generated upon scanning the qr_code or entering the secret into the authenticator app.
    const { id, totp } = data;

    setFactorId(id);
    setTotp(totp);
  };
  const cancel2FASetup = async () => {
    if (!factorId) return;
    const { error } = await supabase.auth.mfa.unenroll({
      factorId,
    });
    if (error) {
      console.error(error.message);
      return;
    }
  };
  const handleModalClose = async () => {
    await cancel2FASetup();
    onClose();
  };
  const handle2FASetupConfirmation = async () => {
    const { data, error } = await supabase.auth.mfa.challengeAndVerify({
      factorId,
      code,
    });
    if (error || !data) {
      if (error.status === 400) {
        toaster.create({
          title: t('userSettings.security.invalidCodeError'),
          type: 'error',
        });
      } else {
        captureException(error);
        toaster.create({
          title: t('userSettings.security.totpModal.error'),
          type: 'error',
        });
        handleModalClose();
      }
      return;
    } else {
      toaster.create({
        title: t('userSettings.security.totpModal.success'),
        type: 'success',
      });
      onClose();
    }
  };

  useEffect(() => {
    setup2FA();
  }, []);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && handleModalClose()}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('userSettings.security.totpModal.title')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody className="flex w-full flex-col items-center gap-y-4">
          <div className="mb-4">
            {t('userSettings.security.totpModal.subtitle')}
          </div>
          <Skeleton loading={!totp?.qr_code}>
            <Image boxSize="100px" src={totp?.qr_code} alt="qr code" />
          </Skeleton>

          <div className="flex w-full flex-col items-center gap-y-2">
            <span className="text-xs font-medium">
              {t(
                'userSettings.security.totpModal.enterAuthenticationCodePrompt'
              )}
            </span>
            <HStack colorPalette={'maia-accent'}>
              <PinInput
                type="numeric"
                variant={'flushed'}
                onValueChange={(e) => setCode(e.value.join(''))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handle2FASetupConfirmation();
                }}
                size="md"
                autoFocus
                otp
              />
            </HStack>
          </div>
          <div className="bg-maia-purple-100 flex w-full flex-col items-center gap-y-2 rounded-md p-4 font-medium">
            <div>
              <span className="mr-1">
                {t('userSettings.security.totpModal.scanNotWorking')}
              </span>
              <span className="font-normal">
                {t('userSettings.security.totpModal.enterManually')}
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              <Skeleton loading={!totp?.secret}>
                <span>{totp?.secret}</span>
              </Skeleton>
              {totp?.secret && (
                <Tooltip
                  content={copyTooltipString}
                  closeDelay={500}
                  positioning={{ placement: 'top' }}
                >
                  <IconButton
                    variant={'ghost'}
                    size={'xs'}
                    aria-label={t('general.copy')}
                    className="hover:bg-maia-purple-200 text-maia-gray-600 hover:text-maia-gray-700"
                    onClick={handleCopy}
                  >
                    <FontAwesomeIcon icon={faClone} className="fill-current" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </DialogBody>
        <Separator />
        <DialogFooter gap={2}>
          <Button
            size={'sm'}
            className="font-medium"
            variant="outline"
            onClick={handleModalClose}
          >
            {t('userSettings.security.totpModal.secondaryButton')}
          </Button>
          <Button
            ref={confirmButtonRef}
            colorPalette="maia-purple"
            size={'sm'}
            onClick={handle2FASetupConfirmation}
            disabled={code.length < 6}
          >
            {t('userSettings.security.totpModal.primaryButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
