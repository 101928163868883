import { ValidationError, object, ref, string } from 'yup';
import { useMemo, useRef, useState } from 'react';

import { USER_SETTINGS_TOAST_ID } from './userSettingsToastId.js';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../store/useBoundStore.js';
import { toaster } from '../../components/ui/toaster.js';
import { Field } from '../../components/ui/field.js';
import { PasswordInput } from '../../components/ui/password-input.js';
import { Button } from '../../components/ui/button.js';
import { Box } from '@chakra-ui/react';

type FormError = {
  password?: string;
  repeatpassword?: string;
};

type UpdatePasswordProps = {
  onClose: () => void;
};

function UpdatePassword({ onClose }: UpdatePasswordProps) {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);
  const supabase = useBoundStore((state) => state.supabase);

  const password = useRef('');
  const repeatpassword = useRef('');
  const [formError, setFormError] = useState<FormError>();
  const [loading, setLoading] = useState(false);

  const formSchema = useMemo(() => {
    return object().shape({
      password: string()
        .required(`${t('loginView.emptyPw')}`)
        .min(8, `${t('loginView.passwordTooShortError')}`),
      repeatpassword: string().oneOf(
        [ref('password')],
        `${t('loginView.passwordMustMatchError')}`
      ),
    });
  }, [t]);

  async function handleSave() {
    setLoading(true);

    try {
      await formSchema.validate(
        {
          password: password.current,
          repeatpassword: repeatpassword.current,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        const formError: FormError = {};
        error.inner.forEach((element) => {
          formError[element.path as keyof FormError] = element.message;
        });
        setFormError(formError);
        setLoading(false);
        return;
      }
    }

    const updatedUserData = {
      password: password.current,
    };

    if (!userInfo?.userId) return;

    try {
      const result = await supabase?.auth.updateUser(updatedUserData);

      if (result.error) {
        throw new Error(result.error.message);
      }
      toaster.create({
        id: USER_SETTINGS_TOAST_ID,
        title: t('userSettings.saveSuccess'),
        type: 'success',
      });
    } catch (err) {
      if (err instanceof Error) {
        captureException(err);
        toaster.create({
          id: USER_SETTINGS_TOAST_ID,
          title: t('userSettings.saveError'),
          description: err.message,
          type: 'error',
        });
      }
    }

    setLoading(false);
    onClose();
  }

  return (
    <Box className="flex flex-col gap-4" colorPalette={'maia-accent'}>
      <Field
        label={t('userSettings.password')}
        color={'maia-gray.700'}
        invalid={!!formError?.password}
        errorText={formError?.password}
      >
        <PasswordInput
          backgroundColor={'white'}
          placeholder={t('userSettings.password')}
          defaultValue={password.current}
          onChange={(e) => {
            password.current = e.target.value;
          }}
          autoFocus
        />
      </Field>
      <Field
        invalid={!!formError?.repeatpassword}
        errorText={formError?.repeatpassword}
        label={t('userSettings.repeatPassword')}
        color={'maia-gray.700'}
      >
        <PasswordInput
          backgroundColor={'white'}
          placeholder={t('userSettings.repeatPassword')}
          defaultValue={repeatpassword.current}
          onChange={(e) => {
            repeatpassword.current = e.target.value;
          }}
        />
      </Field>

      <div className="flex justify-end gap-2">
        <Button
          size="sm"
          variant="outline"
          className="text-maia-text-dark"
          onClick={onClose}
          loading={loading}
          colorPalette={'gray'}
        >
          {t('userSettings.auth.cancel')}
        </Button>
        <Button
          size="sm"
          className="text-maia-text-light"
          colorPalette="maia-purple"
          onClick={handleSave}
          loading={loading}
        >
          {t('userSettings.saveButton')}
        </Button>
      </div>
    </Box>
  );
}

export default UpdatePassword;
