import { t } from 'i18next';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import {
  NumberInputField,
  NumberInputRoot,
} from '@/components/ui/number-input';
import { Field } from '../../components/ui/field';
import { Button } from '../../components/ui/button';

type ChooseSeatAmountProps = {
  className?: string;
  isTrial?: boolean;
  seatsEmptyError: boolean;
  seatsInvalidError: boolean;
  continueButtonLoading: boolean;
  onContinue: (seats?: number) => void;
};

function ChooseSeatAmount({
  className,
  isTrial,
  seatsEmptyError,
  seatsInvalidError,
  continueButtonLoading,
  onContinue,
}: ChooseSeatAmountProps) {
  const [seats, setSeats] = useState<number | undefined>(1);

  return (
    <div
      className={twMerge(
        'flex max-w-xl flex-col items-center gap-8 text-center',
        className
      )}
    >
      <div className="text-2xl font-semibold">
        {t('createWorkspace.seatSelectionTitle')}
      </div>

      <Field
        invalid={seatsEmptyError || seatsInvalidError || (seats ?? 0) > 50}
        errorText={
          (seats ?? 0) > 50
            ? `${t('createWorkspace.manySeatsEnterpriseHint')}
              <a href="mailto:info@prodlane.io">info@prodlane.io</a>.`
            : seatsEmptyError
              ? t('createWorkspace.seatsEmptyError')
              : seatsInvalidError
                ? `${seats} ${t('createWorkspace.seatsInvalidError')}`
                : isTrial
                  ? t('createWorkspace.seatsTrialHint')
                  : null
        }
      >
        <NumberInputRoot
          className="flex w-28 flex-col items-center bg-white"
          min={1}
          max={isTrial ? 3 : 10000}
          defaultValue={'1'}
          onValueChange={({ value }) => {
            setSeats(Number(value));
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onContinue(seats);
            }
          }}
        >
          <NumberInputField />
        </NumberInputRoot>
      </Field>
      <div className="flex items-center justify-end gap-4">
        <Button
          loading={continueButtonLoading}
          colorPalette="maia-accent"
          onClick={() => {
            onContinue(seats);
          }}
        >
          {t('createWorkspace.continueButton')}
        </Button>
      </div>
    </div>
  );
}

export default ChooseSeatAmount;
