import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/ui/button';
import {
  DialogRoot,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

type Disable2FAModalProps = {
  title: string;
  subtitle: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function Disable2FAModal({
  title,
  subtitle,
  isOpen,
  onConfirm,
  onClose,
}: Disable2FAModalProps) {
  const { t } = useTranslation();

  const handleConfirm = async () => {
    await onConfirm();
    onClose();
  };
  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent className="text-maia-gray-700">
        <DialogHeader>
          <DialogTitle>
            <DialogTitle>{title}</DialogTitle>
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody>{subtitle}</DialogBody>
        <DialogFooter gap={2}>
          <Button
            className="text-maia-text-dark font-medium"
            variant="outline"
            onClick={onClose}
            size={'sm'}
          >
            {t('userSettings.security.disable2FAModal.secondaryButton')}
          </Button>
          <Button
            className="text-maia-text-light bg-maia-support-red"
            mr={3}
            onClick={handleConfirm}
            size={'sm'}
          >
            {t('userSettings.security.disable2FAModal.primaryButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
