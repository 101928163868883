import { useEffect, useState } from 'react';

import { Database } from 'common-ts';
import { useApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '../../components/ui/button';
import { Field } from '../../components/ui/field';
import { Input } from '@chakra-ui/react';

type TransferOwnerShipModalProps = {
  user:
    | Database['public']['Views']['workspace_users_admin_view']['Row']
    | undefined;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (userId: string | undefined) => void;
};

function TransferOwnerShipModal({
  user,
  isOpen,
  onClose,
  onConfirm,
}: TransferOwnerShipModalProps) {
  const { t } = useTranslation();
  const [emailConfirmationValue, setEmailConfirmationValue] = useState('');
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const [open, setOpen] = useState(isOpen);
  const { data: isStipeCustomer } = useApi(
    supabase,
    '/workspace',
    '/is_stripe_customer',
    {
      method: 'POST',
      workspaceId,
    }
  );

  useEffect(() => {
    setEmailConfirmationValue('');
  }, [isOpen]);

  return (
    <DialogRoot
      open={open}
      onOpenChange={(e) => {
        if (!e.open) {
          onClose();
        }
        setOpen(e.open);
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <DialogTitle>
              {t('workspaceSettings.transferOwnershipModal.title')}
            </DialogTitle>
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody className="flex flex-col gap-4">
          <div>
            {t('workspaceSettings.transferOwnershipModal.transferInfo', {
              userName: user?.email,
            })}
            <br />
            {isStipeCustomer?.stripeId
              ? t('workspaceSettings.transferOwnershipModal.stripeInfo')
              : null}
            <br />
            {t('workspaceSettings.transferOwnershipModal.noUndo')}
          </div>
          <Field
            label={t(
              'workspaceSettings.transferOwnershipModal.enterEmailLabel'
            )}
          >
            <Input
              value={emailConfirmationValue}
              onChange={(e) => {
                setEmailConfirmationValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  emailConfirmationValue === user?.email
                ) {
                  onConfirm(user.user_id ?? undefined);
                  onClose();
                }
              }}
            />
          </Field>
        </DialogBody>
        <DialogFooter>
          <div className="flex items-center gap-2">
            <Button colorPalette="gray" onClick={onClose}>
              {t('general.cancelButton')}
            </Button>
            <Button
              colorPalette="maia-accent"
              mr={3}
              onClick={() => {
                onConfirm(user?.user_id ?? undefined);
                onClose();
              }}
              disabled={emailConfirmationValue !== user?.email}
            >
              {t('workspaceSettings.transferOwnershipModal.confirmButton')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default TransferOwnerShipModal;
