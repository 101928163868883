import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../../../components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

type ConfirmDeletePromptCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

/**
 * Modal to confirm the deletion of a prompt collection.
 * @param isOpen - Whether the modal is open.
 * @param onClose - Callback when the modal is closed or the deletion is cancelled.
 * @param onConfirm - Callback when the deletion is confirmed.
 */
function ConfirmDeletePromptCollectionModal({
  isOpen,
  onClose,
  onConfirm,
}: ConfirmDeletePromptCollectionModalProps) {
  const { t } = useTranslation();
  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('promptLibrary.confirmDeletePromptCollectionDialogHeader')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody>
          {t('promptLibrary.confirmDeletePromptCollectionDialogBody')}
        </DialogBody>
        <DialogFooter gap={3}>
          <Button variant="outline" onClick={onClose}>
            {t('general.cancelButton')}
          </Button>
          <Button colorPalette="red" onClick={onConfirm}>
            {t('general.deleteButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default ConfirmDeletePromptCollectionModal;
