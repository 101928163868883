import { Input } from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { Database } from 'common-ts';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../store/useBoundStore';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/ui/button';
import { Radio, RadioGroup } from '@/components/ui/radio';

type HowDidYouHearOfUsProps = {
  className?: string;
  onContinue: () => void;
};

type HeardOfMaia = Database['public']['Enums']['heard_of_maia'];

function HowDidYouHearOfUs({ className, onContinue }: HowDidYouHearOfUsProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const userInfo = useBoundStore((state) => state.userInfo);

  const [radioSelection, setRadioSelection] = useState<HeardOfMaia>();
  const otherReasonRef = useRef('');

  function handleSend() {
    supabase
      .from('user_setting')
      .update({
        heard_of_maia: radioSelection,
        heard_of_maia_custom:
          radioSelection === 'OTHER' ? otherReasonRef.current : null,
      })
      .eq('user_id', userInfo!.userId)
      .then(() => {}); // For some reason this is needed for the update to be called at all

    onContinue();
  }

  return (
    <div
      className={twMerge(
        'flex max-w-xl flex-col items-center gap-8 px-4 text-center',
        className
      )}
    >
      <div className="text-2xl font-semibold">{t('onboarding.welcome')}</div>

      <div>{t('onboarding.maiaDescription')}</div>
      <div className="flex w-full flex-col gap-4">
        <div className="font-semibold">{t('onboarding.heardOfUs')}</div>
        <RadioGroup
          onValueChange={({ value }) => setRadioSelection(value as HeardOfMaia)}
          colorPalette={'maia-accent'}
        >
          <div className="flex flex-col gap-2 font-thin">
            <Radio className="text-md font-normal" value="EMAIL">
              <span>{t('onboarding.heardOfUsOptions.email')}</span>
            </Radio>
            <Radio className="text-md font-normal" value="LINKEDIN">
              <span>{t('onboarding.heardOfUsOptions.linkedin')}</span>
            </Radio>
            <Radio className="text-md font-normal" value="WEBSITE">
              <span>{t('onboarding.heardOfUsOptions.website')}</span>
            </Radio>
            <Radio className="text-md font-normal" value="WORKSHOP">
              <span>{t('onboarding.heardOfUsOptions.workshop')}</span>
            </Radio>
            <Radio className="text-md font-normal" value="RECOMMENDATION">
              <span>{t('onboarding.heardOfUsOptions.recommendation')}</span>
            </Radio>
            <Radio className="text-md font-normal" value="NEWSPAPER">
              <span>{t('onboarding.heardOfUsOptions.newspaper')}</span>
            </Radio>
            <Radio className="text-md font-normal" value="OTHER">
              <span>{t('onboarding.heardOfUsOptions.other')}:</span>
            </Radio>
          </div>
        </RadioGroup>
        <Input
          placeholder={t('onboarding.heardOfUsOptions.specify')}
          maxLength={300}
          onChange={(e) => {
            otherReasonRef.current = e.target.value;
          }}
          disabled={radioSelection !== 'OTHER'}
        />
      </div>
      <div className="flex items-center justify-between gap-4 self-stretch">
        <Button variant={'subtle'} onClick={onContinue}>
          {t('general.skipButtons')}
        </Button>
        <Button
          colorPalette="maia-accent"
          disabled={!radioSelection}
          onClick={handleSend}
        >
          {t('onboarding.continueButton')}
        </Button>
      </div>
    </div>
  );
}

export default HowDidYouHearOfUs;
