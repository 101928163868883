import { createSystem, defaultConfig } from '@chakra-ui/react';
import { fontFamily, fontSizeTokens } from './tokens/font';
import { colorTokens } from './tokens/colors';
import { colorSemanticTokens } from './semantic-tokens/colors';
import { buttonRecipe } from './recipes/button';
import { separatorRecipe } from './recipes/separator';
import { textAreaRecipe } from './tokens/textArea';
import { inputRecipe } from './tokens/input';
import { fieldSlotRecipe } from './slot-recipes/field';
import { dialogSlotRecipe } from './slot-recipes/dialog';
import { tableSlotRecipe } from './slot-recipes/table';

// Generate theme and recipe typings, if theme is updated
// www.chakra-ui.com/docs/get-started/cli#usage
export const system = createSystem(defaultConfig, {
  theme: {
    // basic, static styling
    tokens: {
      fonts: {
        body: { value: fontFamily },
      },
      fontSizes: fontSizeTokens,
      colors: colorTokens,
    },
    // dynamic, context-aware styling
    semanticTokens: {
      colors: colorSemanticTokens,
    },
    // simple component styling
    recipes: {
      button: buttonRecipe,
      separator: separatorRecipe,
      textarea: textAreaRecipe,
      input: inputRecipe,
    },
    // complex component styling with different parts such as tabs, fields, dialog, etc.
    slotRecipes: {
      field: fieldSlotRecipe,
      dialog: dialogSlotRecipe,
      table: tableSlotRecipe,
    },
  },
});
