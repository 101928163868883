import {
  Database,
  ROUTES,
  SupabaseAuthError,
  getPlanLevel,
  maiaErrorWithStack,
} from 'common-ts';
import { CreateToasterReturn } from '@chakra-ui/react';

import { NavigateFunction } from 'react-router-dom';
import { SupabaseClient } from '@supabase/supabase-js';
import { TFunction } from 'i18next';
import { captureException } from '@sentry/react';
import { fetchApi } from './useApi';

/**
 * Get the most suitable workspace. Will return the first workspace that matches the criteria applied in the following order:
 * 1) Workspace that the user used last
 * 2) Workspace with the highest license that is not inactive + user is not disabled
 * 3) Workspace with the highest license that the user is owner of
 * 4) Workspace with the highest license that the user is admin of
 * 5) Create new 'FREE' workspace
 */
export async function getDefaultWorkspace({
  supabase,
  allWorkspacesOfUser,
}: {
  supabase: SupabaseClient<Database, 'public'>;
  allWorkspacesOfUser: (Database['public']['Tables']['workspace']['Row'] & {
    workspace_user: {
      disabled: Database['public']['Tables']['workspace_user']['Row']['disabled'];
      user_type: Database['public']['Tables']['workspace_user']['Row']['user_type'];
    }[];
  })[];
}) {
  const { data: sessionData, error: sessionError } =
    await supabase.auth.getSession();
  if (sessionError) {
    if (sessionError.code === 'flow_state_expired') {
      return {
        data: null,
        error: maiaErrorWithStack({
          name: 'SessionExpired',
          message: 'User session expired',
        }),
      };
    }

    return {
      data: null,
      error: maiaErrorWithStack<SupabaseAuthError>({
        name: 'SupabaseAuthError',
        message: 'Error trying to get session',
        payload: { originalError: sessionError },
      }),
    };
  }

  if (!sessionData.session) {
    return {
      data: null,
      error: maiaErrorWithStack({
        name: 'NoUserSession',
        message: 'No active user session found',
      }),
    };
  }

  const userId = sessionData.session.user.id;

  // User has no workspaces
  if (allWorkspacesOfUser.length === 0) {
    const { data } = await fetchApi(supabase, '/user', '/no_workspace', {
      method: 'GET',
    });

    if (!data) {
      return {
        data: null,
        error: maiaErrorWithStack({
          message: 'Error trying to create new workspace for user',
          name: 'BackendError',
        }),
      };
    }
    return { data: data.newWorkspaceId, error: null };
  }

  const lastSetWorkspaceId = localStorage.getItem(`workspaceId_${userId}`);

  const lastUsedWorkspace = allWorkspacesOfUser.find(
    (workspace) => workspace.id === lastSetWorkspaceId
  );

  if (
    lastUsedWorkspace &&
    !lastUsedWorkspace.inactive &&
    !lastUsedWorkspace.workspace_user[0]?.disabled
  ) {
    return { data: lastUsedWorkspace.id, error: null };
  }

  const workspacesByLicenseType = allWorkspacesOfUser.sort(
    (workspaceA, workspaceB) =>
      getPlanLevel(workspaceB.license_type) -
      getPlanLevel(workspaceA.license_type)
  );

  // Navigate to workspace with highest license which is enabled for the user
  // Alternatively, if no active workspace is available, navigate to workspace with highest license, that the user owns
  // Alternatively, navigate to workspace with highest license, that user is ADMIN of
  let workspaceIdOwnedByUser: string | undefined;
  let inactiveWorkspaceIdWhereUserIsAdmin: string | undefined;
  for (const workspace of workspacesByLicenseType) {
    if (!workspace.inactive && !workspace.workspace_user[0]?.disabled) {
      return { data: workspace.id, error: null };
    }
    if (
      workspace.workspace_user[0]?.user_type === 'OWNER' &&
      !workspaceIdOwnedByUser
    ) {
      workspaceIdOwnedByUser = workspace.id;
    }
    if (
      workspace.workspace_user[0]?.user_type === 'ADMIN' &&
      !inactiveWorkspaceIdWhereUserIsAdmin
    ) {
      inactiveWorkspaceIdWhereUserIsAdmin = workspace.id;
    }
  }

  if (workspaceIdOwnedByUser) {
    return { data: workspaceIdOwnedByUser, error: null };
  }

  if (inactiveWorkspaceIdWhereUserIsAdmin) {
    return { data: inactiveWorkspaceIdWhereUserIsAdmin, error: null };
  }

  // No suitable workspace found (User can not change anything about the inactive / disabled status of the workspaces they are part of, because they do not have OWNER or ADMIN roles in any of them)
  // => handle the same as if user was not part of any workspace
  // TODO endpoint for user has no workspace
  const { data } = await fetchApi(supabase, '/user', '/no_workspace', {
    method: 'GET',
  });

  if (!data) {
    return {
      data: null,
      error: maiaErrorWithStack({
        message: 'Error trying to create new workspace for user',
        name: 'BackendError',
      }),
    };
  }
  return { data: data.newWorkspaceId, error: null };
}

/**
 * Will navigate to the most suitable workspace.
 * See `getDefaultWorkspace` for how the most suitable workspace is determined.
 */
export async function navigateToDefaultWorkspace({
  supabase,
  navigateFn,
  toaster,
  translationFn,
}: {
  supabase: SupabaseClient<Database, 'public'>;
  navigateFn: NavigateFunction;
  toaster: CreateToasterReturn;
  translationFn: TFunction;
}) {
  const allWorkspacesRes = await supabase
    .from('workspace')
    .select('*, workspace_user(user_type, disabled)');

  if (allWorkspacesRes.error || !allWorkspacesRes.data) {
    captureException(allWorkspacesRes.error);
    toaster.create({
      title: translationFn('navigateToWorkspace.supabaseError'),
      type: 'error',
    });
    return;
  }
  const { data: navigateToWorkspaceId, error } = await getDefaultWorkspace({
    allWorkspacesOfUser: allWorkspacesRes.data,
    supabase,
  });

  if (error) {
    switch (error.name) {
      case 'SupabaseAuthError':
      case 'NoUserSession':
        navigateFn(ROUTES.AUTH.SIGN_IN.buildPath({}));
        break;
      case 'BackendError':
        toaster.create({
          title: translationFn('navigateToWorkspace.supabaseError'),
          type: 'error',
        });
        navigateFn(ROUTES.AUTH.SIGN_IN.buildPath({}));
        break;
      case 'SessionExpired':
        toaster.create({
          title: translationFn('navigateToWorkspace.supabaseError'),
          type: 'warning',
        });
        navigateFn(ROUTES.AUTH.SIGN_IN.buildPath({}));
    }
    return;
  }
  navigateFn(
    ROUTES.HOME.CHAT.buildPath({ workspaceId: navigateToWorkspaceId })
  );
}
