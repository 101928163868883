import { IconButton } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoLettering from '../../components/Logo/LogoLettering';
import { ROUTES } from 'common-ts';
import SubscriptionTable from '../../components/SubscriptionTable/SubscriptionTable';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

export default function Pricing() {
  const [{ navBackOnClose }] = useTypedSearchParams(ROUTES.HOME.PRICING);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const { data: trialEligibilityData } = useApi(
    supabase,
    '/payment',
    '/trial_eligibility',
    {
      method: 'POST',
      workspaceId,
    }
  );

  const isEligibleForTrial = trialEligibilityData?.is_eligible;

  return (
    <div className="relative flex h-full flex-col gap-5 py-6 lg:justify-center">
      <IconButton
        variant={'subtle'}
        aria-label="close"
        className="absolute right-1 top-1 sm:right-4 sm:top-4"
        onClick={() => {
          navBackOnClose ? navigate(navBackOnClose) : navigate(-1);
        }}
        borderRadius={'full'}
      >
        <FontAwesomeIcon icon={faXmark} />
      </IconButton>
      <LogoLettering size={74} />
      <div className="flex flex-col items-center gap-2.5 px-11 py-5">
        <div className="text-4xl font-extrabold">
          {t('pricingTable.choosePlan')}
        </div>
        {isEligibleForTrial ? (
          <div className="text-xl font-medium">
            {t('pricingTable.startWithTrial')}
          </div>
        ) : null}
      </div>
      <SubscriptionTable isEligibleForTrial={isEligibleForTrial} />
    </div>
  );
}
