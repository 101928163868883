import { useEffect, useRef } from 'react';

import { FREE_USER_REQUEST_LIMIT } from '../../utils/userPlan.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { toaster } from '../../components/ui/toaster.js';

function ChatRequestLimitToaster() {
  const { t } = useTranslation();
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const sentRequests = useBoundStore((state) => state.sentRequests);

  const prevRequestCountRef = useRef<number>();

  useEffect(() => {
    if (workspaceLicenseType === 'FREE') {
      if (
        sentRequests % 10 === 0 &&
        sentRequests > 0 &&
        sentRequests !== prevRequestCountRef.current
      ) {
        toaster.create({
          title: t('chat.messageLimitInfo', {
            used: sentRequests,
            total: FREE_USER_REQUEST_LIMIT,
          }),
          type: 'info',
        });
        prevRequestCountRef.current = sentRequests;
      }
    }
  }, [sentRequests]);

  return <></>;
}

export default ChatRequestLimitToaster;
