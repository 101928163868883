import { defineRecipe } from '@chakra-ui/react';

export const buttonRecipe = defineRecipe({
  base: {
    fontWeight: '600',
    borderRadius: 'md',
    paddingInline: '12px',
    cursor: 'pointer',
    focusRingColor: 'maia-accent.500',
  },
  variants: {
    size: {
      lg: { fontSize: 'lg' },
      md: { fontSize: 'md' },
      sm: { fontSize: 'sm' },
      xs: { fontSize: 'xs' },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});
