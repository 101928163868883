import { WorkspaceState, fetchWorkspaceStateInfo } from './fetchWorkspaceInfo';
import { fetchSentRequests, getExtendedBuckets, getUserInfo } from '../utils';

import { StateCreator } from 'zustand';
import { SupabaseSlice } from './createSupabaseSlice';
import { UserInfoSlice } from './createUserInfoSlice.js';
import { getCurrentScope } from '@sentry/react';
import { getIsOnboarded } from '../../utils/onboarding';
import { getUserPlan } from '../../utils/userPlan';

type WorkspaceAction = {
  setWorkspaceId: (workspaceId: string) => void;
  updateExtendedBuckets: () => Promise<void>;
  updateWorkspaceState: (workspaceId: string) => Promise<void>;
  updateLastVisitedCollection: (collectionId: string | undefined) => void;
  updateFeatureFlags: (workspaceId: string) => Promise<void>;
};

export type WorkspaceInfoSlice = WorkspaceState & WorkspaceAction;

export const initialWorkspaceState: WorkspaceState = {
  loading: true,
  extendedBuckets: [],
  featureFlags: undefined,
  workspaceId: '',
  workspaceName: '',
  workspaceUserType: 'USER',
  workspaceInactive: null,
  workspaceLicenseType: null,
  workspaceUserDisabled: null,
  isStripeCustomer: undefined,
  isInFreeTrial: undefined,
  lastVisitedCollection: undefined,
};

export const createWorkspaceInfoSlice: StateCreator<
  SupabaseSlice & UserInfoSlice & WorkspaceInfoSlice,
  [],
  [],
  WorkspaceInfoSlice
> = (set, get) => ({
  ...initialWorkspaceState,
  setWorkspaceId: (workspaceId) => {
    set({ loading: true });
    // set workspace preference
    const userId = get().userInfo?.userId;
    if (userId) localStorage.setItem(`workspaceId_${userId}`, workspaceId);

    set({ workspaceId });
    get().updateWorkspaceState(workspaceId);
  },
  updateExtendedBuckets: async () => {
    const { supabase, workspaceId } = get();
    const userInfo = await getUserInfo(supabase);
    if (!userInfo) return;
    const { userId } = userInfo;

    const extendedBuckets = await getExtendedBuckets(
      supabase,
      userId,
      workspaceId
    );
    set({ extendedBuckets });
  },
  updateLastVisitedCollection: (collectionId) => {
    set({ lastVisitedCollection: collectionId });
  },
  updateWorkspaceState: async (workspaceId) => {
    const { supabase } = get();

    if (supabase) {
      set({ loading: true });
      const onboardingStatus = await getIsOnboarded(supabase);
      const userPlanResult = await getUserPlan(supabase, workspaceId);
      const userInfo = await getUserInfo(supabase);

      if (!userInfo) {
        set({ loading: false });
        return;
      }

      const { userId } = userInfo;
      getCurrentScope().setUser({ id: userId });
      const sentRequestsResult = await fetchSentRequests(supabase);

      const workspaceState = await fetchWorkspaceStateInfo(
        workspaceId,
        supabase,
        userId
      );

      set((state) => ({
        ...workspaceState,
        isOnboarded: onboardingStatus,
        userIsMarker: userPlanResult.marker,
        userInfo,
        sentRequests: sentRequestsResult,
        lastVisitedCollection: state.lastVisitedCollection,
      }));

      supabase.auth.onAuthStateChange((event, session) => {
        switch (event) {
          case 'INITIAL_SESSION':
          case 'TOKEN_REFRESHED':
            set({ accessToken: session?.access_token });
            break;
          case 'SIGNED_IN':
          case 'USER_UPDATED':
          case 'SIGNED_OUT':
          case 'MFA_CHALLENGE_VERIFIED':
          case 'PASSWORD_RECOVERY':
            break;
        }
      });
      set({ loading: false });
    }
  },
  updateFeatureFlags: async (workspaceId) => {
    const { supabase } = get();

    if (supabase) {
      const { featureFlags } = await getUserPlan(supabase, workspaceId);
      set({ featureFlags });
    }
  },
});
