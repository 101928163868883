// !! If you add / remove something here, you also need to create a db migration to add / remove mime_types from internal.supported_mime_types.
export const supportedFileTypes = [
    // pdf
    'application/pdf',
    // docx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // txt
    'text/plain;charset=UTF-8',
    'text/plain',
    // csv
    'text/csv',
    // pptx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
export const supportedGlossaryFileTypes = [
    'application/pdf',
    'text/csv',
    'text/plain',
    'text/plain;charset=UTF-8',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
