import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../i18n.js';
import { supportedLanguages } from 'common-ts';
import { useState } from 'react';
import { Avatar } from './ui/avatar.js';
import { Button } from './ui/button.js';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from './ui/menu.js';
import { IconButton } from '@chakra-ui/react';

function ChangeLanguage() {
  const [currentLanguage, setCurrentLanguage] = useState(
    supportedLanguages.find(
      (supportedLang) => supportedLang.short === i18n.language.split('-')[0]
    )?.short ?? 'en'
  );
  const [open, setOpen] = useState(false);

  const setLanguage = (lang: (typeof supportedLanguages)[number]['short']) => {
    if (lang === currentLanguage) return;
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <MenuRoot open={open} onOpenChange={() => setOpen((prev) => !prev)}>
      <MenuTrigger asChild focusRingStyle={'hidden'}>
        <IconButton variant={'ghost'} className="p-0" colorPalette="whiteAlpha">
          <Avatar className="h-6 w-6" src={`/lang_${currentLanguage}.png`} />
          <FontAwesomeIcon
            icon={open ? faCaretUp : faCaretDown}
            className="text-maia-accent pl-1"
          />
        </IconButton>
      </MenuTrigger>
      <MenuContent className="min-w-max px-0">
        {supportedLanguages.map((lang, index) => (
          <MenuItem value={lang.full}>
            <Button
              key={index}
              colorPalette={`${
                lang.short === currentLanguage ? 'maia-purple' : 'transparent'
              }`}
              variant={lang.short === currentLanguage ? 'solid' : 'subtle'}
              className="p-0"
              onClick={() => {
                setLanguage(lang.short);
                setOpen(false);
              }}
            >
              <Avatar className="h-6 w-6" src={`/lang_${lang.short}.png`} />
            </Button>
          </MenuItem>
        ))}
      </MenuContent>
    </MenuRoot>
  );
}

export default ChangeLanguage;
