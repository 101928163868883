import {
  Button,
  createListCollection,
  Input,
  SelectTrigger,
  SelectValueText,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { Database } from 'common-ts';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Field } from '../../components/ui/field';
import {
  SelectRoot,
  SelectContent,
  SelectItem,
} from '../../components/ui/select';

type TellUsAboutYourselfProps = {
  className?: string;
  firstNameError: boolean;
  onContinue: (
    firstName: string,
    lastName: string,
    jobTitle: Database['public']['Enums']['job_description'] | undefined,
    jobTitleOther: string | undefined
  ) => void;
};

function TellUsAboutYourself({
  className,
  firstNameError,
  onContinue,
}: TellUsAboutYourselfProps) {
  const { t } = useTranslation();

  const firstNameRef = useRef('');
  const lastNameRef = useRef('');
  const jobTitleOtherRef = useRef('');
  const [jobTitle, setJobTitle] = useState<
    Database['public']['Enums']['job_description'] | undefined
  >(undefined);

  const jobDescriptionTranslationMap: {
    [key in Database['public']['Enums']['job_description']]: string;
  } = {
    AGILE: t('onboarding.jobDescriptions.agile'),
    ANALYTICS: t('onboarding.jobDescriptions.analytics'),
    DESIGN: t('onboarding.jobDescriptions.design'),
    DEV_ENG: t('onboarding.jobDescriptions.dev'),
    C_LEVEL: t('onboarding.jobDescriptions.cLevel'),
    HR: t('onboarding.jobDescriptions.hr'),
    IT: t('onboarding.jobDescriptions.it'),
    LEGAL_FINANCE: t('onboarding.jobDescriptions.legal'),
    MARKETING: t('onboarding.jobDescriptions.marketing'),
    OPERATIONS: t('onboarding.jobDescriptions.operations'),
    PRODUCT_MANAGEMENT: t('onboarding.jobDescriptions.product'),
    PROJECT_MANAGEMENT: t('onboarding.jobDescriptions.project'),
    SALES: t('onboarding.jobDescriptions.sales'),
    STUDENT: t('onboarding.jobDescriptions.student'),
    SUPPORT: t('onboarding.jobDescriptions.support'),
    TEACHER: t('onboarding.jobDescriptions.teacher'),
    UX: t('onboarding.jobDescriptions.ux'),
    OTHER: t('onboarding.jobDescriptions.other'),
  };

  function handleContinue() {
    let jobTitleToSend = jobTitle;
    if (
      !Array.from(Object.keys(jobDescriptionTranslationMap)).includes(
        jobTitleToSend ?? ''
      )
    ) {
      jobTitleToSend = undefined;
    }

    onContinue(
      firstNameRef.current,
      lastNameRef.current,
      jobTitleToSend,
      jobTitleOtherRef.current
    );
  }
  const jobTitleCollection = createListCollection({
    items: Object.entries(jobDescriptionTranslationMap).map(([key, val]) => {
      return {
        label: val,
        value: key,
      };
    }),
  });

  return (
    <div
      className={twMerge(
        'flex w-full max-w-xl flex-col items-center gap-8 px-4 text-center',
        className
      )}
    >
      <div className="text-2xl font-semibold">
        {t('onboarding.tellUsAboutYourself')}
      </div>
      <Field
        label={`${t('userSettings.firstName')}*`}
        className="w-full"
        invalid={firstNameError}
        errorText={t('onboarding.firstNameError')}
      >
        <Input
          colorPalette={'maia-accent'}
          backgroundColor={'white'}
          placeholder={t('userSettings.firstName')}
          onChange={(e) => {
            firstNameRef.current = e.target.value;
          }}
        />
      </Field>
      <Field label={t('userSettings.lastName')} className="w-full">
        <Input
          colorPalette={'maia-accent'}
          backgroundColor={'white'}
          placeholder={t('userSettings.lastName')}
          onChange={(e) => {
            lastNameRef.current = e.target.value;
          }}
        />
      </Field>
      <Field label={t('onboarding.jobTitle')} className="w-full">
        <SelectRoot
          collection={jobTitleCollection}
          size={'sm'}
          onValueChange={({ value }) => {
            setJobTitle(
              value[0] as Database['public']['Enums']['job_description']
            );
          }}
        >
          <SelectTrigger>
            <SelectValueText placeholder={t('onboarding.jobTitle')} />
          </SelectTrigger>
          <SelectContent>
            {jobTitleCollection.items.map((job) => (
              <SelectItem key={job.label} item={job}>
                {job.label}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectRoot>
        {jobTitle === 'OTHER' ? (
          <Input
            className="mt-2"
            backgroundColor={'white'}
            placeholder={t('onboarding.jobTitle')}
            onChange={(e) => {
              jobTitleOtherRef.current = e.target.value;
            }}
          />
        ) : null}
      </Field>
      <div className="flex items-center justify-end gap-4 self-stretch">
        <Button colorPalette="maia-accent" onClick={handleContinue}>
          {t('onboarding.continueButton')}
        </Button>
      </div>
    </div>
  );
}

export default TellUsAboutYourself;
