import { HStack } from '@chakra-ui/react';
import { Accordion } from '@chakra-ui/react/accordion';
import * as React from 'react';
import { LuChevronDown } from 'react-icons/lu';

interface AccordionItemTriggerProps extends Accordion.ItemTriggerProps {
  indicatorPlacement?: 'start' | 'end' | 'none';
  customIndicator?: React.ReactNode;
}

export const AccordionItemTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionItemTriggerProps
>((props, ref) => {
  const {
    children,
    indicatorPlacement = 'end',
    customIndicator,
    ...rest
  } = props;
  return (
    <Accordion.ItemTrigger {...rest} ref={ref} cursor={'pointer'}>
      {indicatorPlacement === 'start' && (
        <Accordion.ItemIndicator
          rotate={
            customIndicator
              ? { base: '0deg', _open: '0deg' }
              : { base: '-90deg', _open: '0deg' }
          }
        >
          {customIndicator ?? <LuChevronDown />}
        </Accordion.ItemIndicator>
      )}
      <HStack gap="4" flex="1" textAlign="start" width="full">
        {children}
      </HStack>
      {indicatorPlacement === 'end' && (
        <Accordion.ItemIndicator
          rotate={
            customIndicator
              ? { base: '0deg', _open: '0deg' }
              : { base: '-90deg', _open: '0deg' }
          }
        >
          {customIndicator ?? <LuChevronDown />}
        </Accordion.ItemIndicator>
      )}
    </Accordion.ItemTrigger>
  );
});

interface AccordionItemContentProps extends Accordion.ItemContentProps {}

export const AccordionItemContent = React.forwardRef<
  HTMLDivElement,
  AccordionItemContentProps
>((props, ref) => {
  return (
    <Accordion.ItemContent>
      <Accordion.ItemBody {...props} ref={ref} />
    </Accordion.ItemContent>
  );
});

export const AccordionRoot = Accordion.Root;
export const AccordionItem = Accordion.Item;
