import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '@/components/ui/dialog';
import { useRef } from 'react';
import UpgradeSubscriptionButton from './UpgradeSubscriptionButton';
import { t } from 'i18next';
import { HStack } from '@chakra-ui/react';
import { Button } from './ui/button';

type MaiaAlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  AlertDialogHeaderText: string;
  AlertDialogBodyText: string;
};

function MaiaAlertDialogWithUpgradeButton({
  isOpen,
  onClose,
  AlertDialogHeaderText,
  AlertDialogBodyText,
}: MaiaAlertDialogProps) {
  const cancelRef = useRef(null);

  return (
    <DialogRoot
      open={isOpen}
      finalFocusEl={() => cancelRef.current}
      onOpenChange={({ open }) => !open && onClose()}
      onEscapeKeyDown={onClose}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader fontSize="lg" fontWeight="bold">
          <DialogTitle>{AlertDialogHeaderText}</DialogTitle>
        </DialogHeader>
        <DialogBody>{AlertDialogBodyText}</DialogBody>
        <DialogFooter>
          <HStack gap={2}>
            <UpgradeSubscriptionButton flexShrink={0} size={'md'}>
              {t('general.upgradeButton')}
            </UpgradeSubscriptionButton>
            <Button ref={cancelRef} onClick={onClose}>
              {t('general.closeButtons')}
            </Button>
          </HStack>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default MaiaAlertDialogWithUpgradeButton;
