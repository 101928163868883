import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from '../components/ui/accordion';
import {
  EnrichedBasicLayoutItem,
  SnippetResultsV1,
} from '../../../common-ts/src/types/chatEndpoint';
import { FileNameId, ROUTES } from 'common-ts';
import {
  faArrowDownLeft,
  faArrowUpRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';

import ChatSnippetResult from './SnippetResult.js';
import DocumentSummaryResult from '../pages/chat/DocumentSummaryResult.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { abstractV1V2 } from './resultsV1V2Conversion.js';
import { faBookOpen } from '@fortawesome/pro-regular-svg-icons';
import { useBoundStore } from '../store/useBoundStore';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/ui/button';

type QueryResultsProps = {
  snippets: SnippetResultsV1 | EnrichedBasicLayoutItem;
  isPowerMode: boolean;
  focusInlineReference: number;
  clearInlineReference: () => void;
  fileSummaries?: FileNameId[];
  wholeFiles?: FileNameId[];
  isExpanded?: boolean;
  glossaryUsed?: boolean;
};
function QueryResults({
  snippets,
  isPowerMode,
  focusInlineReference,
  clearInlineReference,
  fileSummaries = [],
  wholeFiles = [],
  isExpanded = false,
  glossaryUsed = false,
}: QueryResultsProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string[]>(
    isExpanded ? ['snippets', 'summaries', 'wholefiles'] : []
  );
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const abstraction = useMemo(() => abstractV1V2(snippets), [snippets]);

  const snippetsResultJSX = () => (
    <AccordionItem
      value="snippets"
      border={'none'}
      className="w-full cursor-pointer"
    >
      <AccordionItemTrigger
        padding={0}
        className="flex w-full hover:bg-gray-100"
        customIndicator={
          <Button
            size={'xs'}
            className="flex-shrink-0 text-xs"
            colorPalette="maia-accent"
          >
            {value.includes('snippets')
              ? t('general.collapse')
              : t('general.expand')}
            <FontAwesomeIcon
              className="text-xs"
              icon={
                value.includes('snippets') ? faArrowUpRight : faArrowDownLeft
              }
            />
          </Button>
        }
      >
        <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
          {t('general.foundXSnippets', {
            amount: abstraction.length,
          })}
        </div>
      </AccordionItemTrigger>
      <AccordionItemContent padding={0} className="flex flex-col gap-[10px]">
        <div className="bg-maia-border h-[1px] w-full" />
        <ChatSnippetResult
          result={abstraction}
          focusInlineReference={focusInlineReference}
          clearInlineReference={clearInlineReference}
        />
      </AccordionItemContent>
    </AccordionItem>
  );
  const fileSummariesJSX = () => (
    <AccordionItem value="summaries" border="none">
      <AccordionItemTrigger
        padding={0}
        className="flex w-full hover:bg-gray-100"
        customIndicator={
          <Button
            size={'xs'}
            className="flex-shrink-0 text-xs"
            colorPalette="maia-accent"
          >
            {isExpanded ? t('general.collapse') : t('general.expand')}
            <FontAwesomeIcon
              className="text-xs"
              icon={isExpanded ? faArrowUpRight : faArrowDownLeft}
            />
          </Button>
        }
      >
        <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
          {t('general.usedSummary')}: {fileSummaries.length}
        </div>
      </AccordionItemTrigger>
      <AccordionItemContent padding={0} className="flex flex-col gap-[10px]">
        <div className="bg-maia-gray-200 h-[1px] w-full" />
        <DocumentSummaryResult summariesOrWholeDocs={fileSummaries} />
      </AccordionItemContent>
    </AccordionItem>
  );
  const wholeFilesJSX = () => (
    <AccordionItem value="wholefiles" border="none">
      <h2>
        <AccordionItemTrigger
          padding={0}
          className="flex w-full justify-between hover:bg-gray-100"
          customIndicator={
            <Button
              size={'xs'}
              className="flex-shrink-0 text-xs"
              colorPalette="maia-accent"
            >
              {isExpanded ? t('general.collapse') : t('general.expand')}{' '}
              <FontAwesomeIcon
                className="text-xs"
                icon={isExpanded ? faArrowUpRight : faArrowDownLeft}
              />
            </Button>
          }
        >
          <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
            {t('general.usedWhole')}: {wholeFiles.length}
          </div>
        </AccordionItemTrigger>
      </h2>
      <AccordionItemContent padding={0} className="flex flex-col gap-[10px]">
        <div className="bg-maia-gray-200 h-[1px] w-full" />
        <DocumentSummaryResult summariesOrWholeDocs={wholeFiles} />
      </AccordionItemContent>
    </AccordionItem>
  );

  useEffect(() => {
    if (focusInlineReference !== -1)
      setValue(['snippets', 'summaries', 'wholefiles']);
  }, [focusInlineReference]);
  return (
    <AccordionRoot
      className="w-full cursor-pointer rounded"
      value={value}
      onValueChange={(e) => setValue(e.value)}
      multiple
    >
      {/* TODO v2 might make sense displayed another way */}
      {abstraction.length ? snippetsResultJSX() : null}
      {isPowerMode && fileSummaries?.length ? fileSummariesJSX() : null}
      {isPowerMode && wholeFiles?.length ? wholeFilesJSX() : null}
      {glossaryUsed ? (
        <div
          className={
            'border-maia-border mt-2 flex w-full cursor-pointer items-center justify-between gap-[10px] rounded border px-0 py-0 font-bold md:px-4 md:py-2'
          }
        >
          <div className="text-maia-accent flex items-center gap-2">
            <FontAwesomeIcon icon={faBookOpen} />
            <Link
              to={ROUTES.HOME.SETTINGS.WORKSPACE.GLOSSARY.buildPath({
                workspaceId,
              })}
              target="_blank"
            >
              <span className="font-medium leading-[26px]">
                {t('general.viewGlossary')}
              </span>
            </Link>
          </div>
        </div>
      ) : null}
    </AccordionRoot>
  );
}

export default QueryResults;
