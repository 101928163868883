import { useEffect, useState } from 'react';
import { Text, Box, Separator } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { faUser } from '@fortawesome/pro-regular-svg-icons';

import AuthenticatorAppMFA from './AuthenticatorAppMFA';
import ContentHeader from '../../../components/ContentHeader';
import EmailMFA from './EmailMFA';
import MaiaFullGridWidthContent from '../../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../../components/layout/MaiaMainGrid';
import SettingsSection from '../../settings/SettingsSection';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { ROUTES } from 'common-ts';
import { Link as RouterLink } from 'react-router-dom';

export default function SecuritySetting() {
  const { t } = useTranslation();
  const userInfo = useBoundStore((state) => state.userInfo);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [mfaCheck, setMfaCheck] = useState<boolean>(false);

  useEffect(() => {
    if (mfaCheck) setMfaCheck(false);
  }, [mfaCheck]);

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('userSettings.security.title')}
          buttons={[]}
          icon={faUser}
          iconLabel={
            userInfo?.firstName
              ? `${userInfo.firstName} ${userInfo.lastName ?? ''}`
              : userInfo?.email ?? ''
          }
        />
        <SettingsSection>{t('userSettings.security.2FAtitle')}</SettingsSection>
        <div className="mb-4">{t('userSettings.security.subtitle')}</div>
        <AuthenticatorAppMFA setMfaCheck={(val) => setMfaCheck(val)} />
        <EmailMFA setMfaCheck={(val) => setMfaCheck(val)} />
        <Separator />
        <Box pt={2}>
          <Text textAlign="right" fontSize="xx-small" color="gray.500">
            <RouterLink
              to={ROUTES.HOME.SETTINGS.OPEN_SOURCE_SOFTWARE.buildPath({
                workspaceId: workspaceId!,
              })}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              Open source software
            </RouterLink>
          </Text>
        </Box>
      </MaiaFullGridWidthContent>
    </MaiaMainGrid>
  );
}
