/**
 * Cleans up unnecessary entries in a SharepointFileSelectionMap object.
 *
 * The function processes a `SharepointFileSelectionMap` and performs two main actions:
 *
 * 1. Removes all folders and their children where `selected === false`. This indicates that
 *    neither the folder nor any of its children should be included.
 *
 * 2. Removes all child entries where `selected === true` for a folder. This implies that
 *    the folder is selected entirely, so its children are implicitly included and thus unnecessary.
 *
 * The function creates a deep clone of the input object before performing the cleanup, and returns this cleaned version.
 *
 * @param {SharepointFileSelectionMap} sharepointFileMap - The map of SharePoint sites and folders to be cleaned.
 * @returns {SharepointFileSelectionMap} A new, cleaned SharePoint file selection map with unnecessary entries removed.
 */
export function cleanUpFileSelectionMap(sharepointFileMap) {
    const cloneSharepointFileMap = JSON.parse(JSON.stringify(sharepointFileMap));
    for (const siteId in cloneSharepointFileMap) {
        if (cloneSharepointFileMap[siteId]?.selected === false) {
            delete cloneSharepointFileMap[siteId]; // Remove entire site if not selected
        }
        else {
            const foldersToDelete = new Set();
            for (const folderId in cloneSharepointFileMap[siteId].folders) {
                const folder = cloneSharepointFileMap[siteId].folders[folderId];
                if (folder?.selected === false) {
                    // Mark folder and its subfolders for deletion
                    foldersToDelete.add(folderId);
                }
                else if (folder?.selected === true) {
                    // Mark all subfolders for deletion, as the folder itself is selected
                    foldersToDelete.add(`${folderId}_`);
                }
            }
            // Delete marked folders and their subfolders
            for (const folderId in cloneSharepointFileMap[siteId].folders) {
                foldersToDelete.forEach((deletePartKey) => {
                    if (folderId.startsWith(deletePartKey)) {
                        delete cloneSharepointFileMap[siteId].folders[folderId];
                    }
                });
            }
        }
    }
    return cloneSharepointFileMap;
}
