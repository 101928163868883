import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from './ui/tooltip';

type ContentHeaderProps = {
  icon: IconDefinition;
  iconLabel: string;
  headingLabel: string;
  buttons: Array<JSX.Element>;
};

function ContentHeader({
  icon,
  iconLabel,
  headingLabel,
  buttons,
}: ContentHeaderProps) {
  return (
    <div className="border-maia-border my-4 flex flex-col gap-2 border-b-[1px] pb-2">
      <div className="text-chakra-gray-500 flex items-center gap-2 text-xs">
        <FontAwesomeIcon className="h-3" icon={icon} />
        <span className="truncate">{iconLabel}</span>
      </div>
      <div className="text-maia-text-dark mb-2 flex flex-wrap items-center justify-between text-3xl font-medium md:h-9">
        <Tooltip content={headingLabel} openDelay={500}>
          <span className="max-w-xs truncate lg:max-w-sm xl:max-w-lg">
            {headingLabel}
          </span>
        </Tooltip>
        <div className="flex h-8 gap-2">{...buttons}</div>
      </div>
    </div>
  );
}

export default ContentHeader;
