import { CreatableSelect, MultiValue } from 'chakra-react-select';

import { Button } from '@/components/ui/button';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type InviteColleaguesProps = {
  className?: string;
  tooManyEmailsError: boolean;
  continueButtonLoading?: boolean;
  onContinue: (emails: string[]) => void;
};

function InviteColleagues({
  className,
  tooManyEmailsError,
  continueButtonLoading,
  onContinue,
}: InviteColleaguesProps) {
  const { t } = useTranslation();

  const [emails, setEmails] = useState<
    MultiValue<{ label: string; value: string }>
  >([]);

  return (
    <div
      className={twMerge(
        'flex max-w-xl flex-col items-center gap-8 px-4',
        className
      )}
    >
      <div className="text-2xl font-semibold">
        {t('createWorkspace.inviteColleaguesTitle')}
      </div>
      <div className="flex flex-col gap-8">
        <div className="text-sm">
          {t('createWorkspace.inviteColleaguesDescription')}
        </div>
        <CreatableSelect<{ label: string; value: string }, true>
          isMulti
          selectedOptionStyle="check"
          noOptionsMessage={() => ''}
          formatCreateLabel={(currentVal) => {
            return `${t(
              'workspaceSettings.addUserModal.createOptionLabel'
            )} '${currentVal}'`;
          }}
          components={{ DropdownIndicator: null }}
          onChange={(val) => {
            setEmails(val);
          }}
          value={emails}
          placeholder={t('createWorkspace.emailInputPlaceHolder')}
        />
        {tooManyEmailsError ? (
          <div className="text-xs">{t('createWorkspace.tooManyEmails')}</div>
        ) : null}
        <div className="flex justify-end">
          <Button
            loading={continueButtonLoading}
            colorPalette="maia-accent"
            onClick={() => {
              onContinue(emails.map((val) => val.value));
            }}
          >
            {t('createWorkspace.continueButton')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InviteColleagues;
