// SubscriptionCard.tsx
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../ui/skeleton';
import { LinkButton } from '../ui/link-button';
import { Button } from '../ui/button';
import { Separator } from '@chakra-ui/react';

export type SubscriptionCardProps = {
  title: string;
  price: string;
  priceDescription: string;
  features: string[];
  targetAudience: string;
  subtitle: string;
  includes: string;
  ctaButtonLabel: string;
  isActive: boolean;
  isLoaded: boolean;
  onConfirm?: (() => Promise<void>) | (() => void);
};

const featureList = (features: string[], isLoaded: boolean) => (
  <ul className="ml-4 mt-4">
    {features.map((feature) => (
      <Skeleton key={feature} loading={!isLoaded}>
        <div
          key={feature}
          className="my-2 flex items-center gap-x-4 font-medium"
        >
          <FontAwesomeIcon icon={faCheck} />
          <li>{feature}</li>
        </div>
      </Skeleton>
    ))}
  </ul>
);

export default function SubscriptionCard({
  title,
  isActive,
  price,
  priceDescription,
  features,
  targetAudience,
  subtitle,
  includes,
  ctaButtonLabel,
  isLoaded,
  onConfirm,
}: SubscriptionCardProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex w-[300px] flex-col items-center rounded-none sm:min-w-96 sm:max-w-max">
      <div className="my-8 flex h-[244px] w-[336px] flex-col items-center justify-center gap-y-4">
        <Skeleton loading={!isLoaded}>
          <p className="text-xs">{targetAudience}</p>
        </Skeleton>
        <Skeleton loading={!isLoaded}>
          <p className="text-3xl font-extrabold">{title}</p>
        </Skeleton>
        <Skeleton loading={!isLoaded}>
          {isActive ? (
            <div className="bg-maia-accent rounded-full px-2 py-1 text-xs font-semibold text-white">
              {t('pricingTable.activeLabel')}
            </div>
          ) : (
            <div className="text-lg">
              <span className="font-bold">{price}</span>
              <span>{priceDescription}</span>
            </div>
          )}
        </Skeleton>

        <Skeleton loading={!isLoaded}>
          <p className="text-center font-medium">{subtitle}</p>
        </Skeleton>
        <Skeleton loading={!isLoaded}>
          {!isActive &&
            (title.toLowerCase().includes('enterprise') ? (
              <LinkButton
                colorPalette="maia-accent"
                href="mailto:info@prodlane.io"
              >
                {ctaButtonLabel}
              </LinkButton>
            ) : (
              <Button
                colorPalette="maia-accent"
                onClick={async () => {
                  setIsLoading(true);
                  await onConfirm?.();
                  setIsLoading(false);
                }}
                aria-label={ctaButtonLabel}
                loading={isLoading}
              >
                {ctaButtonLabel}
              </Button>
            ))}
        </Skeleton>
      </div>
      <Separator colorPalette="gray" />
      <div className="flex w-full flex-col px-4 py-8">
        <Skeleton loading={!isLoaded}>
          <p className="text-sm font-semibold">{includes}</p>
        </Skeleton>
        {featureList(features, isLoaded)}
      </div>
    </div>
  );
}
