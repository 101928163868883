import { Button, useBreakpointValue } from '@chakra-ui/react';
import { FileObjectWithStrategy, TempFileObject } from './FileRow';
import { ExtendedBucket } from '../../@types/extendedTypes';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../store/useBoundStore.js';

type ViewFileButtonProps = {
  file: FileObjectWithStrategy | TempFileObject;
  selectedCollection: ExtendedBucket;
};

function ViewFileButton({ file, selectedCollection }: ViewFileButtonProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  async function handlePresignedUrl() {
    const { data, error } = await supabase.storage
      .from(selectedCollection.id)
      .createSignedUrl(file.name, 60);
    if (error) {
      console.error('Error generating URL:', error);
    } else if (data.signedUrl) {
      // Mobile browsers are often blocking new tabs, so we need to redirect instead.
      if (isMobile) {
        window.location.href = data.signedUrl;
      } else {
        window.open(data.signedUrl, '_blank');
      }
    }
  }

  return (
    <Button
      className="font-medium"
      size={'sm'}
      colorPalette={'gray'}
      variant={'outline'}
      onClick={handlePresignedUrl}
    >
      {t('fileManagerPanel.viewFileButton')}
    </Button>
  );
}

export default ViewFileButton;
