import {
  AvailableModelEnum,
  ExperimentalModelEnum,
  isModelAvailableForPlan,
} from 'common-ts';

import { UserPlan } from './userPlan';

export const gptKey = '05aec42a-62fa-4710-aaf9-613479c5923e';

export function setLlmModel(model: AvailableModelEnum | ExperimentalModelEnum) {
  localStorage.setItem(gptKey, model);
}
export function getLlmModel(
  userPlan: UserPlan | undefined
): AvailableModelEnum | ExperimentalModelEnum {
  const model = localStorage.getItem(gptKey);

  if (model && isValueInEnum(model, ExperimentalModelEnum)) {
    return model as ExperimentalModelEnum;
  }
  if (!model || !isValueInEnum(model, AvailableModelEnum)) {
    return AvailableModelEnum.GPT_4_O;
  }

  if (
    !isModelAvailableForPlan(userPlan ?? 'FREE', model as AvailableModelEnum)
  ) {
    return AvailableModelEnum.GPT_4_O_MINI;
  }

  return model as AvailableModelEnum | ExperimentalModelEnum;
}

function isValueInEnum(value: string, enumeration: any): boolean {
  return Object.values(enumeration).includes(value);
}
