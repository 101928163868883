// Importing it like this is a workaround for this bug https://github.com/microsoft/TypeScript/issues/42873
// Should be fixed with the next typescript version
import * as TypesafeRouter from 'react-router-typesafe-routes/dom';
export const ROUTES = {
    REGISTER: TypesafeRouter.route('register'),
    AUTH: TypesafeRouter.route('auth/*', {}, {
        SIGN_IN: TypesafeRouter.route('sign-in', {
            searchParams: {
                inviteCode: TypesafeRouter.string(),
                emailConfirmed: TypesafeRouter.boolean().default(false),
            },
        }),
        SIGN_UP: TypesafeRouter.route('sign-up', {
            searchParams: {
                inviteCode: TypesafeRouter.string(),
            },
        }),
        FORGOT_PASSWORD: TypesafeRouter.route('forgot-password'),
        RESET_PASSWORD: TypesafeRouter.route('reset-password', {
            searchParams: {
                email: TypesafeRouter.string().defined(),
                token: TypesafeRouter.string().defined(),
            },
        }),
        TWO_FACTOR_SELECT: TypesafeRouter.route('two-factor/select', {
            state: {
                email: TypesafeRouter.string().defined(),
                password: TypesafeRouter.string().defined(),
                inviteCode: TypesafeRouter.string(),
            },
        }),
        TWO_FACTOR: TypesafeRouter.route('two-factor', {
            state: {
                email: TypesafeRouter.string().defined(),
                password: TypesafeRouter.string().defined(),
                challengeId: TypesafeRouter.string().defined(),
                type: TypesafeRouter.union('NONE', 'EMAIL', 'AUTHENTICATOR').defined(),
                inviteCode: TypesafeRouter.string(),
            },
        }),
        CONFIRM_EMAIL: TypesafeRouter.route('confirm-email', {
            searchParams: {
                token: TypesafeRouter.string().defined(),
                email: TypesafeRouter.string().defined(),
            },
        }),
        ACCEPT_INVITE: TypesafeRouter.route('accept-invite', {
            searchParams: {
                email: TypesafeRouter.string().defined(),
                token: TypesafeRouter.string().defined(),
            },
        }),
    }),
    /** Can't generate redirect link for freshly invited user to any workspace because workspace is created after user has been invited -> special route */
    INVITED: TypesafeRouter.route('invited'),
    /** TODO: remove this route after migration to new reset password flow */
    RESETTING_PW: TypesafeRouter.route('resetting-password'),
    HOME: TypesafeRouter.route(':workspaceId/*', {}, {
        CHAT: TypesafeRouter.route('chat/:chatId?'),
        SEARCH: TypesafeRouter.route('search/:searchAnswerId?'),
        FILE_MANAGER: TypesafeRouter.route('fileManager/:collectionId?'),
        SETTINGS: TypesafeRouter.route('settings/*', {}, {
            ACCOUNT: TypesafeRouter.route('account', {}, {
                PERSONAL: TypesafeRouter.route('personal', {
                    searchParams: {
                        resetPassword: TypesafeRouter.string(),
                        setPassword: TypesafeRouter.string(),
                    },
                }),
                SECURITY: TypesafeRouter.route('security'),
            }),
            WORKSPACE: TypesafeRouter.route('workspace', {}, {
                GENERAL: TypesafeRouter.route('general'),
                LICENSE: TypesafeRouter.route('license'),
                USER_MANAGEMENT: TypesafeRouter.route('user_management'),
                GLOSSARY: TypesafeRouter.route('glossary'),
            }),
            INTEGRATIONS: TypesafeRouter.route('integrations', {}, { SHAREPOINT: TypesafeRouter.route('sharepoint') }),
            OPEN_SOURCE_SOFTWARE: TypesafeRouter.route('open-source-software'),
        }),
        PRICING: TypesafeRouter.route('pricing', {
            searchParams: { navBackOnClose: TypesafeRouter.string() },
        }),
        ONBOARDING: TypesafeRouter.route('onboarding', {
            state: {
                isTrial: TypesafeRouter.boolean().defined(),
                plan: TypesafeRouter.union('BASIC', 'PROFESSIONAL').defined(),
            },
        }),
    }),
    INVITE_LINK_WITH_CODE: TypesafeRouter.route('invite/:inviteCode'),
    IFRAME: TypesafeRouter.route('iframe/:iframeId/*', {}, { SEARCH: TypesafeRouter.route('search') }),
    OAUTH: TypesafeRouter.route('ms_oauth', {
        searchParams: {
            code: TypesafeRouter.string().defined(),
            state: TypesafeRouter.string().defined(),
        },
    }),
};
