import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../../../components/ui/dialog';
import { Button } from '@chakra-ui/react';

type UnsavedChangesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSave?: () => void;
};

/**
 * Modal to handle unsaved changes.
 * The modal will prompt the user to save, discard or cancel the changes.
 * @param isOpen - Whether the modal is open.
 * @param onClose - Callback when the modal is closed or the user cancels the action.
 * @param onDiscard - Callback when the user discards the changes.
 * @param onSave - Callback when the user saves the changes.
 */
export const UnsavedChangesModal = ({
  isOpen,
  onClose,
  onDiscard,
  onSave,
}: UnsavedChangesModalProps) => {
  const { t } = useTranslation();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <DialogRoot
      closeOnEscape={false}
      closeOnInteractOutside={false}
      open={isOpen}
      finalFocusEl={() => cancelRef.current}
      onOpenChange={({ open }) => !open && onClose()}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader fontSize="lg" fontWeight="bold">
          <DialogTitle>{t('general.unsavedChanges')}</DialogTitle>
        </DialogHeader>
        <DialogBody>{t('promptLibrary.unsavedChangesDialogBody')}</DialogBody>

        <DialogFooter gap={3}>
          <Button ref={cancelRef} onClick={onClose} variant="outline">
            {t('general.cancelButton')}
          </Button>
          <Button colorPalette="red" onClick={onDiscard}>
            {t('general.discard')}
          </Button>
          <Button colorPalette="green" onClick={onSave}>
            {t('general.saveButtons')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
