import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n.js';
import { supportedLanguages } from 'common-ts';
import { useTranslation } from 'react-i18next';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from '@/components/ui/menu';
import { Button } from '../../components/ui/button.js';

function LanguageSetting() {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    supportedLanguages.find((lang) => lang.short === i18n.language)
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(currentLanguage?.short);
  }, [currentLanguage]);

  const setLanguage = (lang: (typeof supportedLanguages)[number]) => {
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang.short);
  };
  return (
    <MenuRoot open={open} onOpenChange={(e) => setOpen(e.open)}>
      <MenuTrigger className="w-full outline-none" asChild>
        <Button className="border-maia-border text-maia-text-dark hover:border-maia-accent flex w-full justify-between border border-solid bg-white font-normal">
          {currentLanguage?.full || t('searchPanel.dataChooser')}
          {open ? (
            <FontAwesomeIcon icon={faChevronUp} className="text-maia-accent" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Button>
      </MenuTrigger>
      <MenuContent className="flex flex-col gap-1 py-1.5 text-left shadow">
        {supportedLanguages.map((lang) => (
          <MenuItem
            key={lang.short}
            className="hover:bg-maia-blue-100 flex min-w-0 cursor-pointer items-center gap-2"
            onClick={() => {
              setLanguage(lang);
            }}
            value={lang.full}
          >
            <span>{lang.full}</span>
          </MenuItem>
        ))}
      </MenuContent>
    </MenuRoot>
  );
}

export default LanguageSetting;
