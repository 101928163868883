import { fetchApi } from '../../utils/useApi';
import { t } from 'i18next';
import { useBoundStore } from '../../store/useBoundStore';
import { useState } from 'react';
import { toaster } from '../../components/ui/toaster';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../components/ui/dialog';
import { Button, Spinner } from '@chakra-ui/react';

type TrialAboutToEndModalProps = {
  isOpen: boolean;
  trialEndTimestamp: number;
  onClose: () => void;
};

function TrialAboutToEndModal({
  isOpen,
  trialEndTimestamp,
  onClose,
}: TrialAboutToEndModalProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [portalLoading, setPortalLoading] = useState(false);

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('general.trialAboutToEndModal.title')}</DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody className="flex flex-col gap-4">
          <div>
            {t('general.trialAboutToEndModal.body')}{' '}
            {new Date(trialEndTimestamp).toLocaleDateString()}
            <br />
            {t('general.trialAboutToEndModal.bodyAfterDate')}
          </div>
          <div className="flex items-center gap-1">
            <Button
              variant={'outline'}
              colorPalette="maia-accent"
              onClick={async () => {
                setPortalLoading(true);
                const portalRes = await fetchApi(
                  supabase,
                  '/payment',
                  '/load_portal',
                  {
                    method: 'POST',
                    returnUrl: window.location.href,
                    workspaceId,
                  }
                );
                setPortalLoading(false);
                if (portalRes.success) {
                  window.location.assign(portalRes.data.url);
                } else {
                  toaster.create({
                    title: t('general.tryAgainError'),
                    type: 'error',
                  });
                }
              }}
            >
              {t('workspaceSettings.licenseManagement.stripeLink')}
            </Button>
            {portalLoading && <Spinner size={'sm'} />}
          </div>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
}

export default TrialAboutToEndModal;
