import { Input } from '@chakra-ui/react';
import { Button } from '../../components/ui/button';
import { Database } from 'common-ts';
import WorkspaceIcon from '../../components/menu/WorkspaceIcon';
import { twMerge } from 'tailwind-merge';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '../../components/ui/field';

type ChooseWorkspaceNameProps = {
  className?: string;
  userName?: string;
  licenseType: Database['public']['Enums']['license_tier'];
  nameEmptyError: boolean;
  onContinue: (name: string) => void;
};

function ChooseWorkspaceName({
  className,
  userName,
  licenseType,
  nameEmptyError,
  onContinue,
}: ChooseWorkspaceNameProps) {
  const { t } = useTranslation();
  const workspaceNameSuggestion = `${userName ? `${userName}'s` : t('createWorkspace.your')} workspace`;
  const nameRef = useRef(workspaceNameSuggestion);

  return (
    <div
      className={twMerge(
        'flex max-w-xl flex-col items-center gap-8 px-4 text-center',
        className
      )}
    >
      <div className="text-2xl font-semibold">
        {t('createWorkspace.nameYourWorkspace')}
      </div>
      <div className="bg-maia-gray-200 flex h-20 w-20 items-center justify-center rounded-sm">
        <WorkspaceIcon licenseType={licenseType} />
      </div>
      <Field
        label={t('createWorkspace.workspaceName')}
        invalid={nameEmptyError}
        errorText={t('createWorkspace.nameRequiredError')}
      >
        <Input
          colorPalette={'maia-accent'}
          type="text"
          autoFocus
          placeholder={workspaceNameSuggestion}
          defaultValue={nameRef.current}
          onChange={(e) => {
            nameRef.current = e.target.value;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onContinue(nameRef.current);
            }
          }}
        />
        <span>{t('createWorkspace.workspaceExplanation')}</span>
      </Field>

      <div className="flex items-center justify-end gap-4 self-stretch">
        <Button
          colorPalette="maia-accent"
          onClick={() => {
            onContinue(nameRef.current);
          }}
        >
          {t('createWorkspace.continueButton')}
        </Button>
      </div>
    </div>
  );
}

export default ChooseWorkspaceName;
