import { MSAuthFlowState } from '../../utils/msAuthFlow';
import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { toaster } from '../../components/ui/toaster';

function OauthPage() {
  const { t } = useTranslation();
  const [searchParams] = useTypedSearchParams(ROUTES.OAUTH);
  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();

  useEffect(() => {
    async function handleAuthFlow() {
      let authFlowState: MSAuthFlowState;
      try {
        authFlowState = JSON.parse(searchParams.state);
      } catch (error: any) {
        captureException(error);
        toaster.create({ type: 'error', title: t('general.reloadError') });
        return;
      }

      switch (authFlowState.action) {
        case 'activate': {
          const res = await fetchApi(
            supabase,
            '/integrations',
            '/sharepoint/activate',
            {
              method: 'POST',
              code: searchParams.code,
              workspaceId: authFlowState.workspaceId,
            }
          );

          if (!res.success) {
            toaster.create({
              type: 'error',
              title: t('integrationSettings.sharepoint.activationError'),
            });
          }

          break;
        }
        case 'userAuth': {
          const res = await fetchApi(
            supabase,
            '/integrations',
            '/sharepoint/login',
            {
              method: 'POST',
              code: searchParams.code,
              workspaceId: authFlowState.workspaceId,
            }
          );

          if (!res.success) {
            if (res.status === 409) {
              toaster.create({
                type: 'error',
                title: t(
                  'fileManagerPanel.integrationAccordion.msLoginWrongTenant'
                ),
              });
            } else {
              toaster.create({
                type: 'error',
                title: t('fileManagerPanel.integrationAccordion.msLoginError'),
              });
            }
          }
          break;
        }
      }

      navigate(authFlowState.redirectTo);
    }

    handleAuthFlow();
  }, []);

  return (
    <div className="flex flex-col items-center gap-4 px-8 py-4">
      <div>{t('oauth.redirectionInfo')}</div>
    </div>
  );
}

export default OauthPage;
