import { useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../../../../components/ListItem';
import { Prompt } from '../promptLibraryTypes';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { t } from 'i18next';
import { IconButton, Input } from '@chakra-ui/react';
import { Button } from '../../../../components/ui/button';
import {
  MenuRoot,
  MenuTrigger,
  MenuContent,
  MenuItem,
} from '../../../../components/ui/menu';

type PromptListItemProps = {
  prompt: Prompt;
  isSelected: boolean;
  isInitialNaming: boolean;
  onClick: (prompt: Prompt) => void;
  onFavoriteClick: (prompt: Prompt) => void;
  onRenameSaved: (prompt: Prompt, newName: string) => void;
  onDeleteClick: (prompt: Prompt) => void;
  isInitialNamingDone: () => void;
  isEditable: boolean;
  searchTerm: string;
  portalRef: React.RefObject<HTMLDivElement>;
};

function PromptListItem({
  prompt,
  isSelected,
  isInitialNaming,
  onClick,
  onFavoriteClick,
  onRenameSaved,
  onDeleteClick,
  isInitialNamingDone,
  isEditable,
  searchTerm,
  portalRef,
}: PromptListItemProps) {
  const [isFavorite, setIsFavorite] = useState(prompt.isFavorite);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const newNameRef = useRef(prompt.name);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleRenameSave = () => {
    if (isNameInvalid) {
      return;
    }
    onRenameSaved(prompt, newNameRef.current);
    if (isInitialNaming) {
      isInitialNamingDone();
    }
    setIsRenaming(false);
  };

  const highlightText = (text: string, searchTerm: string) => {
    if (!searchTerm) {
      return text;
    }
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="font-medium">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <ListItem
      key={prompt.id}
      variant="left"
      selected={isSelected}
      domElementProps={{
        onClick: () => onClick(prompt),
      }}
      className="h-auto min-h-9 w-full py-0.5"
    >
      {isRenaming || isInitialNaming ? (
        <div className="flex w-full flex-col items-start gap-2 py-1">
          <Input
            ref={inputRef}
            defaultValue={prompt.name}
            onChange={(e) => {
              newNameRef.current = e.target.value;
              setIsNameInvalid(newNameRef.current.trim() === '');
            }}
            className={`bg-white ${isNameInvalid ? 'border-maia-support-red border' : ''}`}
            autoFocus
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRenameSave();
                e.stopPropagation();
                e.preventDefault();
              } else if (e.key === 'Escape') {
                setIsRenaming(false);
                if (isInitialNaming) {
                  isInitialNamingDone();
                }
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          />
          <div className="flex items-center gap-2">
            <Button
              className="text-xs font-medium"
              variant="outline"
              size="xs"
              onClick={(e) => {
                setIsRenaming(false);
                if (isInitialNaming) {
                  isInitialNamingDone();
                }
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {t('general.cancelButton')}
            </Button>
            <Button
              className="bg-maia-accent text-maia-text-light text-xs font-medium"
              size="xs"
              onClick={(e) => {
                handleRenameSave();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {t('general.saveButtons')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full items-center gap-2">
            <FontAwesomeIcon
              icon={isFavorite ? faStarSolid : faStarLight}
              className={`${isFavorite ? 'text-maia-support-yellow' : 'text-gray-400'}`}
              onClick={() => {
                setIsFavorite(!isFavorite);
                onFavoriteClick(prompt);
              }}
            />
            <span className="flex-grow">
              {highlightText(prompt.name, searchTerm)}
            </span>
            {isEditable && (
              <MenuRoot positioning={{ placement: 'bottom-end' }}>
                <MenuTrigger>
                  <IconButton
                    size={'sm'}
                    variant="ghost"
                    aria-label="chat settings"
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </IconButton>
                </MenuTrigger>
                <MenuContent className="min-w-[130px]" portalRef={portalRef}>
                  <MenuItem
                    value="delete"
                    onClick={() => onDeleteClick(prompt)}
                  >
                    {t('general.deleteButton')}
                  </MenuItem>
                  <MenuItem
                    value='="rename'
                    onClick={() => {
                      newNameRef.current = prompt.name;
                      setIsRenaming(true);
                    }}
                  >
                    {t('general.renameButtons')}
                  </MenuItem>
                </MenuContent>
              </MenuRoot>
            )}
          </div>
        </>
      )}
    </ListItem>
  );
}

export default PromptListItem;
