import FileIcon from '../../components/FileIcon';
import FilePreviewIconButton from './FilePreviewIconButton';
import { MaiaDocumentPowerMessage } from 'common-ts';

type DocumentSummaryResultProps = {
  summariesOrWholeDocs: NonNullable<
    | MaiaDocumentPowerMessage['fileSummaries']
    | MaiaDocumentPowerMessage['wholeFiles']
  >;
  className?: string;
};

function DocumentSummaryResult({
  summariesOrWholeDocs,
  className = '',
}: DocumentSummaryResultProps) {
  return summariesOrWholeDocs.map((summary) => {
    return (
      <div
        className={
          className
            ? className
            : 'border-maia-border flex w-full items-center justify-between gap-[10px] rounded border px-0 py-0 font-bold md:px-4 md:py-3'
        }
      >
        <div className="flex">
          <FileIcon
            className="text-chakra-gray-400 mr-3 font-black"
            name={summary.fileName}
          />
          <span className="text-maia-text-dark font-medium leading-[26px]">
            {summary.fileName}
          </span>
        </div>
        <div>
          <FilePreviewIconButton
            fileInfoId={summary.fileInfoId}
            fileName={summary.fileName}
          />
        </div>
      </div>
    );
  });
}

export default DocumentSummaryResult;
