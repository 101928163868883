import { ROUTES } from 'common-ts';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useBoundStore } from '../../store/useBoundStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import LogoPure from '../../components/Logo/LogoPure';
import { handleJoin } from '../../utils/inviteLink';
import { toaster } from '../../components/ui/toaster';

export default function InviteLinkeReroute() {
  const { t } = useTranslation();
  const { inviteCode } = useTypedParams(ROUTES.INVITE_LINK_WITH_CODE);

  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();

  useEffect(() => {
    handleJoin(inviteCode, supabase, t, navigate, toaster);
  }, []);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <LogoPure className="fill-maia-gray-300 animate-pulse" size={200} />
    </div>
  );
}
