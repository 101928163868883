import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@chakra-ui/react';
import LogoPure from '../../components/Logo/LogoPure';
import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { faArrowUpLeftFromCircle } from '@fortawesome/pro-regular-svg-icons';
import { getDefaultWorkspace } from '../../utils/getDefaultWorkspace';
import { logout } from '../../utils/handleLogout';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { toaster } from '../../components/ui/toaster';

type DefaultWorkspaceRerouteProps = {
  /** Redirect to a certain page within the default workspace?
   * If undefined, will go to the base url of the default workspace
   */
  redirectTo?: (workspaceId: string) => string;
};

/**
 * Redirects to the default workspace.
 */
function DefaultWorkspaceReroute({ redirectTo }: DefaultWorkspaceRerouteProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const resetToInitialState = useBoundStore(
    (state) => state.resetToInitialState
  );

  const navigate = useNavigate();

  const [workspaceId, setWorkspaceId] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function handleWorkspaceNavigation() {
      const allWorkspacesRes = await supabase
        .from('workspace')
        .select('*, workspace_user(user_type, disabled)');

      if (allWorkspacesRes.error || !allWorkspacesRes.data) {
        captureException(allWorkspacesRes.error);
        toaster.create({
          title: t('navigateToWorkspace.supabaseError'),
          type: 'error',
        });
        return;
      }

      const { data: navigateToWorkspaceId, error } = await getDefaultWorkspace({
        allWorkspacesOfUser: allWorkspacesRes.data,
        supabase,
      });

      if (error) {
        switch (error.name) {
          case 'SupabaseAuthError':
          case 'NoUserSession':
            navigate(ROUTES.AUTH.SIGN_IN.buildPath({}));
            break;
          case 'BackendError':
            toaster.create({
              title: t('navigateToWorkspace.supabaseError'),
              type: 'error',
            });
            navigate(ROUTES.AUTH.SIGN_IN.buildPath({}));
            break;
          case 'SessionExpired':
            toaster.create({
              title: t('general.sessionExpired'),
              type: 'warning',
            });
            navigate(ROUTES.AUTH.SIGN_IN.buildPath({}));
            break;
        }
        return;
      }

      setWorkspaceId(navigateToWorkspaceId);
    }

    handleWorkspaceNavigation();
  }, []);

  function handleLogout() {
    resetToInitialState();
    logout(supabase, navigate);
  }

  if (!workspaceId) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <IconButton
          className="absolute left-6 top-6"
          variant={'ghost'}
          aria-label="logout"
          onClick={handleLogout}
        >
          <FontAwesomeIcon icon={faArrowUpLeftFromCircle} />
        </IconButton>
        <LogoPure className="fill-maia-gray-300 animate-pulse" size={80} />
      </div>
    );
  } else {
    return (
      <Navigate
        to={
          redirectTo
            ? redirectTo(workspaceId)
            : ROUTES.HOME.buildPath({ workspaceId })
        }
      />
    );
  }
}

export default DefaultWorkspaceReroute;
