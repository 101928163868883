import { useEffect, useState } from 'react';
import {
  Checkbox,
  CheckboxProps,
  CheckedState,
} from '../../components/ui/checkbox';

type CheckboxOnChangeStateWrapProps = Omit<CheckboxProps, 'onChange'> & {
  onChange: (checked: boolean) => Promise<boolean>;
};

function CheckboxOnChangeStateWrap(props: CheckboxOnChangeStateWrapProps) {
  const [checked, setChecked] = useState<CheckedState>(!!props.checked);

  useEffect(() => {
    setChecked(!!props.checked);
  }, [props.checked]);
  return (
    <Checkbox
      {...props}
      checked={checked}
      onChange={async () => {
        setChecked(!checked);
        const reallyChecked = await props.onChange(!checked);
        setChecked(reallyChecked);
      }}
    />
  );
}

export default CheckboxOnChangeStateWrap;
