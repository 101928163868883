import FileProgress, {
  FileRowProgressUpdate,
  Uploading,
} from './FileProgress.js';
import { useEffect, useState } from 'react';

import { ConfirmDeleteFileModal } from './modals/ConfirmDeleteFileModal';
import { ConfirmNewAnalysisModal } from './modals/ConfirmNewAnalysisModal.js';
import { ExtendedBucket } from '../../@types/extendedTypes.js';
import { FileObjectWithStrategy } from './FileRow.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaiaAlertDialogWithUpgradeButton from '../../components/MaiaAlertDialogWithUpgradeButton.js';
import SortableTable from '../../components/SortableTable/SortableTable.js';
import ViewFileButton from './ViewFileButton.js';
import { convertMimeTypeToReadable } from './utils.js';
import { decodeName } from 'common-ts';
import { faHourglassEnd } from '@fortawesome/pro-duotone-svg-icons';
import { fetchApi } from '../../utils/useApi.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/ui/button.js';
import { Checkbox } from '../../components/ui/checkbox.js';
import { Tooltip } from '../../components/ui/tooltip.js';
import { Badge } from '@chakra-ui/react';

type FilesOverviewProps = {
  files: (FileObjectWithStrategy | TempFileObject)[];
  selectedCollection: ExtendedBucket;
  onFileListChanged?: () => void;
};

export type TempFileObject = {
  name: string;
  type: string;
  status: Uploading;
};

/**
 * FilesOverview displays a list or table of files allowing users to manage (delete, view progress) files.
 * It supports both mobile and desktop layouts and integrates with Supabase for backend operations.
 *
 * @param files - Array of files to be displayed.
 * @param selectedCollection - Currently selected collection/bucket.
 * @param onFileListChanged - Callback function that triggers when the list of files changes. e.g. after a file is deleted.
 */
function FilesOverview({
  files,
  selectedCollection,
  onFileListChanged,
}: FilesOverviewProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { t } = useTranslation();

  const onClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<
    Map<string, FileObjectWithStrategy | TempFileObject>
  >(new Map());
  const [filesToBeChanged, setFilesToBeChanged] = useState<
    FileObjectWithStrategy[]
  >([]);
  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
  const [isNewAnalysisModalOpen, setIsNewAnalysisModalOpen] = useState(false);
  const allSelected = selectedFiles.size === files.length && files.length > 0;

  /**
   * Toggle the selection of a file.
   * @param file - File to be selected or deselected.
   * @param isSelected - Determines if the file should be selected or deselected.
   */
  const toggleFileSelection = (
    file: FileObjectWithStrategy | TempFileObject,
    isSelected: boolean
  ) => {
    setSelectedFiles((prevSelected) => {
      const newSelected = new Map(prevSelected);
      if (isSelected) {
        // Assuming `file.name` is unique. If not, use a different unique property.
        newSelected.set(file.name, file);
      } else {
        newSelected.delete(file.name);
      }
      return newSelected;
    });
  };

  /**
   * Toggle the selection of all files.
   * @param isSelected - Determines if all files should be selected or deselected.
   */
  const toggleSelectAll = () => {
    if (!allSelected) {
      const allFilesMap = new Map<
        string,
        FileObjectWithStrategy | TempFileObject
      >();
      files.forEach((file) => {
        allFilesMap.set(file.name, file);
      });
      setSelectedFiles(allFilesMap);
    } else {
      setSelectedFiles(new Map());
    }
  };

  /**
   * Helper function to transform Map<string, FileObjectWithStrategy | TempFileObject> into a FileObjectWithStrategy arrays
   * @param theMap
   * @returns FileObjectWithStrategy[]
   */
  const transformFileObjectWithStrategyToArray = (
    theMap: Map<string, FileObjectWithStrategy | TempFileObject>
  ) => {
    return Array.from(theMap)
      .filter((e) => 'id' in e[1])
      .map((en) => {
        // This is save of that type, its typescript ...
        return en[1] as unknown as FileObjectWithStrategy;
      });
  };

  /**
   * Request delete of given files.
   * @param files - Array of files.
   */
  const requestDeleteFiles = async (files: FileObjectWithStrategy[]) => {
    setFilesToBeChanged([...files]);
    setIsDeleteFileModalOpen(true);
  };

  /**
   * Delete the files that are in filesToBeChanged.
   */
  const deleteFiles = async () => {
    setIsDeleteFileModalOpen(false);
    const { error } = await supabase.storage
      .from(selectedCollection.id)
      .remove(filesToBeChanged.map((file) => file.name));
    if (error) {
      console.error('Error deleting files:', error);
    } else {
      onFileListChanged?.();
      setFilesToBeChanged([]);
      selectedFiles.clear();
    }
  };

  /**
   * Request Analysis of files with the given names.
   * @param files - Array of files.
   */
  const requestNewAnalysis = async (files: FileObjectWithStrategy[]) => {
    setFilesToBeChanged([...files]);
    setIsNewAnalysisModalOpen(true);
  };

  /**
   * Issues new Analysis for files which are in filesToBeChanged.
   */
  const newAnalysis = async () => {
    setIsNewAnalysisModalOpen(false);
    fetchApi(supabase, '/embeddings', '/embed_premium', {
      bucketId: selectedCollection.id,
      fileIds: filesToBeChanged.map((file) => file.id),
      method: 'POST',
      workspaceId,
    }).then(() => {
      setFilesToBeChanged([]);
      selectedFiles.clear();
      onFileListChanged?.();
    });
  };

  /**
   * Get the files that are in progress.
   */
  async function getFilesInProgress() {
    for (const f of files) {
      if (!('id' in f)) {
        return false;
      }

      if (f.embeddingStatus === 'ONGOING' || f.embeddingStatus === 'PENDING') {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    getFilesInProgress();
  }, [selectedCollection]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const cancel = await getFilesInProgress();
      if (cancel) {
        clearInterval(interval);
      } else {
        onFileListChanged?.();
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [files]);

  const HeaderButtons = (
    <div className="flex w-[calc(100%-4rem)] flex-wrap gap-2 py-[10px] font-medium">
      <Button
        className="bg-white font-medium"
        variant={'outline'}
        size={'sm'}
        onClick={() => toggleSelectAll()}
      >
        <Checkbox
          colorPalette="maia-purple"
          size={'md'}
          className="hover:bg-maia-purple-50 border-maia-border pointer-events-none rounded pr-2"
          checked={allSelected}
        />
        {allSelected ? t('general.deselectAll') : t('general.selectAll')}
      </Button>
      <Tooltip
        content={
          selectedCollection.access_type === 'VIEW'
            ? t('fileManagerPanel.tooltips.noEditor')
            : t('fileManagerPanel.tooltips.runAda')
        }
        openDelay={500}
      >
        <Button
          className="bg-white font-medium"
          variant={'outline'}
          size={'sm'}
          disabled={
            selectedCollection.access_type === 'VIEW' ||
            selectedFiles.size === 0
          }
          onClick={() =>
            requestNewAnalysis(
              transformFileObjectWithStrategyToArray(selectedFiles)
            )
          }
        >
          {t('fileManagerPanel.useAda')}
        </Button>
      </Tooltip>
      <Tooltip
        content={
          selectedCollection.access_type === 'VIEW'
            ? t('fileManagerPanel.tooltips.noEditor')
            : t('fileManagerPanel.tooltips.delete')
        }
        openDelay={500}
      >
        <Button
          className="bg-maia-support-red text-maia-text-light font-medium hover:bg-red-700"
          variant={'outline'}
          size={'sm'}
          disabled={
            selectedCollection.access_type === 'VIEW' ||
            selectedFiles.size === 0
          }
          onClick={() =>
            requestDeleteFiles(
              transformFileObjectWithStrategyToArray(selectedFiles)
            )
          }
        >
          {t('general.deleteButton')}
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <div className="flex grow flex-col overflow-hidden">
      {HeaderButtons}
      <MaiaAlertDialogWithUpgradeButton
        isOpen={isOpen}
        onClose={onClose}
        AlertDialogHeaderText={t('fileManagerPanel.storageLimitReached')}
        AlertDialogBodyText={t('fileManagerPanel.storageLimitReachedBody')}
      />
      <SortableTable
        variant="line"
        objects={files}
        rowsSelectable={{
          onSelectionChange: toggleFileSelection,
          rowSelected: (file) => selectedFiles.has(file.name),
        }}
        customColumns={{
          name: {
            title: t('fileManagerPanel.tableHeaderName'),
            cellValue: (file) => decodeName(file.name),
            cellRender: (file) => (
              <span className="max-w-[228px] whitespace-pre-wrap">
                {decodeName(file.name)}
              </span>
            ),
          },
          format: {
            title: t('fileManagerPanel.tableHeaderFormat'),
            cellValue: (file) => {
              return 'metadata' in file
                ? convertMimeTypeToReadable(file.metadata.mimetype)
                : file.type;
            },
          },
          uploaded: {
            title: t('fileManagerPanel.tableHeaderUploaded'),
            cellValue: (file) =>
              'created_at' in file ? file.created_at : undefined,
            cellRender: (file) =>
              'created_at' in file ? (
                new Date(file.created_at).toLocaleTimeString([], {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })
              ) : (
                <span
                  className="w-full text-center text-blue-600"
                  color={'blue.600'}
                >
                  <FontAwesomeIcon
                    className="fill-current"
                    icon={faHourglassEnd}
                  />
                </span>
              ),
          },
          analytics: {
            title: t('fileManagerPanel.tableHeaderAnalytic'),
            cellValue: (file) =>
              'id' in file ? (file.strategy === 'premium' ? 2 : 1) : 0,
            cellRender: (file) => (
              <Badge
                fontWeight={'bold'}
                variant={'solid'}
                backgroundColor={
                  'id' in file && file.strategy === 'premium'
                    ? 'maia-accent.500'
                    : 'gray.400'
                }
              >
                {'id' in file && file.strategy === 'premium'
                  ? 'ADVANCED'
                  : 'STANDARD'}
              </Badge>
            ),
          },
          status: {
            title: t('fileManagerPanel.tableHeaderStatus'),
            cellValue: () => 1,
            cellRender: (file) => {
              let fileProgressUpdate: FileRowProgressUpdate =
                'id' in file
                  ? {
                      status: { type: file.embeddingStatus },
                      failReason: file.embeddingFailReason,
                    }
                  : { status: file.status };

              if (
                isDeleteFileModalOpen &&
                filesToBeChanged.find((f) => f.name === file.name)
              ) {
                fileProgressUpdate = {
                  status: { type: 'BEING_DELETED' },
                };
              }
              if (
                isNewAnalysisModalOpen &&
                filesToBeChanged.find((f) => f.name === file.name)
              ) {
                fileProgressUpdate = {
                  status: { type: 'BEING_ANALYZED' },
                };
              }

              return (
                <FileProgress
                  progressUpdate={fileProgressUpdate}
                  fileType={
                    'metadata' in file
                      ? convertMimeTypeToReadable(file.metadata.mimetype)
                      : file.type
                  }
                />
              );
            },
          },
          preview: {
            title: '',
            cellValue: () => undefined,
            cellRender: (file) => (
              <ViewFileButton
                file={file}
                selectedCollection={selectedCollection}
              />
            ),
            columnClassName: 'w-40',
            shouldSort: false,
          },
        }}
      />
      <ConfirmNewAnalysisModal
        isOpen={isNewAnalysisModalOpen}
        onConfirm={newAnalysis}
        fileDetails={filesToBeChanged.map((file) => ({
          fileId: file.id,
          numberOfPages: file.numberOfPages || 0,
        }))}
        onClose={() => {
          setFilesToBeChanged([]);
          setIsNewAnalysisModalOpen(false);
        }}
      />
      <ConfirmDeleteFileModal
        isOpen={isDeleteFileModalOpen}
        onConfirm={() => deleteFiles()}
        fileCount={filesToBeChanged.length}
        onClose={() => {
          setFilesToBeChanged([]);
          setIsDeleteFileModalOpen(false);
        }}
      />
    </div>
  );
}

export default FilesOverview;
