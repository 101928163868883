import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';

type GenerateInviteLinkWarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

function GenerateInviteLinkWarningModal({
  isOpen,
  onClose,
  onConfirm,
}: GenerateInviteLinkWarningModalProps) {
  const { t } = useTranslation();

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent className="w-full max-w-md">
        <DialogHeader>
          <DialogTitle>
            {t('workspaceSettings.generateNewLinkWarningTitle')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogBody>
          <div>{t('workspaceSettings.generateNewLinkWaring')}</div>
        </DialogBody>
        <DialogFooter>
          <div className="flex items-center gap-2">
            <Button className="font-medium" onClick={onClose} variant="outline">
              {t('general.cancelButton')}
            </Button>
            <Button
              className="font-medium"
              colorPalette="maia-purple"
              onClick={onConfirm}
            >
              {t('workspaceSettings.generateInviteLinkButton')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default GenerateInviteLinkWarningModal;
